import { BasicReturn, IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEquipamentoDocumento } from '../../../../Interfaces/IEquipamentoDocumento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getEquipamentoDocumentoInfoByGuid = async (Guid: string): Promise<IBasicReturn<IEquipamentoDocumento>> => {
  let basicReturn: IBasicReturn<IEquipamentoDocumento> = new BasicReturn(true);

  await MyAxiosInstance().get<IBasicReturn<IEquipamentoDocumento>>(`Empresa/EquipamentoDocumentoInfoByGuid/${Guid}`)
    .then((response): IBasicReturn<IEquipamentoDocumento> => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = new BasicReturn(false);

      return basicReturn;
    });

  return basicReturn;
};

export default getEquipamentoDocumentoInfoByGuid;
