import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const onChangeTechnicianPropertyForUser = async (userGuid: string): Promise<IBasicReturn> => {
  let users: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn>(`Identity/ChangeTechnicianProperty/${userGuid}`)
    .then((response): IBasicReturn => {
      const { data } = response;

      users = data;

      return users;
    })
    .catch((): IBasicReturn => {
      users.Success = false;

      return users;
    });

  return users;
};

export default onChangeTechnicianPropertyForUser;
