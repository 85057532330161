import React, { useContext } from 'react';
import { Button, Image, ResponsiveContext } from 'grommet';
import { Next } from 'grommet-icons';
import {
  CardBox,
  CardSection,
  ContentSection,
  Description,
  MidSectionContainer,
  TitleSmall,
  Title,
} from './MidSectiolLPStyles';

export interface MidSectiolLPProps {}

const MidSectiolLP: React.FC<MidSectiolLPProps> = ({}): JSX.Element => {
  const size = useContext(ResponsiveContext);

  return (
    <MidSectionContainer>
      <ContentSection>
        <Title color="text-title">Introdução aos ROV’s</Title>
        <Description>
          ROVs são submarinos não tripulados controlados à distância, projetados
          para realizar uma variedade de tarefas em ambientes subaquáticos.
          Desde a inspeção de infraestruturas submarinas até a exploração de
          ecossistemas marinhos, os ROVs são ferramentas essenciais para
          cientistas, engenheiros e indústrias em todo o mundo.
        </Description>
      </ContentSection>
      <CardSection>
        <CardBox>
          <Image
            style={{
              width: size === 'small' ? '100%' : '332px',
              objectFit: 'none',
            }}
            src="/mid-level-image-lp1.png"
            alt=""
          />
          <TitleSmall color="text-title">Exploração científica</TitleSmall>
          <Description>
            Neubau und Ausbau von Straßen, Verkehrsflächen, Wasserwegen und
            Eisenbahnen erfordert überzeugende Verkehrskonzepte.
          </Description>

          <Button
            primary
            label="Saiba Mais"
            icon={<Next size="small" color="#fff" />}
            reverse
          />
        </CardBox>
        <CardBox>
          <Image
            style={{
              width: size === 'small' ? '100%' : '332px',
              objectFit: 'none',
            }}
            src="/mid-level-image-lp2.png"
            alt=""
          />
          <TitleSmall color="text-title">
            Indústria de petróleo a gás
          </TitleSmall>
          <Description>
            Neubau und Ausbau von Straßen, Verkehrsflächen, Wasserwegen und
            Eisenbahnen erfordert überzeugende Verkehrskonzepte.
          </Description>
          <Button
            primary
            label="Saiba Mais"
            icon={<Next size="small" color="#fff" />}
            reverse
          />
        </CardBox>
        <CardBox>
          <Image
            style={{
              width: size === 'small' ? '100%' : '332px',
              objectFit: 'none',
            }}
            src="/mid-level-image-lp3.png"
            alt=""
          />
          <TitleSmall color="text-title">Segurança e defesa</TitleSmall>
          <Description>
            Neubau und Ausbau von Straßen, Verkehrsflächen, Wasserwegen und
            Eisenbahnen erfordert überzeugende Verkehrskonzepte.
          </Description>
          <Button
            primary
            label="Saiba Mais"
            icon={<Next size="small" color="#fff" />}
            reverse
          />
        </CardBox>
      </CardSection>
    </MidSectionContainer>
  );
};

MidSectiolLP.displayName = 'MidSectiolLP';

export default MidSectiolLP;
