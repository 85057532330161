import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import { IPagination } from '../../../../Interfaces/IPagination';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const pesquisarEquipamentos = async (searchTerm: string, admin: boolean = false): Promise<IBasicReturn<IPagination<IEquipamento[]>>> => {
  let documentos: IBasicReturn<IPagination<IEquipamento[]>> = {
    Success: true,
  };

  const url: string = (admin) ? `Empresa/PesquisarEquipamentoPorEmpresa/${searchTerm}` : `Empresa/PesquisarEquipamento/${searchTerm}`;

  await MyAxiosInstance().get<IBasicReturn<IPagination<IEmpresaDocumento[]>>>(url)
    .then((response): IBasicReturn<IPagination<IEquipamento[]>> => {
      const { data } = response;

      documentos = data;

      return documentos;
    })
    .catch((): IBasicReturn<IPagination<IEquipamento[]>> => {
      documentos.Success = false;

      return documentos;
    });

  return documentos;
};

export default pesquisarEquipamentos;
