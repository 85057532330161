import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const adicionarEmpresaSubmit = async (values: IEmpresa): Promise<IBasicReturn> => {
  let empresa: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().post<IBasicReturn>('Empresa/AdicionarEmpresa', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      empresa = data;

      return empresa;
    })
    .catch((): IBasicReturn => {
      empresa.Success = false;

      return empresa;
    });

  return empresa;
};

export default adicionarEmpresaSubmit;
