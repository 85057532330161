import React from 'react';
import { Box, Button } from 'grommet';
import { Edit } from 'grommet-icons';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import { useNavigate } from 'react-router-dom';

export interface ManageEmpresasActionsProps {
  empresa: IEmpresa;
  refreshData: () => void;
}

const ManageEmpresasActions: React.FC<ManageEmpresasActionsProps> = ({ empresa }): JSX.Element => {
  const navigate = useNavigate();

  const onEditEmpresaClick = (): void => {
    navigate(`/owner/editarEmpresa/${empresa.Guid}`);
  };

  return (
    <>
      <Box
        direction="row"
        gap="none"
        pad="none"
      >
        <Button
          icon={<Edit />}
          title='Editar Empresa'
          onClick={onEditEmpresaClick}
        />
      </Box>
    </>
  );
};

ManageEmpresasActions.displayName = 'ManageEmpresasActions';

export default ManageEmpresasActions;
