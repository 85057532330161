import React, { ReactNode, useEffect, useState } from 'react';
import { FormField, SelectMultiple, Spinner } from 'grommet';
import { toast } from 'react-toastify';
import { IUser } from '../../../Interfaces/IUser';
import getDropDownTecnicosPorEmpresa from './Helpers/getDropDownTecnicosPorEmpresa';
import DropDownTecnicosOption from './Components/DropDownTecnicosOption';

export interface DropDownTecnicosPorEmpresaProps {
  name: string;
  label: string;
  title: string;
  disabled?: boolean;
  multiple?: boolean;
}

const DropDownTecnicosPorEmpresa: React.FC<DropDownTecnicosPorEmpresaProps> = ({
  name, label, title, disabled, multiple = false,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tecnicos, setTecnicos] = useState<IUser[]>([]);

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await getDropDownTecnicosPorEmpresa()
      .then((data): void => {
        if (data.Success) {
          setTecnicos(data.Object!);
        } else {
          toast.error('Erro ao buscar equipamentos');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar equipamentos');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (loading) ? (<Spinner />) : (
    <FormField
      label={label}
      name={name}
      width="100%"
      required
    >
      <SelectMultiple
        name="TecnicoGuid"
        aria-label={title}
        title={title}
        placeholder={label}
        options={tecnicos}
        valueKey={{ key: 'Guid', reduce: true }}
        labelKey={(option: IUser): ReactNode => <DropDownTecnicosOption user={option} />}
        disabled={disabled}
        multiple={multiple}
        searchPlaceholder="Pesquisar"
        messages={{
          selected: '{selected} SELECIONADOS',
          clearAll: 'Limpar seleção',
          selectAll: 'Selecionar todos',
          summarizedValue: '{selected} de {total} selecionados',
          selectedOfTotal: '{selected} de {total} selecionados',
          showMore: 'Mostrar mais',
        }}
        showSelectedInline
      />
    </FormField>
  );
};

DropDownTecnicosPorEmpresa.displayName = 'DropDownTecnicosPorEmpresa';

export default DropDownTecnicosPorEmpresa;
