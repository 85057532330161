import React, { useEffect, useState } from 'react';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import { toast } from 'react-toastify';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import getMinhaEmpresa from './Helpers/getMinhaEmpresa';
import Loading from '../../Loading/Loading';
import { Box, Button, Paragraph } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { Briefcase, Edit, Home, MailOption, Notes, Phone, Server, UserManager } from 'grommet-icons';
import StatsBox from '../../Dashboard/Components/StatsBox/StatsBox';
import AdminChecker from '../../AdminChecker/AdminChecker';

export interface MinhaEmpresaProps {}

const MinhaEmpresa: React.FC<MinhaEmpresaProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const editarMinhaEmpresa = (): void => {
    navigate('/auth/editarMinhaEmpresa');
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [empresa, setEmpresa] = useState<IEmpresa>({});

  const getEmpresa = async (): Promise<void> => {
    setLoading(true);

    await getMinhaEmpresa()
      .then((data): void => {
        if (data.Success) {
          setEmpresa(data.Object!);
          setLoading(false);
        } else {
          toast.error('Erro ao buscar empresa');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar empresa');
        setLoading(false);
      });
  };

  useEffect((): void => {
    getEmpresa();
  }, []);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Administração" />
        <AdminChecker>
          <Button
            secondary
            label="Editar"
            title="Editar empresa"
            onClick={editarMinhaEmpresa}
            icon={<Edit size="16px" />}
          />
        </AdminChecker>
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Box
            direction="column"
            fill="horizontal"
            align="center"
            justify="between"
            gap="32px"
          >
            <Box
              direction="row"
              fill="horizontal"
              align="center"
              justify="between"
            >
              <Box pad="small" gap='8px'>
                <Box direction="row" gap="1rem" align="center">
                  <Notes color="#716F8A" size="14px" />
                  <Paragraph margin="none" color="#716F8A" size="small">
                    Razão Social
                  </Paragraph>
                </Box>
                <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.RazaoSocial}</Paragraph>
              </Box>
              <Box pad="small" gap='8px'>
                <Box direction="row" gap="1rem" align="center">
                  <Briefcase color="#716F8A" size="14px" />
                  <Paragraph margin="none" color="#716F8A" size="small">
                    Nome Fantasia
                  </Paragraph>
                </Box>
                <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.NomeFantasia}</Paragraph>
              </Box>
              <Box pad="small" gap='8px'>
                <Box direction="row" gap="1rem" align="center">
                  <Home color="#716F8A" size="14px" />
                  <Paragraph margin="none" color="#716F8A" size="small">
                    Endereço
                  </Paragraph>
                </Box>
                <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.Endereco}</Paragraph>
              </Box>
            </Box>
            <Box
              direction="row"
              fill="horizontal"
              align="center"
              justify="between"
            >
              <Box pad="small" gap='8px'>
                <Box direction="row" gap="1rem" align="center">
                  <Server color="#716F8A" size="14px" />
                  <Paragraph margin="none" color="#716F8A" size="small">
                    CNPJ
                  </Paragraph>
                </Box>
                <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.CNPJ}</Paragraph>
              </Box>

              <Box pad="small" gap='8px'>
                <Box direction="row" gap="1rem" align="center">
                  <MailOption color="#716F8A" size="14px" />
                  <Paragraph margin="none" color="#716F8A" size="small">
                    E-mail
                  </Paragraph>
                </Box>
                <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.Email}</Paragraph>
              </Box>
              <Box pad="small" gap='8px'>
                <Box direction="row" gap="1rem" align="center">
                  <Phone color="#716F8A" size="14px" />
                  <Paragraph margin="none" color="#716F8A" size="small">
                    Telefone
                  </Paragraph>
                </Box>
                <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.Telefone}</Paragraph>
              </Box>
              <Box pad="small" gap='8px'>
                <Box direction="row" gap="1rem" align="center">
                  <UserManager color="#716F8A" size="14px" />
                  <Paragraph margin="none" color="#716F8A" size="small">
                    Responsável
                  </Paragraph>
                </Box>
                <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>
                  {empresa.ResponsavelTecnico}
                </Paragraph>
              </Box>
            </Box>
          </Box>
          <Box margin={{
            top: '4rem',
          }} fill="horizontal">
          <StatsBox />
          </Box>
        </>
      )}
    </PageWrapper>
  );
};

MinhaEmpresa.displayName = 'MinhaEmpresa';

export default MinhaEmpresa;
