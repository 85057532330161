import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAppContext from '../Context/useAppContext';

export interface ToastContainerConfigProps {}

const ToastContainerConfig: React.FC<ToastContainerConfigProps> = (): JSX.Element => {
  const {
    state: { Theme },
  } = useAppContext();

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={4000}
      hideProgressBar={false}
      rtl={false}
      closeOnClick
      newestOnTop
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={(Theme === 'dark') ? 'dark' : 'light'}
      limit={5}
    />
  );
};

ToastContainerConfig.displayName = 'ToastContainerConfig';

export default ToastContainerConfig;
