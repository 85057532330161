import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import { IPagination } from '../../../../Interfaces/IPagination';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getDocumentos = async (empresaGuid: string, page: IPagination): Promise<IBasicReturn<IPagination<IEmpresaDocumento[]>>> => {
  let apiReturn: IBasicReturn<IPagination<IEmpresaDocumento[]>> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPagination<IEmpresaDocumento[]>>>(`Empresa/Documentos/${empresaGuid}/${page.Page}/${page.PageSize}`)
    .then((response): IBasicReturn<IPagination<IEmpresaDocumento[]>> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn<IPagination<IEmpresaDocumento[]>> => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default getDocumentos;
