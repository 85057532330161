import React from 'react';
import { Box, Spinner, SpinnerProps } from 'grommet';

const Loading = (props: SpinnerProps): JSX.Element => {
  const {
    ...spinner
  } = props;

  const { size = 'large' } = spinner;

  return (
    <Box
      pad="medium"
      fill="horizontal"
      align="center"
      justify="center"
    >
      <Spinner
        size={size}
        {...spinner}
      />
    </Box>
  );
};

Loading.displayName = 'Loading';

export default Loading;
