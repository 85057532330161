import { Box, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import AppHeader from '../Header/AppHeader';
import AppFooter from '../Footer/AppFooter';
import Sidebar from '../Sidebar/Sidebar';
import GridWithStyles from './Styles/GridWithStyles';

export interface AppStructureProps {
  children: JSX.Element;
}

const AppStructure: React.FC<AppStructureProps> = ({ children }): JSX.Element => {
  const size = useContext(ResponsiveContext);

  return (
    <GridWithStyles
      fill="vertical"
      rows={['auto', 'flex', 'auto']}
      columns={['auto']}
      areas={[
        ['header'],
        ['main'],
        ['footer'],
      ]}
    >
      <AppHeader />
      <Box
        gridArea="main"
        pad={{
          top: 'none',
          bottom: 'none',
          right: 'none',
          left: 'none',
        }}
        direction={(size === 'small') ? 'column' : 'row'}
      >
        <Sidebar />
        {children}
      </Box>
      <AppFooter />
    </GridWithStyles>
  );
};

AppStructure.displayName = 'AppStructure';

export default AppStructure;
