import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getDropDownTecnicosPorEmpresa = async (): Promise<IBasicReturn<IUser[]>> => {
  let apiReturn: IBasicReturn<IUser[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IUser[]>>('Empresa/DropDownTecnicosPorEmpresa')
    .then((response): IBasicReturn<IUser[]> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn<IUser[]> => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default getDropDownTecnicosPorEmpresa;
