import { Box, Button, Paragraph } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { IEmpresaDocumento } from '../../../../../Interfaces/IEmpresaDocumento';
import getMeusDocumentos from '../../Helpers/getMeusDocumentos';
import {
  IPagination,
  Pagination as PaginationClass,
} from '../../../../../Interfaces/IPagination';
import Loading from '../../../Loading/Loading';
import Pagination from '../../../Pagination/Pagination';
import totalPages from '../../../Pagination/Helpers/totalPages';
import ManageEmpresasDocumentosTable from '../ManageEmpresasDocumentosTable';
import PageSize from '../../../Pagination/Components/PageSize';
import PesquisarDocumentos from '../PesquisarDocumento/PesquisarDocumentos';

export interface MeusDocumentosProps {}

const MeusDocumentos: React.FC<MeusDocumentosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [documentos, setDocumentos] = useState<
  IPagination<IEmpresaDocumento[]>
  >(new PaginationClass(1, 30, 30, []));

  const initialPagination: IPagination<IEmpresaDocumento[]> =
    new PaginationClass(1, 30, 30);

  const onAddDocumentoClick = (): void => {
    navigate('/admin/adicionarDocumento');
  };

  const setData = (data: IPagination<IEmpresaDocumento[]>): void => {
    setDocumentos(data);
  };

  const getData = async (
    pagination: IPagination = initialPagination,
  ): Promise<void> => {
    setLoading(true);

    await getMeusDocumentos(pagination)
      .then((data): void => {
        if (data.Success) {
          setDocumentos(data.Object!);
        } else {
          toast.error('Erro ao buscar documentos da empresa');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar documentos da empresas');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(
      parseInt(newPage, 10),
      documentos.PageSize,
    );
    getData(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getData(newPagination);
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Documentos" />
        <Button
          primary
          label="Adicionar"
          title="Adicionar Documento"
          onClick={onAddDocumentoClick}
          icon={<Add size="16px" />}
        />
      </Box>
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur.
        </Paragraph>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <Box
          fill="horizontal"
          direction="column"
          gap="none"
          margin={{
            top: 'medium',
          }}
        >
          <PesquisarDocumentos
            refreshData={getData}
            setResults={setData}
            empresaGuid={'00000000-0000-0000-0000-000000000000'}
          />
          <ManageEmpresasDocumentosTable
            documentos={documentos.Object || []}
            refreshData={getData}
          />
          <PageSize size={documentos.PageSize!} onChange={updatePageSize} />
          <Pagination
            page={documentos.Page!}
            last={totalPages(documentos.Total || 0, documentos.PageSize!)}
            onChange={updatePage}
          />
        </Box>
      )}
    </PageWrapper>
  );
};

MeusDocumentos.displayName = 'MeusDocumentos';

export default MeusDocumentos;
