import { Box } from 'grommet';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { IUser } from '../../../../../Interfaces/IUser';
import getUserInfoByGuid from './Helpers/getUserInfoByGuid';
import Loading from '../../../Loading/Loading';
import UsuarioForm from '../../../Forms/Usuario/UsuarioForm';
import Alert from '../../../Alert/Alert';
import saveUserInfo from './Helpers/saveUserInfo';
import useAppContext from '../../../Template/Context/useAppContext';

export interface EditUserProps {}

const EditUser: React.FC<EditUserProps> = ({}): JSX.Element => {
  const {
    state: { Owner },
  } = useAppContext();

  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();
  const onCancel = (): void => {
    const url: string = Owner ? '/owner/manageUsers' : '/admin/usuarios';
    navigate(url);
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<IUser>({});

  const getInitialData = async (): Promise<void> => {
    await getUserInfoByGuid(guid!)
      .then((data): void => {
        if (data.Success) {
          const initialValues: IUser = data.Object!;
          initialValues.ConfirmEmail = initialValues.Email;

          setUser(initialValues);
        } else {
          toast.error('Erro ao buscar informações do usuário');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar informações do usuário');
      });
  };

  const onSubmit = async (values: IUser): Promise<void> => {
    await saveUserInfo(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Informações do usuário salvas com sucesso');
          onCancel();
        } else {
          toast.error('Erro ao salvar informações do usuário');
        }
      })
      .catch((): void => {
        toast.error('Erro ao salvar informações do usuário');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Editar usuário"
      />
      <Box
        width="large"
      >
        <Alert
          text="Alterar qualquer propriedade de um usuário causa desconexão imediata!"
        />
        {
          (loading) ? (<Loading />)
            : (
              <UsuarioForm
                initialValues={user}
                onSubmit={onSubmit}
                onCancel={onCancel}
                editing
              />
            )
        }
      </Box>
    </PageWrapper>
  );
};

EditUser.displayName = 'EditUser';

export default EditUser;
