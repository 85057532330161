import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const editVideoGetInitialValues = async (videoGuid: string): Promise<IBasicReturn<IStreamingVideo>> => {
  let apiResponse: IBasicReturn<IStreamingVideo> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IStreamingVideo>>(`Streaming/VideoEditInfo/${videoGuid}`)
    .then((response): IBasicReturn<IStreamingVideo> => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn<IStreamingVideo> => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default editVideoGetInitialValues;
