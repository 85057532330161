import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'grommet';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import EquipamentosCadastroForm from '../../../Forms/Equipamentos/EquipamentosCadastroForm';
import inserirEquipamentoInitialValues from '../../Helpers/inserirEquipamentoInitialValues';
import inserirEquipamentoSubmit from '../../Helpers/inserirEquipamentoSubmit';
import useAppContext from '../../../Template/Context/useAppContext';

export interface AdicionarEquipamentoProps {}

const AdicionarEquipamento: React.FC<AdicionarEquipamentoProps> = ({}): JSX.Element => {
  const {
    state: { Admin },
  } = useAppContext();

  const navigate = useNavigate();

  const onCancel = (): void => {
    if (Admin) {
      navigate('/admin/manageEquipamentos');
    } else {
      navigate('/owner/manageEquipamentos');
    }
  };

  const {
    state: { Empresa },
  } = useAppContext();

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Equipamento"
      />
      <Box
        width="large"
      >
        <EquipamentosCadastroForm
          initialValues={inserirEquipamentoInitialValues(Empresa.Guid)}
          onSubmit={inserirEquipamentoSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarEquipamento.displayName = 'AdicionarEquipamento';

export default AdicionarEquipamento;
