import { BasicReturn, IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getEquipamentoInfoByGuid = async (Guid: string): Promise<IBasicReturn<IEquipamento>> => {
  let basicReturn: IBasicReturn<IEquipamento> = new BasicReturn(true);

  await MyAxiosInstance().get<IBasicReturn<IEquipamento>>(`Empresa/EquipamentosInfoByGuid/${Guid}`)
    .then((response): IBasicReturn<IEquipamento> => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = new BasicReturn(false);

      return basicReturn;
    });

  return basicReturn;
};

export default getEquipamentoInfoByGuid;
