import React, { useEffect, useState } from 'react';
import { Button, Paragraph } from 'grommet';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import Loading from '../../Loading/Loading';
import getEmailValidation from './Helpers/getEmailValidation';
import useAppContext from '../../Template/Context/useAppContext';

export interface ValidarEmailProps {}

const ValidarEmail: React.FC<ValidarEmailProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onGoLogin = (): void => {
    navigate('/login');
  };

  const {
    state: {},
    dispatch,
  } = useAppContext();

  const [loading, setLoading] = useState(true);
  let timer: number = 10;
  const timerRef = React.useRef<NodeJS.Timeout>();

  const cleanUpTimer = (): void => {
    if (timerRef.current) clearInterval(timerRef.current);
  };

  const leavePage = (): void => {
    cleanUpTimer();

    dispatch({
      type: 'changeAuth',
      value: false,
    });

    dispatch({
      type: 'changeDrawer',
      value: false,
    });

    dispatch({
      type: 'changeFirstName',
      value: '',
    });

    dispatch({
      type: 'changeLastName',
      value: '',
    });

    dispatch({
      type: 'changeEmail',
      value: '',
    });

    dispatch({
      type: 'changeOwner',
      value: false,
    });

    dispatch({
      type: 'changeValidated',
      value: false,
    });

    onGoLogin();
  };

  const emailValidation = async (): Promise<void> => {
    setLoading(true);

    await getEmailValidation()
      .then((data): void => {
        if (data.Success) {
          toast.success('Email validado com sucesso');
          setLoading(false);

          timerRef.current = setInterval((): void => {
            if (timer > 0) {
              timer = timer - 1;
            } else {
              leavePage();
            }
          }, 1000);
        } else {
          toast.error('Erro ao validar email');
        }
      })
      .catch((): void => {
        toast.error('Erro ao validar email');
      });
  };

  useEffect((): void => {
    emailValidation();
    return cleanUpTimer();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Validar Email"
      />
      {
        (loading) ? (<Loading />) : (
          <>
            <Paragraph>
              Email validado com sucesso!
            </Paragraph>
            <Button
              primary
              label="Voltar"
              onClick={leavePage}
            />
          </>
        )
      }
    </PageWrapper>
  );
};

ValidarEmail.displayName = 'ValidarEmail';

export default ValidarEmail;
