import React, { useState } from 'react';
import { Box } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import ProjetoForm from '../../Forms/Projeto/ProjetoForm';
import adicionarProjetoInitialValues from '../Helpers/adicionarProjetoInitialValues';
import adicionarProjetoSubmit from '../Helpers/adicionarProjetoSubmit';
import { IProjeto } from '../../../../Interfaces/IProjeto';

export interface AdicionarProjetoProps {}

const AdicionarProjeto: React.FC<AdicionarProjetoProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const onCancel = (): void => navigate('/admin/projetos');

  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (values: IProjeto): Promise<void> => {
    setSubmitting(true);
    await adicionarProjetoSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Projeto adicionado com sucesso');
          onCancel();
        } else {
          toast.error('Erro ao adicionar projeto');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        toast.error('Erro ao adicionar projeto');
        setSubmitting(false);
      });
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Projeto"
      />
        <Box
          width="large"
        >
        <ProjetoForm
          initialValues={adicionarProjetoInitialValues()}
          onSubmit={onSubmit}
          submitting={submitting}
          onCancel={onCancel}
        />
        </Box>
    </PageWrapper>
  );
};

AdicionarProjeto.displayName = 'AdicionarProjeto';

export default AdicionarProjeto;
