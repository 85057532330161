import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getDropDownPlantas = async (empresaGuid: string): Promise<IBasicReturn<IPlanta[]>> => {
  let apiReturn: IBasicReturn<IPlanta[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPlanta[]>>(`Empresa/DropDownPlantas/${empresaGuid}`)
    .then((response): IBasicReturn<IPlanta[]> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn<IPlanta[]> => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default getDropDownPlantas;
