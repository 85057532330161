import React from 'react';
import { Button } from 'grommet';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper/PageWrapper';
import {
  ErrorDescription,
  ErrorPageContainer,
  ErrorTag,
  ErrorTitle,
} from './Error500Styles';

export interface Error500Props {}

const Error500: React.FC<Error500Props> = ({ }): JSX.Element => {
  const navigate = useNavigate();

  const onVoltarHome = (): void => {
    navigate('/');
  };
  return (
    <PageWrapper justify="center">
      <ErrorPageContainer>
        <ErrorTag color="text-strong">500</ErrorTag>
        <ErrorTitle color="text-strong">Opss! Erro no servidor interno.</ErrorTitle>
        <ErrorDescription color="text-strong">
          Desculpe alguma coisa deu errado. Mas não se preocupe, estamos trabalhando para resolver.
        </ErrorDescription>
        <Button
          primary
          label="VOLTAR PARA HOME"
          onClick={onVoltarHome}
        />
      </ErrorPageContainer>
    </PageWrapper>
  );
};

Error500.displayName = 'Error500';

export default Error500;
