import { IUser } from '../../../../../Interfaces/IUser';

const convidarUsuarioInitialValues: IUser = {
  Guid: '',
  FirstName: '',
  LastName: '',
  Email: '',
  ConfirmEmail: '',
  Active: true,
  Admin: false,
  Technician: false,
  Owner: false,
  TechDoc: '',
};

export default convidarUsuarioInitialValues;
