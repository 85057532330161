import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, FileInput, FormExtendedEvent, FormField, ResponsiveContext, TextArea, TextInput } from 'grommet';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { IProjetoDocumento } from '../../../../Interfaces/IProjetoDocumento';
import DropDownTipoDocumentoProjeto from '../../DropDownTipoDocumentoProjeto/DropDownTipoDocumentoProjeto';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';
import { IUpload } from '../../../../Interfaces/IUpload';

export interface ProjetoDocumentoFormProps {
  initialValues: IProjetoDocumento;
  onSubmit: (values: IProjetoDocumento) => void;
  submitting: boolean;
  onCancel?: () => void;
}

const ProjetoDocumentoForm: React.FC<ProjetoDocumentoFormProps> = ({ initialValues, onSubmit, onCancel, submitting }): JSX.Element => {
  const [values, setValues] = useState<IProjetoDocumento>(initialValues);
  const onChange = (nextValues: IProjetoDocumento): void => setValues(nextValues);

  let upload: IUpload = {};

  const size = useContext(ResponsiveContext);
  const maxFileSize: number = 10 * 1024 * 1024;

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IProjetoDocumento>): Promise<void> => {
    const { value } = evt;
    const { UploadNome } = value;

    if (!UploadNome) {
      toast.error('Por favor, selecione um arquivo para upload');
      return;
    }

    const uploadFile: File = UploadNome[0];

    if (uploadFile.size > maxFileSize) {
      toast.error('Arquivo muito grande (máximo 10MB)');
      return;
    }

    const [extension] = uploadFile.name.split('.').reverse();

    if (!extension) {
      toast.error('Arquivo sem extensão');
      return;
    }

    const reader = new FileReader();

    reader.onload = (): void => {
      const { result } = reader;

      upload = {
        UploadMimeType: uploadFile.type,
        UploadBase64: result!.toString(),
        UploadExtension: extension,
      };

      const valuesToSubmit = {
        ...value,
        Upload: upload,
      };

      onSubmit(valuesToSubmit);

      reader.onerror = (): void => {
        toast.error('Erro ao carregar imagem');
      };
    };

    reader.readAsDataURL(uploadFile);
  };

  const privadoChange = (value: boolean): void => {
    setValues({ ...values, Privado: value });
  };

  useEffect((): void => {
    setValues({ ...values, Tipo: { Guid: values.TipoDocumento } });
  }, [values.TipoDocumento]);

  return (
    <Box
      width="large"
    >
      <FormWrapper
        values={values}
        onChange={onChange}
        onSubmit={handleSubmit}
      >
        <Box
          direction={(size === 'small') ? 'column' : 'row'}
          gap="small"
          justify="end"
        >
          <FormField
            label="Nome do Documento"
            name="Titulo"
            width="100%"
            maxLength={98}
            required
          >
            <TextInput
              name="Titulo"
              aria-label="Por favor informe o nome do documento"
              title="Por favor informe o nome do documento"
            />
          </FormField>
        </Box>

        <Box
          direction={(size === 'small') ? 'column' : 'row'}
          gap="small"
          justify="end"
        >
          <DropDownTipoDocumentoProjeto
            name="TipoDocumento"
            label="Tipo de Documento"
            title="Por favor selecione o tipo de documento"
            disabled={submitting}
          />

          <InterdevSwitch
            activeLabel="Restrito a Administradores"
            inactiveLabel="Público"
            value={values.Privado || false}
            onChange={privadoChange}
            name="Privado"
            title="Alterar a visibilidade do vídeo"
            text="Documento Privado?"
          />
        </Box>

        <Box
          direction={(size === 'small') ? 'column' : 'row'}
          gap="small"
          justify="end"
        >
          <FormField
            label="Descrição"
            name="Observacao"
            width="100%"
          >
            <TextArea
              name="Observacao"
              aria-label="Por favor informe a descrição do documento"
              title="Por favor informe a descrição do documento"
              rows={5}
              maxLength={998}
            />
          </FormField>
        </Box>

        <Box
          direction="row"
          gap="medium"
          justify="start"
          margin={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <FormField
            label="Upload do Documento"
            name="UploadNome"
            width="100%"
            htmlFor="UploadNome"
            required
          >
            <FileInput
              // ref={fileRef}
              multiple={false}
              name="UploadNome"
              id="UploadNome"
              maxSize={maxFileSize}
              accept="application/pdf,image/png,image/jpeg"
              // onChange={onChangeFile}
              messages={{
                browse: 'Carregar',
                dropPrompt: 'Arraste aqui um arquivo .PDF, .PNG ou .JPG ou',
                dropPromptMultiple: 'Arraste seus documentos aqui ou',
                files: 'Arquivos',
                remove: 'Remover',
                removeAll: 'Remover todos',
                maxFile: 'O arquivo é muito grande',
              }}
            />
          </FormField>
        </Box>

        <Box
          direction="row"
          gap="medium"
          justify="start"
          margin={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <Button
            type="submit"
            label="Salvar"
            disabled={submitting}
            primary
          />
          <Button
            label="Cancelar"
            onClick={onClear}
          />
        </Box>
      </FormWrapper>
    </Box>
  );
};

ProjetoDocumentoForm.displayName = 'ProjetoDocumentoForm';

export default ProjetoDocumentoForm;
