import { BasicSelectProps, Box, Button, Image, Select } from 'grommet';
import { Close, Menu, User } from 'grommet-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserMenuOptions from './UserMenuOptions';
import useAppContext from '../Context/useAppContext';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';

export interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = (): JSX.Element => {
  const navigate = useNavigate();

  const onLogoClick = (): void => {
    navigate('/');
  };

  const {
    state: { Drawer, IsAuthenticated, Theme, Language },
    dispatch,
  } = useAppContext();

  const changeMenu = (): void => {
    dispatch({
      type: 'changeDrawer',
      value: !Drawer,
    });
  };

  const onNavigateToLogin = (): void => {
    navigate('/login');
  };

  const changeLanguage = ({ option }: { option: BasicSelectProps['options'] }): void => {
    dispatch({
      type: 'changeLanguage',
      // @ts-expect-error - this is something bugged from Grommet
      value: option.value,
    });
  };

  const changeTheme = (): void => {
    dispatch({
      type: 'changeTheme',
      value: Theme === 'light' ? 'dark' : 'light',
    });
  };

  const languageOptions: BasicSelectProps['options'] = [
    // { label: <LanguageDropDownItem countryCode="BR" text="Português - BR" />, value: 'pt' },
    // { label: <LanguageDropDownItem countryCode="US" text="English - US" />, value: 'en' },
    { label: 'Português - BR', value: 'pt' },
    { label: 'English - US', value: 'en' },
  ];

  return (IsAuthenticated) ? (<></>) : (
    <Box
      gridArea="header"
      elevation="medium"
      fill="horizontal"
      align="center"
      justify="evenly"
      direction="row"
      pad="medium"
    >
      <Box justify="center" align='center' fill="horizontal" gap="2rem" direction="row">
        {IsAuthenticated && (
          <Button
            onClick={changeMenu}
            title={Drawer ? 'Close Menu' : 'Open Menu'}
            icon={Drawer ? <Close color="brand" /> : <Menu color="brand" />}
          />
        )}
        <Image
          src="/logo.png"
          alt="Sinapse Flow"
          style={{
            height: '22px',
            cursor: 'pointer',
          }}
          onClick={onLogoClick}
        />
        <Select
          options={languageOptions}
          value={Language}
          valueKey={{ key: 'value', reduce: true }}
          labelKey="label"
          onChange={changeLanguage}
          placeholder="Language"
          size="small"
        />
      </Box>
      <Box
        justify="center"
        align="center"
        fill="horizontal"
        direction="row"
        gap="2rem"
      >
        <InterdevSwitch
          activeLabel=""
          inactiveLabel=""
          value={Theme === 'light' ? false : true}
          onChange={changeTheme}
          name="Active"
          title="Alterar o Status da empresa"
          text="Modo escuro"
          width="auto"
          direction="row"
        />

        {IsAuthenticated ? (
          <UserMenuOptions />
        ) : (
          <Box
            style={{
              display: 'flex',
              padding: '22px 12px',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#122136',
              height: '48px',
              borderRadius: '50%',
            }}
          >
            <Button
              title="Fazer login"
              icon={<User color="#fff" />}
              onClick={onNavigateToLogin}
            />
          </Box>
        )}

        {/* Antigo menu options, para matar       */}
        {/* <ConfigMenuOptions /> */}
      </Box>
    </Box>
  );
};

AppHeader.displayName = 'AppHeader';

export default AppHeader;
