import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Paragraph, Tab, Tabs } from 'grommet';
import { Add } from 'grommet-icons';
import { toast } from 'react-toastify';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import { IPagination, Pagination as PaginationClass } from '../../../../Interfaces/IPagination';
import { IUser } from '../../../../Interfaces/IUser';
import getUsersDaMinhaEmpresa from './Helpers/getUsersDaMinhaEmpresa';
import Loading from '../../Loading/Loading';
import Alert from '../../Alert/Alert';
import Pagination from '../../Pagination/Pagination';
import totalPages from '../../Pagination/Helpers/totalPages';
import ManageUsersTable from '../../ManageUsers/Components/ManageUsersTable';
import PageSize from '../../Pagination/Components/PageSize';
import getConvidadosDaMinhaEmpresa from './Helpers/getConvidadosDaMinhaEmpresa';
import UsuariosConvidadosDataTable from './Components/UsuariosConvidadosDataTable';

export interface UsuariosDaMinhaEmpresaProps {}

const UsuariosDaMinhaEmpresa: React.FC<UsuariosDaMinhaEmpresaProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onAddUsuarioClick = (): void => {
    navigate('/admin/inserirUsuario');
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<IPagination<IUser[]>>(new PaginationClass(1, 30, 30, []));
  const [convidados, setConvidados] = useState<IUser[]>([]);

  const initialPagination: IPagination<IUser[]> = new PaginationClass(1, 30, 30);

  const getUsers = async (pagination: IPagination = initialPagination): Promise<void> => {
    setLoading(true);

    await getUsersDaMinhaEmpresa(pagination)
      .then((data): void => {
        if (data.Success) {
          setUsers(data.Object!);
          setLoading(false);
        }
      })
      .catch((): void => {
        setUsers(initialPagination);
      });
  };

  const getConvidados = async (): Promise<void> => {
    setLoading(true);

    await getConvidadosDaMinhaEmpresa()
      .then((data): void => {
        if (data.Success) {
          setConvidados(data.Object!);
          setLoading(false);
        } else {
          toast.error('Erro ao buscar convidados');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar convidados');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(parseInt(newPage, 10), users.PageSize);
    getUsers(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getUsers(newPagination);
  };

  const [activeTab, setActiveTab] = useState<number>(0);
  const onUpdateTab = (index: number): void => {
    setActiveTab(index);
  };

  useEffect((): void => {
    if (activeTab === 0) {
      getUsers();
    }

    if (activeTab === 1) {
      getConvidados();
    }
  }, [activeTab]);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
      <PageTitle
        Text="Usuários cadastrados"
      />
        <Button
          primary
          label="Adicionar"
          title="Adicionar Usuário"
          onClick={onAddUsuarioClick}
          icon={<Add size="16px" />}
        />
      </Box>
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur.
        </Paragraph>
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
            }}
          >
            <Alert
              text="Alterar qualquer propriedade de um usuário causa desconexão imediata!"
            />
            {/* <SearchUsersByTerm
              refreshData={getUsers}
              setResults={setUsers}
            /> */}
            <Tabs
              justify="start"
              margin={{
                top: '-20px',
              }}
              activeIndex={activeTab}
              onActive={onUpdateTab}
            >
              <Tab title="Usuários">
                <ManageUsersTable
                  users={users.Object!}
                  refreshData={getUsers}
                  showAdmin
                />
                <PageSize
                  size={users.PageSize!}
                  onChange={updatePageSize}
                />
                <Pagination
                  page={users.Page!}
                  last={totalPages(users.Total!, users.PageSize!)}
                  onChange={updatePage}
                />
              </Tab>
              <Tab title="Convidados">
                <UsuariosConvidadosDataTable
                  usuarios={convidados}
                  refreshData={getConvidados}
                />
              </Tab>
            </Tabs>
          </Box>
        )
      }
    </PageWrapper>
  );
};

UsuariosDaMinhaEmpresa.displayName = 'UsuariosDaMinhaEmpresa';

export default UsuariosDaMinhaEmpresa;
