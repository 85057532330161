import React from 'react';
import MuxPlayer from '@mux/mux-player-react';
import { IStreamingVideo } from '../../../Interfaces/IStreamingVideo';
import useAppContext from '../Template/Context/useAppContext';

export interface VideoPlayerProps {
  video: IStreamingVideo;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ video }): JSX.Element => {
  const {
    state: { Guid },
  } = useAppContext();

  return (
    <MuxPlayer
      playbackId={video.MuxPlaybackId}
      metadata={{
        video_id: video.Guid,
        video_title: video.Title,
        viewer_user_id: Guid,
      }}
    />
  );
};

VideoPlayer.displayName = 'VideoPlayer';

export default VideoPlayer;
