import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { Box } from 'grommet';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../Loading/Loading';
import getDocumentoInfoByGuid from '../../Helpers/editarDocumentoInitialValues';
import editarDocumentoSubmit from '../../Helpers/editarDocumentoSubmit';
import { IEmpresaDocumento } from '../../../../../Interfaces/IEmpresaDocumento';
import DocumentosForm from '../../../Forms/Documentos/DocumentosForm';

export interface EditarDocumentoProps {}

const EditarDocumento: React.FC<EditarDocumentoProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [documento, setDocumento] = useState<IEmpresaDocumento>({});

  const onCancel = (): void => navigate(`/owner/manageEmpresaDocumentos/${documento.Empresa!.Guid}`);

  const getInitialData = async (): Promise<void> => {
    await getDocumentoInfoByGuid(guid!)
      .then((data): void => {
        if (data.Success) {
          const initialValues: IEmpresaDocumento = data.Object!;
          setDocumento(initialValues);
        } else {
          toast.error('Erro ao buscar informações do documento');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar informações do documento');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Editar documento"
      />
      <Box
        width="large"
      >
        {
          (loading) ? (<Loading />)
            : (
              <DocumentosForm
                initialValues={documento}
                onSubmit={editarDocumentoSubmit}
                onCancel={onCancel}
                categoriaDocs={1}
                editing
              />
            )
        }
      </Box>
    </PageWrapper>
  );
};

EditarDocumento.displayName = 'EditarDocumento';

export default EditarDocumento;
