import { BasicReturn, IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getPlantaInfoByGuid = async (plantaGuid: string): Promise<IBasicReturn<IPlanta>> => {
  let basicReturn: IBasicReturn<IPlanta> = new BasicReturn(true);

  await MyAxiosInstance().get<IBasicReturn<IPlanta>>(`Empresa/PlantaInfoByGuid/${plantaGuid}`)
    .then((response): IBasicReturn<IPlanta> => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = new BasicReturn(false);

      return basicReturn;
    });

  return basicReturn;
};

export default getPlantaInfoByGuid;
