import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getConviteInfo = async (conviteGuid: string): Promise<IBasicReturn<IUser>> => {
  let apiResponse: IBasicReturn<IUser> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IUser>>(`Empresa/ConviteParaEmpresaInfo/${conviteGuid}`)
    .then((response): IBasicReturn<IUser> => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn<IUser> => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default getConviteInfo;
