import { IPlanta } from '../../../../../Interfaces/IPlanta';

const adicionarPlantaNaMinhaEmpresaValues = (): IPlanta => {
  return {
    Nome: '',
    Observacao: '',
    Ativo: true,
  };
};

export default adicionarPlantaNaMinhaEmpresaValues;
