import { IEquipamento } from '../../../../Interfaces/IEquipamento';

const adicionarProjetoEquipamentoInitialValues = (projetoGuid: string): IEquipamento => ({
  Projeto: {
    Guid: projetoGuid,
  },
  Guid: '',
});

export default adicionarProjetoEquipamentoInitialValues;
