import React from 'react';
import { Box, Button, Layer } from 'grommet';
import { Close } from 'grommet-icons';
import { IStreamingVideo } from '../../../../../Interfaces/IStreamingVideo';
import PageTitle from '../../../PageTitle/PageTitle';
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';

export interface VideoLayerProps {
  video: IStreamingVideo;
  onCloseLayer: () => void;
}

const VideoLayer: React.FC<VideoLayerProps> = ({ video, onCloseLayer }): JSX.Element => {
  return (
    <Layer
      animation="fadeIn"
      full
    >
      <Box
        align="center"
        justify="start"
        pad="small"
        gap="small"
        direction="column"
        fill
      >
        <Box
          fill="horizontal"
          align="end"
        >
          <Button
            icon={<Close />}
            onClick={onCloseLayer}
          />
        </Box>
        <Box
          fill="horizontal"
          align="center"
        >
          <PageTitle
            Text="Visualizar vídeo"
            level={4}
          />
          <Box
            width="large"
            margin={{
              bottom:'medium',
            }}
          >
            <VideoPlayer
              video={video}
            />
          </Box>
          <Box>
            <Button
              label="Fechar"
              onClick={onCloseLayer}
              icon={<Close size='14px' />}
              primary
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};

VideoLayer.displayName = 'VideoLayer';

export default VideoLayer;
