import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const editarProjetoInitialValues = async (projetoGuid: string): Promise<IBasicReturn<IProjeto>> => {
  let apiResponse: IBasicReturn<IProjeto> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IProjeto>>(`Empresa/ProjetoInfoParaEditar/${projetoGuid}`)
    .then((response): IBasicReturn<IProjeto> => {
      const { data } = response;

      const projeto = data.Object!;
      projeto.EmpresaGuid = projeto.Empresa?.Guid;
      projeto.PlantaGuid = projeto.Planta?.Guid;

      apiResponse = {
        ...data,
        Object: projeto,
      };

      return apiResponse;
    })
    .catch((): IBasicReturn<IProjeto> => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default editarProjetoInitialValues;
