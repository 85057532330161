import React, { useState } from 'react';
import {
  Box, Button, FormExtendedEvent, FormField, TextInput,
} from 'grommet';
import { useNavigate } from 'react-router-dom';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { IUser } from '../../../../Interfaces/IUser';
import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import useAppContext from '../../Template/Context/useAppContext';
import { toast } from 'react-toastify';

export interface AlterarSenhaFormProps {
  initialValues: IUser;
  onSubmit: (values: IUser) => Promise<IBasicReturn<boolean>>;
  onCancel: () => void;
}

const AlterarSenhaForm: React.FC<AlterarSenhaFormProps> = ({ initialValues, onSubmit, onCancel }): JSX.Element => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [values, setValues] = useState(initialValues);
  const onChange = (newValues: IUser): void => {
    setValues(newValues);
  };

  const navigate = useNavigate();

  const onGoLogin = (): void => {
    navigate('/login');
  };

  const {
    state: { },
    dispatch,
  } = useAppContext();

  const validatePassword = (value: string): string | undefined => {
    if (value.length < 6) {
      return 'A senha deve ter no mínimo 6 caracteres';
    }
    if (value.length > 15) {
      return 'A senha deve ter no máximo 15 caracteres';
    }
  };

  const validateConfirmPassword = (value: string): string | undefined => {
    if (value !== values.NewPassword) {
      return 'As senhas não conferem';
    }
  };

  const leavePage = (): void => {
    dispatch({
      type: 'changeAuth',
      value: false,
    });

    dispatch({
      type: 'changeDrawer',
      value: false,
    });

    dispatch({
      type: 'changeFirstName',
      value: '',
    });

    dispatch({
      type: 'changeLastName',
      value: '',
    });

    dispatch({
      type: 'changeEmail',
      value: '',
    });

    dispatch({
      type: 'changeOwner',
      value: false,
    });

    dispatch({
      type: 'changeValidated',
      value: false,
    });

    onGoLogin();
  };

  const handleSubmit = async (evt: FormExtendedEvent<IUser>): Promise<void> => {
    const { value } = evt;
    setSubmitting(true);
    
    await onSubmit(value)
      .then((data): void => {
        if (data.Success && data.Object) {
          toast.success('Senha alterada com sucesso');
          leavePage();
        } else {
          toast.error('Erro ao alterar a senha');
          setSubmitting(false);
        }
      })
      .catch((): void => {
        setSubmitting(false);
        toast.error('Erro ao alterar a senha');
      })
    ;
  };

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <FormField
        label="Senha Atual"
        name="Password"
        width="100%"
        validate={validatePassword}
        required
      >
        <TextInput
          name="Password"
          type="password"
          aria-label="Por favor informe sua senha atual"
          title="Por favor informe sua senha atual"
          placeholder="******"
        />
      </FormField>

      <FormField
        label="Nova Senha"
        name="NewPassword"
        width="100%"
        validate={validatePassword}
        required
      >
        <TextInput
          name="NewPassword"
          type="password"
          aria-label="Por favor informe sua nova senha"
          title="Por favor informe sua nova senha"
          placeholder="******"
        />
      </FormField>

      <FormField
        label="Confirmar Nova Senha"
        name="ConfirmPassword"
        width="100%"
        validate={validateConfirmPassword}
        required
      >
        <TextInput
          name="ConfirmPassword"
          type="password"
          aria-label="Por favor confirme sua nova senha"
          title="Por favor confirme sua nova senha"
          placeholder="******"
        />
      </FormField>

      <Box
        direction="row"
        gap="small"
        justify="end"
        margin={{
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <Button
          type="submit"
          label="Alterar Senha"
          title="Alterar Senha"
          disabled={submitting}
          primary
          reverse
        />
        <Button
          type="button"
          label="Cancelar"
          title="Cancelar"
          disabled={submitting}
          onClick={onCancel}
          secondary
        />
      </Box>
    </FormWrapper>
  );
};

AlterarSenhaForm.displayName = 'AlterarSenhaForm';

export default AlterarSenhaForm;
