import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const adicionarVideoAoProjetoSubmit = async (values: IStreamingVideo): Promise<IBasicReturn> => {
  let resposta: IBasicReturn<IStreamingVideo> = {
    Success: true,
  };

  await MyAxiosInstance().post<IBasicReturn<IStreamingVideo>>('Streaming/AdicionarStreaming', values)
    .then((response): IBasicReturn<IStreamingVideo> => {
      const { data } = response;

      resposta = data;

      return resposta;
    })
    .catch((): IBasicReturn<IStreamingVideo> => {
      resposta.Success = false;

      return resposta;
    });

  return resposta;
};

export default adicionarVideoAoProjetoSubmit;
