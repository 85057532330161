import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getDropDownEquipamentosPorEmpresa = async (): Promise<IBasicReturn<IEquipamento[]>> => {
  let apiReturn: IBasicReturn<IEquipamento[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IEquipamento[]>>('Empresa/DropDownEquipamentosPorEmpresa')
    .then((response): IBasicReturn<IEquipamento[]> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn<IEquipamento[]> => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default getDropDownEquipamentosPorEmpresa;
