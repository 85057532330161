import React, { useState } from 'react';
import { IUsuarioDocumento } from '../../../../Interfaces/IUsuarioDocumento';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import { ColumnConfig, DataTable, Text } from 'grommet';
import ManageUsuariosDocumentosActions from './ManageUsuariosDocumentosActions';

export interface ManageUsuariosDocumentosTableProps {
  documentos: IUsuarioDocumento[];
  refreshData: () => void;
}

const ManageUsuariosDocumentosTable: React.FC<ManageUsuariosDocumentosTableProps> = ({ documentos, refreshData }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Nome',
    direction: 'asc',
  });

  const columns: ColumnConfig<IUsuarioDocumento>[] = [
    {
      property: 'Nome',
      primary: true,
      search: true,
      sortable: true,
      header: 'Nome',
      render: (datum: IUsuarioDocumento): React.ReactNode => (
        <Text>{datum.Nome}</Text>
      ),
    },
    {
      property: 'Descricao',
      primary: true,
      search: true,
      sortable: true,
      header: 'Descrição',
      render: (datum: IUsuarioDocumento): React.ReactNode => (
        <Text>{datum.Descricao}</Text>
      ),
    },
    {
      property: 'Ativo',
      primary: true,
      search: false,
      sortable: true,
      header: 'Ativo',
      render: (datum: IUsuarioDocumento): React.ReactNode => (
        <Text>{datum.Ativo ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'DataVencimento',
      primary: true,
      search: false,
      sortable: true,
      header: 'Vencimento?',
      render: (datum: IUsuarioDocumento): React.ReactNode => (
        <Text>{datum.Vencimento ? datum.DataVencimento : '---'}</Text>
      ),
    },
    {
      property: 'Tipo.Nome',
      primary: true,
      search: false,
      sortable: true,
      header: 'Tipo',
      render: (datum: IUsuarioDocumento): React.ReactNode => (
        <Text>{datum.Tipo?.Nome}</Text>
      ),
    },
    {
      property: 'Guid',
      primary: false,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IUsuarioDocumento): React.ReactNode => (
        <ManageUsuariosDocumentosActions
          documento={datum}
          refreshData={refreshData}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={documentos}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'small',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ManageUsuariosDocumentosTable.displayName = 'ManageUsuariosDocumentosTable';

export default ManageUsuariosDocumentosTable;
