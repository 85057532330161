import React from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import TopSectionLP from './components/TopSectionLP/TopSectionLP';
import MidSectiolLP from './components/MidSectionLP/MIdSectionLP';
import BottomSectionLP from './components/BottomSectionLP/BottomSectionLP';

export interface HomeProps {}

const Home: React.FC<HomeProps> = ({}): JSX.Element => {
  return (
    <>
      <PageWrapper>
        <TopSectionLP />
        <MidSectiolLP />
        <BottomSectionLP />
      </PageWrapper>
    </>
  );
};

Home.displayName = 'Home';

export default Home;
