import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';

const adicionarVideoAoProjetoInitialValues = (projetoGuid: string): IStreamingVideo => ({
  Title: '',
  Description: '',
  Projeto: {
    Guid: projetoGuid,
  },
});

export default adicionarVideoAoProjetoInitialValues;
