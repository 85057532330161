import { IEquipamento } from '../../../../Interfaces/IEquipamento';

const inserirEquipamentoInitialValues = (empresaGuid: string = '31c297c0-6c47-4e66-86eb-7cd7f99e586d'): IEquipamento => ({
  Nome: '',
  NumeroDeSerie: '',
  Ano: new Date().getFullYear(),
  Modelo: '',
  Certificado: '',
  Observacao: '',
  Ativo: true,
  TipoEquipamento: {
    Guid: '',
  },
  TipoEquipamentoGuid: '',
  Empresa: {
    Guid: empresaGuid,
  },
});

export default inserirEquipamentoInitialValues;