import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const inserirEquipamentoSubmit = async (values: IEquipamento): Promise<IBasicReturn> => {
  let apiResponse: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().post<IBasicReturn>('Empresa/AdicionarEquipamento', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default inserirEquipamentoSubmit;
