import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IEquipamento } from '../../../../../Interfaces/IEquipamento';
import adicionarProjetoEquipamentoSubmit from '../../Helpers/adicionarProjetoEquipamentoSubmit';
import ProjetoEquipamentoForm from '../../../Forms/ProjetoEquipamento/ProjetoEquipamentoForm';
import adicionarProjetoEquipamentoInitialValues from '../../Helpers/adicionarProjetoEquipamentoInitialValues';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { Box } from 'grommet';

export interface AdicionarProjetoEquipamentoProps {}

const AdicionarProjetoEquipamento: React.FC<AdicionarProjetoEquipamentoProps> = ({ }): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onCancel = (): void => {
    navigate(`/admin/manageProjetosEquipamentos/${guid}`);
  };

  const onSubmit = async (values: IEquipamento): Promise<void> => {
    setSubmitting(true);

    await adicionarProjetoEquipamentoSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Documento salvo com sucesso');

          if (onCancel) onCancel();
        } else {
          toast.error('Erro ao salvar documento');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        toast.error('Erro ao salvar documento');
        setSubmitting(false);
      });
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Equipamento ao Projeto"
      />
      <Box
        width="large"
      >
        <ProjetoEquipamentoForm
          initialValues={adicionarProjetoEquipamentoInitialValues(guid!)}
          submitting={submitting}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarProjetoEquipamento.displayName = 'AdicionarProjetoEquipamento';

export default AdicionarProjetoEquipamento;
