import { Box, Paragraph, Text } from 'grommet';
import styled from 'styled-components';

export const TopSectionImage = styled(Box)`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  height: auto;

  @media only screen and (max-width: 768px) {
    img {
      height: auto;
      width: 100%;
    }
  }
`;

export const TopSectionContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  max-width: 470px;
  gap: 16px;

  @media only screen and (max-width: 768px) {
    gap: 4px;
  }
`;

export const SmallTitle = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  color: #929a9f;
  text-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @media only screen and (max-width: 768px) {
    margin-top: 16px;
    font-size: 11px;
  }
`;

export const MainTitle = styled(Text)`
  font-size: 32px;
  font-weight: 600;
  line-height: 1.35;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Description = styled(Paragraph)`
  font-size: 16px;
  font-weight: 600;
  color: #929a9f;
  line-height: 1.65;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const TopSectionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
