import React from 'react';
import { Button } from 'grommet';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper/PageWrapper';
import {
  ErrorDescription,
  ErrorPageContainer,
  ErrorTag,
  ErrorTitle,
} from './Error403Styles';

export interface Error403Props {}

const Error403: React.FC<Error403Props> = ({ }): JSX.Element => {
  const navigate = useNavigate();

  const onVoltarHome = (): void => {
    navigate('/');
  };
  return (
    <PageWrapper justify="center">
      <ErrorPageContainer>
        <ErrorTag color="text-strong">403</ErrorTag>
        <ErrorTitle color="text-strong">Opss! Acesso negado.</ErrorTitle>
        <ErrorDescription color="text-strong">
          Desculpe, você não tem acesso a essa página. 
          Se o problema persistir, entre em contato com o administrador.
        </ErrorDescription>
        <Button
          primary
          label="VOLTAR PARA HOME"
          onClick={onVoltarHome}
        />
      </ErrorPageContainer>
    </PageWrapper>
  );
};

Error403.displayName = 'Error403';

export default Error403;
