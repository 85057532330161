import { IUser } from '../../../../Interfaces/IUser';

const insertUsurInitialValues: IUser = {
  Guid: '',
  FirstName: '',
  LastName: '',
  Email: '',
  ConfirmEmail: '',
  Active: true,
  Technician: false,
  Owner: false,
  TechDoc: '',
};

export default insertUsurInitialValues;
