import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const editarEmpresaSubmit = async (values: IEmpresa): Promise<IBasicReturn> => {
  let basicReturn: IBasicReturn<IEmpresa> = { Success: false };

  await MyAxiosInstance().post<IBasicReturn<IEmpresa>>('Empresa/EmpresaUpdateInfo', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = { Success: false };

      return basicReturn;
    });

  return basicReturn;
};

export default editarEmpresaSubmit;
