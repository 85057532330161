import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import getMinhaEmpresa from '../MinhaEmpresa/Helpers/getMinhaEmpresa';
import Loading from '../../Loading/Loading';
import EmpresaForm from '../../Forms/Empresa/EmpresaForm';
import { Box } from 'grommet';
import { useNavigate } from 'react-router-dom';
import editarEmpresaSubmit from '../../ManageEmpresas/Helpers/editarEmpresaSubmit';

export interface EditarMinhaEmpresaProps {}

const EditarMinhaEmpresa: React.FC<EditarMinhaEmpresaProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onCancel = (): void => {
    navigate('/auth/minhaEmpresa');
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [empresa, setEmpresa] = useState<IEmpresa>({});

  const getEmpresa = async (): Promise<void> => {
    setLoading(true);

    await getMinhaEmpresa()
      .then((data): void => {
        if (data.Success) {
          setEmpresa(data.Object!);
          setLoading(false);
        } else {
          toast.error('Erro ao buscar empresa');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar empresa');
        setLoading(false);
      });
  };

  const onSubmit = async (values: IEmpresa): Promise<void> => {
    await editarEmpresaSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Informações da empresa salvas com sucesso');
          onCancel();
        } else {
          toast.error('Erro ao salvar informações da empresa');
        }
      })
      .catch((): void => {
        toast.error('Erro ao salvar informações da empresa');
      });
  };

  useEffect((): void => {
    getEmpresa();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Editar Minha Empresa"
      />
      {
        (loading) ? (<Loading />) : (
          <Box
            width="large"
          >
            <EmpresaForm
              initialValues={empresa}
              onSubmit={onSubmit}
              onCancel={onCancel}
              editing
            />
          </Box>
        )
      }
    </PageWrapper>
  );
};

EditarMinhaEmpresa.displayName = 'EditarMinhaEmpresa';

export default EditarMinhaEmpresa;
