import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IPagination } from '../../../../Interfaces/IPagination';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const pesquisarPlantas = async (empresaGuid: string, searchTerm: string): Promise<IBasicReturn<IPagination<IPlanta[]>>> => {
  let plantas: IBasicReturn<IPagination<IPlanta[]>> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPagination<IPlanta[]>>>(`Empresa/PesquisarPlanta/${empresaGuid}/${searchTerm}`)
    .then((response): IBasicReturn<IPagination<IPlanta[]>> => {
      const { data } = response;

      plantas = data;

      return plantas;
    })
    .catch((): IBasicReturn<IPagination<IPlanta[]>> => {
      plantas.Success = false;

      return plantas;
    });

  return plantas;
};

export default pesquisarPlantas;
