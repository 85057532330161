import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import adicionarPlantaInitialValues from '../../Helpers/adicionarPlantaInitialValues';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import PlantaForm from '../../../Forms/Planta/PlantaForm';
import { Box } from 'grommet';
import adicionarPlantaSubmit from '../../Helpers/adicionarPlantaSubmit';

export interface AdicionarPlantaProps {}

const AdicionarPlanta: React.FC<AdicionarPlantaProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const onCancel = (): void => {
    navigate(`/owner/managePlantas/${guid}`);
  };

  const initialValues = adicionarPlantaInitialValues(guid!);

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Planta"
      />
      <Box
        width="large"
      >
        <PlantaForm
          initialValues={initialValues}
          onSubmit={adicionarPlantaSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarPlanta.displayName = 'AdicionarPlanta';

export default AdicionarPlanta;
