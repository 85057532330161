import React from 'react';
import { IUser } from '../../../Interfaces/IUser';
import { Box, Button, Text, Tip } from 'grommet';
import { CircleInformation } from 'grommet-icons';

export interface CriadoPorProps {
  criadoPor: IUser;
}

const CriadoPor: React.FC<CriadoPorProps> = ({ criadoPor }): JSX.Element => {
  return (
    <Tip
      // dropProps={{ align: { left: 'right' } }}
      plain
      content={
        <Box
          pad="small"
          gap="small"
          background={{ color: '#F3F3F3', opacity: 'strong' }}
          style={{ border: '1px solid #F3F3F3', borderRadius: 12, boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
        >
          <Text weight="bold" >Dados do usuário:</Text>
          <>
            <Text size="small" >
              {criadoPor.FirstName} {criadoPor.LastName}
            </Text>
            <Text size="small">{criadoPor.Email}</Text>
          </>
        </Box>
      }
    >
      <Box
        direction="row"
        align="center"
        gap='8px'
      >
        <Text>{criadoPor.FirstName}</Text>
        <Button
          icon={<CircleInformation size='18px' />}
          title="User Info"
        />
      </Box>
    </Tip>
  );
};

CriadoPor.displayName = 'CriadoPor';

export default CriadoPor;
