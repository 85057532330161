import React from 'react';
import { IProjetoDocumento } from '../../../../Interfaces/IProjetoDocumento';
import MediaCard from './MediaCard/MediaCard';

export interface ImageCardProps {
  image: IProjetoDocumento;
}

const ImageCard: React.FC<ImageCardProps> = ({ image }): JSX.Element => {
  const onImageClick = (): void => {
    window.open(`${import.meta.env.VITE_API_URL}/api/Empresa/DownloadDocumentoProjeto/${image.Guid}.${image.Upload?.UploadExtension}`, '_blank');
  };

  return (
    <MediaCard
      key={image.Guid}
      about={image.Observacao}
      name={image.Titulo}
      preview={`${import.meta.env.VITE_API_URL}/api/Empresa/DownloadDocumentoProjeto/${image.Guid}.${image.Upload?.UploadExtension}`}
      onClick={onImageClick}
    />
  );
};

ImageCard.displayName = 'ImageCard';

export default ImageCard;
