import {
  Box, Button, FormExtendedEvent, FormField, TextArea, TextInput,
} from 'grommet';
import React from 'react';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';
import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { toast } from 'react-toastify';
  
export interface PlantaFormProps {
  initialValues: IPlanta;
  onSubmit: (values: IPlanta) => Promise<IBasicReturn>;
  onCancel?: () => void;
}
  
const PlantaForm: React.FC<PlantaFormProps> = ({ initialValues, onSubmit, onCancel }): JSX.Element => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<IPlanta>(initialValues);
  const onChange = (nextValues: IPlanta): void => setValues(nextValues);

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IPlanta>): Promise<void> => {
    const { value } = evt;

    setSubmitting(true);

    await onSubmit(value)
      .then((data): void => {
        if (data.Success) {
          onClear();
          toast.success('Planta salva com sucesso');
        } else {
          toast.error('Erro ao salvar a planta');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        toast.error('Erro ao salvar a planta');
        setSubmitting(false);
      });
  };

  const validateLength = (value: string): string | undefined => {
    if (value && (value.length < 3 || value.length > 100)) {
      return 'O campo deve ter entre 3 e 100 caracteres';
    }
  };

  const activeChange = (value: boolean): void => {
    setValues({ ...values, Ativo: value });
  };
  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <FormField
        label="Nome da Planta"
        name="Nome"
        width="100%"
        validate={validateLength}
        required
      >
        <TextInput
          name="Nome"
          aria-label="Por favor informe o nome da Planta"
          title="Por favor informe o nome da Planta"
        />
      </FormField>

      <FormField
        label="Observação"
        name="Observacao"
        width="100%"
      >
        <TextArea
          name="Observacao"
          aria-label="Por favor insira observações"
          title="Por favor insira observações"
        />
      </FormField>

      <Box direction="row" gap="small" justify="between" align="start" wrap>
        <Box width="50%">
          <InterdevSwitch
            activeLabel="Ativo"
            inactiveLabel="Inativo"
            value={values.Ativo || false}
            onChange={activeChange}
            name="ativo"
            title="Alterar o Status da Planta"
            text="Status da planta"
          />
        </Box>
      </Box>

      <Box direction="row" gap="medium" justify="start" margin={{ top: 'medium', bottom: 'medium' }}>
        <Button
          type="submit"
          label="Salvar"
          title="Salvar"
          disabled={submitting}
          primary
        />
        <Button
          label="Cancelar"
          title="Cancelar"
          onClick={onClear}
        />
      </Box>
    </FormWrapper>
  );
};
  
PlantaForm.displayName = 'PlantaForm';
  
export default PlantaForm;
  