import React, { useReducer } from 'react';
import appContextReducer from './appContextReducer';
import initialContext from './initialContext';
import AppContext from './AppContext';
import { IUser } from '../../../../Interfaces/IUser';

export interface AppContextProviderProps {
  children: JSX.Element;
  context?: IUser;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({ children, context }): JSX.Element => {
  const [state, dispatch] = useReducer(appContextReducer, (context || initialContext));

  const value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.displayName = 'AppContextProvider';

export default AppContextProvider;
