import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IPagination } from '../../../../Interfaces/IPagination';
import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const pesquisarVideo = async (searchTerm: string): Promise<IBasicReturn<IPagination<IStreamingVideo[]>>> => {
  let videos: IBasicReturn<IPagination<IStreamingVideo[]>> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPagination<IStreamingVideo[]>>>(`Streaming/PesquisarVideo/${searchTerm}`)
    .then((response): IBasicReturn<IPagination<IStreamingVideo[]>> => {
      const { data } = response;

      videos = data;

      return videos;
    })
    .catch((): IBasicReturn<IPagination<IStreamingVideo[]>> => {
      videos.Success = false;

      return videos;
    });

  return videos;
};

export default pesquisarVideo;
