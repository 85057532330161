import React, { useEffect } from 'react';
import ThemeGrommet from './Theme';
import GlobalStyle from './GlobalStyles';
import ToastContainerConfig from '../ToastContainerConfig/ToastContainerConfig';
import AppStructure from './AppStructure';
import useAppContext from '../Context/useAppContext';
import GrommetWithStyles from './Styles/GrommetWithStyles';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import onGoTop from '../../../../utils/onGoTop';

export interface GrommetWrapperProps {
  children: JSX.Element;
}

const GrommetWrapper: React.FC<GrommetWrapperProps> = ({ children }): JSX.Element => {
  const {
    state: { Theme, Language, IsAuthenticated },
    dispatch,
  } = useAppContext();

  const { i18n } = useTranslation();

  const location = useLocation();

  useEffect((): void => {
    onGoTop();
  }, [location]);

  useEffect((): void => {
    i18n.changeLanguage(Language);
  }, [Language]);

  useEffect((): void => {
    if (IsAuthenticated) {
      dispatch({
        type: 'changeDrawer',
        value: true,
      });
    }
  }, []);

  return (
    <GrommetWithStyles
      full="min"
      theme={ThemeGrommet}
      themeMode={(Theme === 'light') ? 'light' : 'dark'}
    >
      <>
        <GlobalStyle />
        <ToastContainerConfig />
        <AppStructure>
          {children}
        </AppStructure>
      </>
    </GrommetWithStyles>
  );
};

GrommetWrapper.displayName = 'GrommetWrapper';

export default GrommetWrapper;
