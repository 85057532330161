import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import getDropDownPlantas from './Helpers/getDropDownPlantas';
import { IPlanta } from '../../../Interfaces/IPlanta';
import { FormField, Select, Spinner } from 'grommet';

export interface DropDownPlantasProps {
  name: string;
  label: string;
  title: string;
  empresaGuid: string;
  disabled?: boolean;
}

const DropDownPlantas: React.FC<DropDownPlantasProps> = ({
  name, label, title, empresaGuid, disabled,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [plantas, setPlantas] = useState<IPlanta[]>([]);

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    if (empresaGuid === '') {
      setLoading(false);
      return;
    }

    await getDropDownPlantas(empresaGuid)
      .then((data): void => {
        if (data.Success) {
          setPlantas(data.Object!);
        } else {
          toast.error('Erro ao buscar plantas');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar plantas');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  useEffect((): void => {
    getInitialData();
  }, [empresaGuid]);

  return (loading) ? (<Spinner />) : (
    <FormField
      label={label}
      name={name}
      width="100%"
      required
    >
      <Select
        name="PlantaGuid"
        aria-label={title}
        title={title}
        placeholder={label}
        options={plantas}
        valueKey={{ key: 'Guid', reduce: true }}
        labelKey="Nome"
        disabled={disabled}
      />
    </FormField>
  );
};

DropDownPlantas.displayName = 'DropDownPlantas';

export default DropDownPlantas;
