import React from 'react';
import { IUser } from '../../../../Interfaces/IUser';
import { Box, Text } from 'grommet';

export interface DropDownTecnicosOptionProps {
  user: IUser;
}

const DropDownTecnicosOption: React.FC<DropDownTecnicosOptionProps> = ({ user }): JSX.Element => {
  return (
    <Box
      direction="row"
      gap="xsmall"
    >
      <Text>{`${user.FirstName} ${user.LastName}`}</Text>
      <Text>{`(${user.TechDoc})`}</Text>
    </Box>
  );
};

DropDownTecnicosOption.displayName = 'DropDownTecnicosOption';

export default DropDownTecnicosOption;
