import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const onChangeActivePropertyForPlanta = async (plantaGuid: string): Promise<IBasicReturn> => {
  try {
    const response = await MyAxiosInstance().get<IBasicReturn>(`Empresa/ChangeActivePropertyForPlantas/${plantaGuid}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao alterar a propriedade ativa da planta:', error);
    return { Success: false };
  }
};

export default onChangeActivePropertyForPlanta;
