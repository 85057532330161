import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box } from 'grommet';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import Loading from '../../Loading/Loading';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import editarProjetoInitialValues from '../Helpers/editarProjetoInitialValues';
import editarProjetoSubmit from '../Helpers/editarProjetoSubmit';
import ProjetoForm from '../../Forms/Projeto/ProjetoForm';
import convertToDate from '../../../../utils/convertToDate';

export interface EditarProjetoProps {}

const EditarProjeto: React.FC<EditarProjetoProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [projeto, setProjeto] = useState<IProjeto>({});

  const onCancel = (): void => navigate('/admin/projetos');

  const getData = async (): Promise<void> => {
    await editarProjetoInitialValues(guid!)
      .then((data): void => {
        if (data.Success) {
          const { PrevisaoInicio, PrevisaoTermino } = data.Object!;

          const previsaoInicio: Date = convertToDate(PrevisaoInicio!);
          const previsaoTermino: Date = convertToDate(PrevisaoTermino!);

          const dadosParaEditar = {
            ...data.Object!,
            PrevisaoInicio: previsaoInicio.toISOString(),
            PrevisaoTermino: previsaoTermino.toISOString(),
          };

          setProjeto(dadosParaEditar);
          setLoading(false);
        } else {
          toast.error('Erro ao buscar informações do projeto');
          navigate('/admin/projetos');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar informações do projeto');
        navigate('/admin/projetos');
      });
  };

  const onSubmit = async (values: IProjeto): Promise<void> => {
    setSubmitting(true);
    await editarProjetoSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Projeto salvo com sucesso');
          onCancel();
        } else {
          toast.error('Erro ao salvar projeto');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        toast.error('Erro ao salvar projeto');
        setSubmitting(false);
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (loading) ? (<Loading />) : (
    <PageWrapper>
      <PageTitle
        Text="Editar Projeto"
      />
      <Box
        width="large"
      >
        <ProjetoForm
          initialValues={projeto}
          onSubmit={onSubmit}
          submitting={submitting}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

EditarProjeto.displayName = 'EditarProjeto';

export default EditarProjeto;
