import { Box, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Representantes', value: 400 },
  { name: 'Gestores', value: 300 },
  { name: 'Clientes', value: 300 },
  { name: 'Stakeholders', value: 200 },
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const renderColorfulLegendText = (value: string) => {
  return (
    <span style={{ color: '#596579', fontWeight: 500, padding: '10px' }}>
      {value}
    </span>
  );
};

export interface RadarChartProps {}

const RadarChart: React.FC<RadarChartProps> = ({}): JSX.Element => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      width={size === 'small' ? '100%' : '80%'}
      height={size === 'small' ? '300px' : '300px'}
      direction={size === 'small' ? 'column' : 'row'}
      margin="large"
    >
      <ResponsiveContainer>
      <PieChart >
      <Legend
          height={70}
          iconType="circle"
          layout="horizontal"
          verticalAlign="bottom"
          iconSize={12}
          formatter={renderColorfulLegendText}
        />
        <Pie
          data={data}
          cx={300}
          cy={100}
          innerRadius={50}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={2}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      </ResponsiveContainer>
      <ResponsiveContainer>
      <PieChart>
      <Legend
          height={70}
          iconType="circle"
          layout="horizontal"
          verticalAlign="bottom"
          iconSize={12}
          formatter={renderColorfulLegendText}
        />
        <Pie
          data={data}
          cx={300}
          cy={100}
          innerRadius={50}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={2}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

RadarChart.displayName = 'RadarChart';

export default RadarChart;
