import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { ITipoDeDocumento } from '../../../../Interfaces/ITipoDeDocumento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getDropDownTiposDeDocumentosProjetos = async (): Promise<IBasicReturn<ITipoDeDocumento[]>> => {
  let apiReturn: IBasicReturn<ITipoDeDocumento[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<ITipoDeDocumento[]>>('Empresa/DropDownTiposDocumentosDoProjeto')
    .then((response): IBasicReturn<ITipoDeDocumento[]> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn<ITipoDeDocumento[]> => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default getDropDownTiposDeDocumentosProjetos;
