import { Box, Text } from 'grommet';
import styled from 'styled-components';

export const Divider = styled.hr`
  border-top: .007px solid #929a9f;
  width: 90%;
`;

export const Title = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.35;
  color: #fff;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 10px;
    margin-bottom: 10px;
  }
`;

export const Description = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 1.5;

  @media only screen and (max-width: 768px) {
    font-size: 11px;
  }
`;

export const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 80px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
  }
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 600px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
  }
`;