import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const changeUsers = async (userGuid: string): Promise<IBasicReturn<IUser>> => {
  let result: IBasicReturn<IUser> = {
    Success: false,
  };

  await MyAxiosInstance().get<IBasicReturn<IUser>>(`Identity/SignInWithUserGuid/${userGuid}`)
    .then((response): IBasicReturn<IUser> => {
      const { data } = response;

      result = data;

      return result;
    })
    .catch((): IBasicReturn => {
      result.Success = false;

      return result;
    });

  return result;
};

export default changeUsers;
