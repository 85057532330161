import { IPlanta } from '../../../../Interfaces/IPlanta';

const adicionarPlantaInitialValues = (empresaGuid: string): IPlanta => {
  return {
    Empresa: {
      Guid: empresaGuid,
    },
    Nome: '',
    Observacao: '',
    Ativo: true,
  };
};

export default adicionarPlantaInitialValues;
