import React from 'react';
import { BotoomSectionContent, BottomSectionContainer, BottomSectionImage, Description, MainTitle, SmallTitle } from './BottomSectionLPStyles';
import { Button, Image } from 'grommet';
import { Next } from 'grommet-icons';

export interface BottomSectionLPProps {}

const BottomSectionLP: React.FC<BottomSectionLPProps> = ({}): JSX.Element => {
  return (
    <BottomSectionContainer>
      <BotoomSectionContent>
        <SmallTitle>Lorem Ipsum</SmallTitle>
        <MainTitle color="text-title">
        Lorem Ipsum
        </MainTitle>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation.
        </Description>
        <Button
            primary
            label="Saiba Mais"
            icon={<Next size="small" color="#fff" />}
            reverse
          />
      </BotoomSectionContent>
      <BottomSectionImage>
        <Image src="/bottom-image-lp.png" alt="" />
      </BottomSectionImage>
    </BottomSectionContainer>
  );
};

BottomSectionLP.displayName = 'BottomSectionLP';

export default BottomSectionLP;
