import React, { useState } from 'react';
import { IUser } from '../../../../Interfaces/IUser';
import projetoTecnicoExcluir from '../Helpers/projetoTecnicoExcluir';
import { toast } from 'react-toastify';
import { Box, Button } from 'grommet';
import { Trash } from 'grommet-icons';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';

export interface ProjetoTecnicosActionsProps {
  tecnico: IUser;
  refreshData: () => void;
}

const ProjetoTecnicosActions: React.FC<ProjetoTecnicosActionsProps> = ({ tecnico, refreshData }): JSX.Element => {
  const [action, setAction] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const changeShowModal = (): void => {
    setShowModal(!showModal);
  };

  const confirmModalTitle = (): string => {
    switch (action) {
      case 'changeActive':
        return 'Alterar propriedade ATIVO?';
      case 'delete':
        return 'Excluir equipamento?';
      default:
        return '';
    }
  };

  const confirmModalText = (): string => {
    switch (action) {
      case 'changeActive':
        return `O técnico ${tecnico.FirstName} ${tecnico.LastName} está ${tecnico.Active ? 'ATIVO' : 'INATIVO'}. Deseja alterar?`;
      case 'delete':
        return `Deseja remover o tecnico ${tecnico.FirstName} ${tecnico.LastName} do projeto?`;
      default:
        return '';
    }
  };

  const onDeleteTecnico = async (): Promise<void> => {
    await projetoTecnicoExcluir(tecnico.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Técnico excluído com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir o documento');
        }
      })
      .catch((): void => {
        toast.error('Erro ao excluir o documento');
      });
  };

  const onDeleteClick = (): void => {
    setAction('delete');
    changeShowModal();
  };

  const onConfirm = (): void => {
    switch (action) {
      case 'changeActive':
        // changeActiveProp();
        break;
      case 'delete':
        onDeleteTecnico();
        break;
      default:
        break;
    }

    changeShowModal();
  };

  return (
    <>
      <Box
        direction="row"
        gap="none"
        pad="none"
      >
        <Button
          icon={<Trash size='16px' />}
          onClick={onDeleteClick}
          title="Excluir técnico"
        />
      </Box>
      {
        (showModal) && (
          <ConfirmModal
            title={confirmModalTitle()}
            text={confirmModalText()}
            onCancel={changeShowModal}
            onConfirm={onConfirm}
          />
        )
      }
    </>
  );
};

ProjetoTecnicosActions.displayName = 'ProjetoTecnicosActions';

export default ProjetoTecnicosActions;
