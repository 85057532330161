import { Box, Button, Text } from 'grommet';
import styled from 'styled-components';

export const CompanyNameText = styled(Text)`
  font-size: 12px;
  color: #ADACC1;
  line-height: 1.35;

  @media only screen and (max-width: 768px) {
  }
`;


export const TagDocNumber = styled(Button)`
  display: flex;
  width: auto;
  border-radius: 4px;
  font-size: 12px;
  padding: 02px 06px;
  border: 1px #47435F solid;
  color: #47435F;

  :hover {
    color: #47435F
  }
  
  :active {
    color: #47435F
  }

  @media only screen and (max-width: 768px) {
  }
`;

export const ActionButton = styled(Button)`
  display: flex;
  width: auto;
  border-radius: 4px;
  font-size: 12px;
  padding: 02px 06px;
  color: #47435F;

  :hover {
    color: #47435F
  }
  
  :active {
    color: #47435F
  }

  @media only screen and (max-width: 768px) {
  }
`;

export const ProfileCardFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px #e2e2e2 solid;
  width: 100%;
  padding: 16px 0 0;

  @media only screen and (max-width: 768px) {
  }
`;

export const TagButtonStatus = styled(Button)`
  display: flex;
  width: min-content;
  border-radius: 4px;
  font-size: 14px;
  padding: 04px 08px;
  pointer-events: none;

  @media only screen and (max-width: 768px) {
  }
`;

export const DateProjectText = styled(Text)`
  font-size: 12px;
  color: #ADACC1;
  line-height: 1.35;
  margin: 0;
  align-self: end;
  font-weight: 600;

  @media only screen and (max-width: 768px) {
  }
`;

export const ProfileCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 396px;
  height: 258px;

  :hover {
    border: 1px #4954E8 solid;
  }

  @media only screen and (max-width: 768px) {
  }
`;