import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const onLoginSubmit = async (values: IUser): Promise<IBasicReturn<IUser>> => {
  let result: IBasicReturn<IUser> = {
    Success: false,
  };

  await MyAxiosInstance().post<IBasicReturn<IUser>>('Identity/SignIn', values)
    .then((response): IBasicReturn<IUser> => {
      const { data } = response;

      result = data;

      return result;
    })
    .catch((): IBasicReturn => {
      result.Success = false;

      return result;
    });

  return result;
};

export default onLoginSubmit;
