import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IProjetoDocumento } from '../../../../Interfaces/IProjetoDocumento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const adicionarDocumentoProjetoSubmit = async (values: IProjetoDocumento): Promise<IBasicReturn> => {
  let apiResponse: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().post<IBasicReturn>('Empresa/AdicionarDocumentoProjeto', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default adicionarDocumentoProjetoSubmit;
