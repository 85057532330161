import React from 'react';
import { Box } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { IUser } from '../../../../../Interfaces/IUser';
import UsuarioForm from '../../../Forms/Usuario/UsuarioForm';
import convidarUsuarioInitialValues from '../Helpers/convidarUsuarioInitialValues';
import convidarUsuarioSubmit from '../Helpers/convidarUsuarioSubmit';

export interface InserirUsuarioProps {}

const InserirUsuario: React.FC<InserirUsuarioProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const onCancel = (): void => navigate('/admin/usuarios');

  const onSubmit = async (values: IUser): Promise<void> => {
    await convidarUsuarioSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Informações do usuário salvas com sucesso');
          navigate('/admin/usuarios');
        } else {
          toast.error('Erro ao salvar informações do usuário');
        }
      })
      .catch((): void => {
        toast.error('Erro ao salvar informações do usuário');
      });
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Inserir usuário"
      />
      <Box
        width="large"
      >
        <UsuarioForm
          initialValues={convidarUsuarioInitialValues}
          onSubmit={onSubmit}
          onCancel={onCancel}
          editing={false}
          convidando
        />
      </Box>
    </PageWrapper>
  );
};

InserirUsuario.displayName = 'InserirUsuario';

export default InserirUsuario;
