import {
  Box, Button, FormExtendedEvent, FormField, TextInput,
} from 'grommet';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { IUser } from '../../../../Interfaces/IUser';
import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import useAppContext from '../../Template/Context/useAppContext';
import { onEmailValidationRegExp } from '../../../../utils/onEmailRegexValidation';

export interface AlterarEmailFormProps {
  initialValues: IUser;
  onSubmit: (values: IUser) => Promise<IBasicReturn<boolean>>;
  onCancel: () => void;
  emailAtual: string;
}

const AlterarEmailForm: React.FC<AlterarEmailFormProps> = ({ onCancel, initialValues, onSubmit, emailAtual }): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState(initialValues);
  const onChange = (newValues: IUser): void => {
    setValues(newValues);
  };

  const navigate = useNavigate();

  const onGoLogin = (): void => {
    navigate('/login');
  };

  const {
    state: { },
    dispatch,
  } = useAppContext();

  const leavePage = (): void => {
    dispatch({
      type: 'changeAuth',
      value: false,
    });

    dispatch({
      type: 'changeDrawer',
      value: false,
    });

    dispatch({
      type: 'changeFirstName',
      value: '',
    });

    dispatch({
      type: 'changeLastName',
      value: '',
    });

    dispatch({
      type: 'changeEmail',
      value: '',
    });

    dispatch({
      type: 'changeOwner',
      value: false,
    });

    dispatch({
      type: 'changeValidated',
      value: false,
    });

    onGoLogin();
  };

  const handleSubmit = async (evt: FormExtendedEvent<IUser>): Promise<void> => {
    const { value } = evt;
    setSubmitting(true);

    if (value.Email === emailAtual) {
      toast.success('Novo email igual ao atual');
      setSubmitting(false);
      return;
    }

    await onSubmit(value)
      .then((data): void => {
        if (data.Success && data.Object) {
          toast.success('Email alterado com sucesso');
          leavePage();
        } else {
          if (data.Code === '50400') {
            toast.error('Erro ao alterar o Email. Email já cadastrado');
            setSubmitting(false);
            return;
          }

          toast.error('Erro ao alterar o Email. Provavelmente a senha está errada');
          setSubmitting(false);
        }
      })
      .catch((): void => {
        setSubmitting(false);
        toast.error('Erro ao alterar o nome');
      })
    ;
  };

  const validatePassword = (value: string): string | undefined => {
    if (value.length < 6) {
      return 'A senha deve ter no mínimo 6 caracteres';
    }
    if (value.length > 15) {
      return 'A senha deve ter no máximo 15 caracteres';
    }
  };

  const validateConfirmEmail = (value: string): string | undefined => {
    if (value !== values.Email) {
      return 'Email e Confirmar email não conferem';
    }
  };

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <FormField
        label="Senha Atual"
        name="Password"
        width="100%"
        validate={validatePassword}
        required
      >
        <TextInput
          name="Password"
          type="password"
          aria-label="Por favor informe sua senha atual"
          title="Por favor informe sua senha atual"
          placeholder="******"
        />
      </FormField>

      <FormField
        label="Email"
        name="Email"
        width="100%"
        validate={onEmailValidationRegExp('Email inválido')}
        required
      >
        <TextInput
          name="Email"
          type="email"
          aria-label="Por favor informe seu email"
          title="Por favor informe seu email"
          placeholder="mail@mail.com"
        />
      </FormField>

      <FormField
        label="Confirmar email"
        name="ConfirmEmail"
        width="100%"
        validate={validateConfirmEmail}
        required
      >
        <TextInput
          type="email"
          name="ConfirmEmail"
          aria-label="Por favor confirme seu email"
          title="Por favor confirme seu email"
          placeholder="john@doe.com"
        />
      </FormField>

      <Box
        direction="row"
        gap="small"
        justify="end"
        margin={{
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <Button
          type="submit"
          label="Salvar"
          title="Salvar"
          disabled={submitting}
          primary
          reverse
        />
        <Button
          type="button"
          label="Cancelar"
          title="Cancelar"
          disabled={submitting}
          onClick={onCancel}
          secondary
        />
      </Box>
    </FormWrapper>
  );
};

AlterarEmailForm.displayName = 'AlterarEmailForm';

export default AlterarEmailForm;
