import React from 'react';
import { StatsBoxItemTitle, StatsBoxItemWrapper, StatsBoxNumbers, StatsBoxNumbersInfo, StatsBoxNumbersMain } from '../StatsBoxStiles';

export interface StatsBoxItemProps {
  id?: string;
  title?: string;
  result?: string;
  resultPercent?: string;
}

const StatsBoxItem: React.FC<StatsBoxItemProps> = ({ result, resultPercent, title,
}): JSX.Element => {
  return (
    <StatsBoxItemWrapper>
        <StatsBoxItemTitle>{title}</StatsBoxItemTitle>
        <StatsBoxNumbers>
          <StatsBoxNumbersMain>{result}</StatsBoxNumbersMain>
          <StatsBoxNumbersInfo>+{resultPercent}%</StatsBoxNumbersInfo>
        </StatsBoxNumbers>
      </StatsBoxItemWrapper>
      
  );
};

StatsBoxItem.displayName = 'StatsBoxItem';

export default StatsBoxItem;
