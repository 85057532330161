import { Box, Button } from 'grommet';
import React, { useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IUser } from '../../../Interfaces/IUser';
import { IPagination, Pagination as PaginationClass } from '../../../Interfaces/IPagination';
import getUsersForManagement from './Helpers/getUsersForManagement';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import totalPages from '../Pagination/Helpers/totalPages';
import PageSize from '../Pagination/Components/PageSize';
import ManageUsersTable from './Components/ManageUsersTable';
import Alert from '../Alert/Alert';
import SearchUsersByTerm from './Components/SearchUsersByTerm/SearchUsersByTerm';
import { Add } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';

export interface ManageUsersProps {}

const ManageUsers: React.FC<ManageUsersProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onAddUsuarioClick = (): void => {
    navigate('/owner/inserirUsuario');
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<IPagination<IUser[]>>(new PaginationClass(1, 30, 30, []));

  const initialPagination: IPagination<IUser[]> = new PaginationClass(1, 30, 30);

  const getUsers = async (pagination: IPagination = initialPagination): Promise<void> => {
    setLoading(true);

    await getUsersForManagement(pagination)
      .then((data): void => {
        if (data.Success) {
          setUsers(data.Object!);
          setLoading(false);
        }
      })
      .catch((): void => {
        setUsers(initialPagination);
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(parseInt(newPage, 10), users.PageSize);
    getUsers(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getUsers(newPagination);
  };

  useEffect((): void => {
    getUsers();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Gerenciamento de usuários"
      />
      <Box
        width="xlarge"
        align="end"
      >
        <Button
          primary
          label="Adicionar Usuário"
          title="Adicionar Usuário"
          onClick={onAddUsuarioClick}
          icon={<Add />}
          reverse
        />
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
            }}
          >
            <Alert
              text="Alterar qualquer propriedade de um usuário causa desconexão imediata!"
            />
            <SearchUsersByTerm
              refreshData={getUsers}
              setResults={setUsers}
            />
            <Pagination
              page={users.Page!}
              last={totalPages(users.Total!, users.PageSize!)}
              onChange={updatePage}
            />
            <ManageUsersTable
              users={users.Object!}
              refreshData={getUsers}
            />
            <PageSize
              size={users.PageSize!}
              onChange={updatePageSize}
            />
            <Pagination
              page={users.Page!}
              last={totalPages(users.Total!, users.PageSize!)}
              onChange={updatePage}
            />
          </Box>
        )
      }
    </PageWrapper>
  );
};

ManageUsers.displayName = 'ManageUsers';

export default ManageUsers;
