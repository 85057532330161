import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const passwordRecoverySendCode = async (email: string): Promise<IBasicReturn> => {
  let users: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn>(`Identity/StartPasswordRecovery/${email}`)
    .then((response): IBasicReturn => {
      const { data } = response;

      users = data;

      return users;
    })
    .catch((): IBasicReturn => {
      users.Success = false;

      return users;
    });

  return users;
};

export default passwordRecoverySendCode;
