import { BasicReturn, IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../utils/AxiosInstance';


const onTwoFactorCodeValidate = async (values: IUser): Promise<IBasicReturn<IUser>> =>
  MyAxiosInstance().post<IBasicReturn<IUser>>('Identity/TwoFactorCodeValidate', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      return data;
    })
    .catch((): IBasicReturn => new BasicReturn(false));

export default onTwoFactorCodeValidate;
