import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getDropDownEmpresas = async (): Promise<IBasicReturn<IEmpresa[]>> => {
  let apiReturn: IBasicReturn<IEmpresa[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IEmpresa[]>>('Empresa/DropDownEmpresas')
    .then((response): IBasicReturn<IEmpresa[]> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn<IEmpresa[]> => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default getDropDownEmpresas;
