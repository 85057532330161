import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import { IPagination } from '../../../../Interfaces/IPagination';
import { IUsuarioDocumento } from '../../../../Interfaces/IUsuarioDocumento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const pesquisarUsuariosDocumentos = async (Guid: string, searchTerm: string): Promise<IBasicReturn<IPagination<IUsuarioDocumento[]>>> => {
  let documentos: IBasicReturn<IPagination<IEmpresaDocumento[]>> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPagination<IUsuarioDocumento[]>>>(`Identity/PesquisarUsuariosDocumentos/${Guid}/${searchTerm}`)
    .then((response): IBasicReturn<IPagination<IUsuarioDocumento[]>> => {
      const { data } = response;

      documentos = data;

      return documentos;
    })
    .catch((): IBasicReturn<IPagination<IUsuarioDocumento[]>> => {
      documentos.Success = false;

      return documentos;
    });

  return documentos;
};

export default pesquisarUsuariosDocumentos;
