import { Box } from 'grommet';
import styled from 'styled-components';

const SidebarNavWithStyles = styled(Box)`
  height: 100vh;
  width: 400px;
  position: sticky;
  top: 0;
`;

export default SidebarNavWithStyles;
