import React from 'react';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import { Anchor } from 'grommet';
import { useNavigate } from 'react-router-dom';

export interface ManageEmpresasTotalPlantasProps {
  empresa: IEmpresa;
}

const ManageEmpresasTotalPlantas: React.FC<ManageEmpresasTotalPlantasProps> = ({ empresa }): JSX.Element => {
  const navigate = useNavigate();
  
  const onPlantasClick = (): void => {
    navigate(`/owner/managePlantas/${empresa.Guid}`);
  };

  return (
    <Anchor
      title="Clique para ver as plantas"
      onClick={onPlantasClick}
    >
      {empresa.TotalPlantas || 0}
    </Anchor>
  );
};

ManageEmpresasTotalPlantas.displayName = 'ManageEmpresasTotalPlantas';

export default ManageEmpresasTotalPlantas;
