import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Paragraph } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IEquipamento } from '../../../Interfaces/IEquipamento';
import getProjetoEquipamentos from './Helpers/getProjetoEquipamentos';
import { toast } from 'react-toastify';
import Loading from '../Loading/Loading';
import ProjetoEquipamentosDataTable from './Components/ProjetoEquipamentosDataTable';

export interface ManageProjetoEquipamentosProps {}

const ManageProjetoEquipamentos: React.FC<
ManageProjetoEquipamentosProps
> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  // const onAddEquipamentoClick = (): void => {
  //   navigate(`/admin/adicionarProjetosEquipamento/${guid}`);
  // };

  const onBackToProjetos = (): void => {
    navigate('/admin/projetos');
  };

  const [equipamentos, setEquipamentos] = useState<IEquipamento[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async (): Promise<void> => {
    setLoading(true);

    await getProjetoEquipamentos(guid!)
      .then((data): void => {
        if (data.Success) {
          setEquipamentos(data.Object!);
        } else {
          toast.error('Erro ao buscar equipamentos do projeto');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar equipamentos do projeto');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Gerenciar Equipamentos do Projeto" />
        {/* <Button
          primary
          label="Adicionar"
          title="Adicionar Equipamento"
          onClick={onAddEquipamentoClick}
          icon={<Add size="16px" />}
        /> */}
      </Box>
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur. 
        </Paragraph>
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <Box
          fill="horizontal"
          direction="column"
          gap="none"
          margin={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <ProjetoEquipamentosDataTable
            refreshData={getData}
            equipamentos={equipamentos}
            completo
          />
        </Box>
      )}

<Box
        width="100%"
        align="center"
        direction="row"
        gap="xsmall"
        justify="start"
      >
        <Button
          primary
          label="Voltar"
          title="Voltar"
          onClick={onBackToProjetos}
          icon={<LinkPrevious  size="16px"/>}
        />
      </Box>
    </PageWrapper>
  );
};

ManageProjetoEquipamentos.displayName = 'ManageProjetoEquipamentos';

export default ManageProjetoEquipamentos;
