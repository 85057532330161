import React from 'react';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProjetoDocumentoForm from '../../Forms/ProjetoDocumento/ProjetoDocumentoForm';
import adicionarDocumentoProjetoInitialValues from '../Helpers/adicionarDocumentoProjetoInitialValues';
import adicionarDocumentoProjetoSubmit from '../Helpers/adicionarDocumentoProjetoSubmit';
import { IProjetoDocumento } from '../../../../Interfaces/IProjetoDocumento';

export interface AdicionarDocumentoDoProjetoProps {}

const AdicionarDocumentoDoProjeto: React.FC<AdicionarDocumentoDoProjetoProps> = ({}): JSX.Element => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onCancel = (): void => {
    navigate(`/admin/manageProjetosDocumentos/${guid}`);
  };

  const onSubmit = async (values: IProjetoDocumento): Promise<void> => {

    await adicionarDocumentoProjetoSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Documento salvo com sucesso');

          if (onCancel) onCancel();
        } else {
          toast.error('Erro ao salvar documento');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        toast.error('Erro ao salvar documento');
        setSubmitting(false);
      });
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Documento do Projeto"
      />
      <ProjetoDocumentoForm
        initialValues={adicionarDocumentoProjetoInitialValues(guid!)}
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitting={submitting}
      />
    </PageWrapper>
  );
};

AdicionarDocumentoDoProjeto.displayName = 'AdicionarDocumentoDoProjeto';

export default AdicionarDocumentoDoProjeto;
