import React, { useState } from 'react';
import { Box, Image } from 'grommet';
import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';
import { MediaAboutText, MediaCardContainer, MediaNameText } from './MediaCard/MediaCardStyles';
import VideoLayer from '../../ManageVideos/Components/VideoLayer/VideoLayer';

export interface VideoCardProps {
  video: IStreamingVideo;
}

const VideoCard: React.FC<VideoCardProps> = ({ video }): JSX.Element => {
  const [showVideo, setShowVideo] = useState(false);
  const changeShowVideo = () => {
    setShowVideo(!showVideo);
  };

  return (
    <>
      <MediaCardContainer
        hoverIndicator
        onClick={changeShowVideo}
      >
        <Box alignSelf="center">
          <Image
            src={`https://image.mux.com/${video.MuxPlaybackId}/thumbnail.png?time=3`}
            alt="Midía para visualização"
            fill
            style={{
              width: '100%',
              justifySelf: 'center !important',
              alignSelf: 'center',
            }}
          />
        </Box>
        <MediaNameText>{video.Title}</MediaNameText>
        <MediaAboutText>{video.Description}</MediaAboutText>
      </MediaCardContainer>
      {
        (showVideo) && (
          <VideoLayer
            video={video}
            onCloseLayer={changeShowVideo}
          />
        )
      }
    </>
  );
};

VideoCard.displayName = 'VideoCard';

export default VideoCard;
