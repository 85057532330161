import { Anchor, Box, Button, Text } from 'grommet';
import styled from 'styled-components';

export const AnchorInterdev = styled(Anchor)`
  font-size: 16px;
  margin-bottom: 6px;
  padding-left: 08px;
  font-weight: 400;
  color: #929a9f;

  @media only screen and (max-width: 768px) {
  }
`;

export const SideBarCustomizedButton = styled(Button)`
  display: flex;
  background-color: #061024;
  border: #061024;
  border-radius: 4px;
  color: white !important;
  font-size: 14px;
  padding: 12px 16px 12px 12px;

  :hover {
    background-color: #5D30A4;
    border: #5D30A4;
  }

  &.active {
    background-color: #2D126D;
    border: #4954E8;
  }

  @media only screen and (max-width: 768px) {}
`;

export const TitleSectionSideBar = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  margin: 8px 0;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const SectionSideBarContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-top: 50px;

  @media only screen and (max-width: 768px) {
  }
`;
