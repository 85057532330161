import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FormField, Select, Spinner } from 'grommet';
import { ITipoDeDocumento } from '../../../Interfaces/ITipoDeDocumento';
import getDropDownTiposDeDocumentosProjetos from './Helpers/getDropDownTiposDeDocumentosProjetos';

export interface DropDownTipoDocumentoProjetoProps {
  name: string;
  label: string;
  title: string;
  disabled?: boolean;
}

const DropDownTipoDocumentoProjeto: React.FC<DropDownTipoDocumentoProjetoProps> = ({
  name, label, title, disabled,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tiposDeDocumentos, setTiposDeDocumentos] = useState<ITipoDeDocumento[]>([]);

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await getDropDownTiposDeDocumentosProjetos()
      .then((data): void => {
        if (data.Success) {
          setTiposDeDocumentos(data.Object!);
        } else {
          toast.error('Erro ao buscar tipos de documentos');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar tipos de documentos');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (loading) ? (<Spinner />) : (
    <FormField
      label={label}
      name={name}
      width="100%"
      required
    >
      <Select
        name="TipoDocumento"
        aria-label={title}
        title={title}
        placeholder={label}
        options={tiposDeDocumentos}
        valueKey={{ key: 'Guid', reduce: true }}
        labelKey="Nome"
        disabled={disabled}
      />
    </FormField>
  );
};

DropDownTipoDocumentoProjeto.displayName = 'DropDownTipoDocumentoProjeto';

export default DropDownTipoDocumentoProjeto;
