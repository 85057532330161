import { Box, Button } from 'grommet';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Add } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import { IPagination, Pagination as PaginationClass } from '../../../../Interfaces/IPagination';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import getPlantasPorUsuario from './Helpers/getPlantasPorUsuario';
import Loading from '../../Loading/Loading';
import ManagePlantasTable from '../../ManagePlantas/Components/ManagePlantasTable';
import PageSize from '../../Pagination/Components/PageSize';
import totalPages from '../../Pagination/Helpers/totalPages';
import Pagination from '../../Pagination/Pagination';

export interface MinhasPlantasProps {}

const MinhasPlantas: React.FC<MinhasPlantasProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onAddPlantaClik = (): void => {
    navigate('/admin/adicionarPlanta');
  };

  const [plantas, setPlantas] = useState<IPagination<IPlanta[]>>(new PaginationClass(1, 30, 30, []));
  const [loading, setLoading] = useState<boolean>(true);

  // const setData = (data: IPagination<IPlanta[]>): void => {
  //   setPlantas(data);
  // };

  const initialPagination: IPagination<IPlanta[]> = new PaginationClass(1, 30, 30);

  const getData = async (pagination: IPagination = initialPagination): Promise<void> => {
    setLoading(true);

    await getPlantasPorUsuario(pagination)
      .then((data): void => {
        if (data.Success) {
          setPlantas(data.Object!);
        } else {
          toast.error('Erro ao buscar plantas');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar plantas');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(parseInt(newPage, 10), plantas.PageSize);
    getData(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getData(newPagination);
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Minhas Plantas"
      />
      <Box
        width="xlarge"
        align="end"
      >
        <Button
          primary
          label="Adicionar Planta"
          title="Adicionar Planta"
          onClick={onAddPlantaClik}
          icon={<Add />}
          reverse
        />
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
            }}
          >
            {/* <PesquisarPlantas
              refreshData={getData}
              setResults={setData}
              empresaGuid={guid!}
            /> */}
            <ManagePlantasTable
              plantas={plantas.Object || []}
              refreshData={getData}
            />
            <PageSize
              size={plantas.PageSize!}
              onChange={updatePageSize}
            />
            <Pagination
              page={plantas.Page!}
              last={totalPages(plantas.Total || 0, plantas.PageSize!)}
              onChange={updatePage}
            />
          </Box>
        )
      }
    </PageWrapper>
  );
};

MinhasPlantas.displayName = 'MinhasPlantas';

export default MinhasPlantas;
