import { Box } from 'grommet';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppsRounded, Cpu, DocumentText, Group, Schedule, Workshop } from 'grommet-icons';
import { SideBarCustomizedButton } from '../SideBarNavStyles';
import AdminChecker from '../../../../../AdminChecker/AdminChecker';
import useAppContext from '../../../../Context/useAppContext';

export interface SidebarLinksFornecedorProps {}

const SidebarLinksFornecedor: React.FC<SidebarLinksFornecedorProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    state: { Admin },
  } = useAppContext();

  const checkifRouteIsActive = (route: string): string => {
    if (location.pathname === route) {
      return 'active';
    }
    return '';
  };

  // /* Projetos */
  const onProjetosClick = (): void => {
    if (Admin) {
      navigate('/admin/projetos');
    } else {
      navigate('/auth/projetos');
    }
  };
  // /* /Projetos */

  const onVencimentosClick = (): void => {
    navigate('/admin/vencimentos');
  };

  // /* Equipamentos */
  const onAdminEquipamentosClick = (): void => {
    navigate('/admin/manageEquipamentos');
  };
  // /* /Equipamentos */

  // /* Administração */
  const onMinhaEmpresaClick = (): void => {
    navigate('/auth/minhaEmpresa');
  };

  const onMeusDocumentosClick = (): void => {
    navigate('/admin/meusDocumentos');
  };

  const onUsuariosDaMinhaEmpresaClick = (): void => {
    navigate('/admin/usuarios');
  };
  // /* /Administração */

  return (
    <Box
      gap="6px"
      margin={{
        bottom: '32px',
      }}
      width="100%"
    >
      <SideBarCustomizedButton
        primary
        label="Administração"
        icon={<AppsRounded size="14px" />}
        onClick={onMinhaEmpresaClick}
        className={checkifRouteIsActive('/auth/minhaEmpresa')}
      />
      <AdminChecker>
        <SideBarCustomizedButton
          primary
          label="Usuários"
          icon={<Group size="14px" />}
          onClick={onUsuariosDaMinhaEmpresaClick}
          className={checkifRouteIsActive('/admin/usuarios')}
        />
      </AdminChecker>
      <AdminChecker>
        <SideBarCustomizedButton
          primary
          label="Documentos"
          icon={<DocumentText size="14px" />}
          onClick={onMeusDocumentosClick}
          className={checkifRouteIsActive('/admin/meusDocumentos')}
        />
      </AdminChecker>
      <AdminChecker>
        <SideBarCustomizedButton
          primary
          label="Equipamentos"
          icon={<Cpu size="14px" />}
          onClick={onAdminEquipamentosClick}
          className={checkifRouteIsActive('/admin/manageEquipamentos')}
        />
      </AdminChecker>
      <SideBarCustomizedButton
        primary
        label="Projetos"
        icon={<Workshop size="14px" />}
        onClick={onProjetosClick}
        className={checkifRouteIsActive('/admin/projetos')}
      />
      <AdminChecker>
        <SideBarCustomizedButton
          primary
          label="Vencimentos"
          icon={<Schedule size="14px" />}
          onClick={onVencimentosClick}
          className={checkifRouteIsActive('/admin/vencimentos')}
        />
      </AdminChecker>
    </Box>
  );
};

SidebarLinksFornecedor.displayName = 'SidebarLinksFornecedor';

export default SidebarLinksFornecedor;
