import React from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import TwoFactorCodeValidation, { TwoFactorCodeValidationProps } from '../Forms/TwoFactorCodeValidation/TwoFactorCodeValidation';
import PageTitle from '../PageTitle/PageTitle';
import { Paragraph } from 'grommet';

export interface TwoFactorValidationPageProps extends TwoFactorCodeValidationProps {}

const TwoFactorValidationPage: React.FC<TwoFactorValidationPageProps> = ({ initialValues, onError, setValidated }): JSX.Element => {
  return (
    <PageWrapper>
      <PageTitle
        Text="Autenticação em dois fatores"
      />
      <Paragraph fill>
        A autenticação em dois fatores é uma camada adicional de segurança para sua conta.
      </Paragraph>
      <TwoFactorCodeValidation
        initialValues={initialValues}
        setValidated={setValidated}
        onError={onError}
      />
    </PageWrapper>
  );
};

TwoFactorValidationPage.displayName = 'TwoFactorValidationPage';

export default TwoFactorValidationPage;
