import {
  Box, Button, Heading, Layer, Paragraph,
} from 'grommet';
import { MailOption } from 'grommet-icons';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { IUser } from '../../../../../Interfaces/IUser';
import ChangeEmailFromAdminViewForm from '../../../Forms/ChangeEmailFromAdminView/ChangeEmailFromAdminViewForm';
import onChangeEmailFromAdminView from './Helpers/onChangeEmailFromAdminView';

export interface ChangeEmailProps {
  user: IUser;
  refreshData: () => void;
}

const ChangeEmail: React.FC<ChangeEmailProps> = ({ user, refreshData }): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const changeShowModal = (): void => {
    setShowModal(!showModal);
  };

  const initialValues: IUser = {
    Guid: user.Guid,
    Email: '',
    ConfirmEmail: '',
  };

  const onSubmit = async (values: IUser): Promise<void> => {
    await onChangeEmailFromAdminView(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('E-mail do usuário alterado com sucesso');
          changeShowModal();
          refreshData();
        } else {
          toast.error('Erro ao alterar o e-mail do usuário');
        }
      })
      .catch((): void => {
        toast.error('Erro ao alterar o e-mail do usuário');
      });
  };

  return (
    <>
      <Button
        icon={<MailOption size='16px' />}
        title="Alterar email"
        onClick={changeShowModal}
      />
      {
        (showModal) && (
          <Layer
            position="center"
            onClickOutside={changeShowModal}
            onEsc={changeShowModal}
          >
            <Box
              pad="medium"
              gap="small"
              width="large"
            >
              <Heading
                level={4}
                margin="none"
                textAlign="start"
                fill
              >
                Deseja alterar o e-mail?
              </Heading>
              <Paragraph fill>
                Você está alterando o e-mail do usuário
                {`${user.FirstName} ${user.LastName} (${user.Email})`}.
                <br /> 
                <strong>Isto irá desconectá-lo do sistema</strong>.
              </Paragraph>
              <ChangeEmailFromAdminViewForm
                initialValues={initialValues}
                closeModal={changeShowModal}
                onSubmit={onSubmit}
              />
            </Box>
          </Layer>
        )
      }
    </>
  );
};

ChangeEmail.displayName = 'ChangeEmail';

export default ChangeEmail;
