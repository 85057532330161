import {
  Box, Button, Layer, RangeInput, Text,
} from 'grommet';
import React, { ChangeEvent, useCallback, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { Close } from 'grommet-icons';
import getCroppedImg from '../Helpers/getCroppedImage';
import PageTitle from '../../PageTitle/PageTitle';

export interface ImageEditorLayerProps {
  onCloseLayer: () => void;
  b64Image: string;
  aspect?: number;
  onSaveResult?: (b64: string) => void;
}

const ImageEditorLayer: React.FC<ImageEditorLayerProps> = ({ onCloseLayer, b64Image, onSaveResult, aspect = 1 }): JSX.Element => {
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [crop, setCrop] = useState<Point>({
    x: 0, y: 0,
  });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0, height: 0, x: 0, y: 0,
  });

  const onCropComplete = useCallback(
    (_croppedArea: Area, completedCroppedAreaPixels: Area): void => {
      setCroppedAreaPixels(completedCroppedAreaPixels);
    },
    [],
  );

  const onSave = async (): Promise<void> => {
    await getCroppedImg(
      b64Image, croppedAreaPixels, rotation,
    )
      .then((result): void => {
        if (onSaveResult) onSaveResult(result);
        onCloseLayer();
      });
  };

  const onZoomChange = (evt: ChangeEvent<HTMLInputElement>): void => setZoom(parseFloat(evt.target.value));

  const onRotationChange = (evt: ChangeEvent<HTMLInputElement>): void => setRotation(parseInt(evt.target.value, 10));

  return (
    <Layer
      animation="fadeIn"
      full
    >
      <Box
        align="center"
        justify="start"
        pad="small"
        gap="small"
        direction="column"
        fill
      >
        <Box
          fill="horizontal"
          align="end"
        >
          <Button
            icon={<Close />}
            onClick={onCloseLayer}
          />
        </Box>
        <Box fill="horizontal">
          <PageTitle
            Text="Editar imagem"
          />
        </Box>
        <Box
          fill="horizontal"
          align="center"
          height="400px"
          style={{
            position: 'relative',
          }}
        >
          <Cropper
            image={b64Image}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={aspect}
            objectFit="contain"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Box
          fill="horizontal"
          align="center"
          direction="row"
          gap="medium"
        >
          <Box
            width="50%"
            align="center"
            direction="column"
          >
            <Text>Zoom</Text>
            <Box
              align="center"
              pad={{
                vertical: 'small',
              }}
              fill="horizontal"
            >
              <RangeInput
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={onZoomChange}
              />
            </Box>
          </Box>
          <Box
            width="50%"
            align="center"
            direction="column"
          >
            <Text>Rotação</Text>
            <Box
              align="center"
              pad={{
                vertical: 'small',
              }}
              fill="horizontal"
            >
              <RangeInput
                value={rotation}
                min={0}
                max={360}
                step={1}
                onChange={onRotationChange}
              />
            </Box>
          </Box>
        </Box>
        <Box
          fill="horizontal"
          justify="end"
          align="baseline"
          gap="medium"
          direction="row"
        >
          <Button
            primary
            label="Salvar"
            title="Salvar imagem"
            onClick={onSave}
          />
          <Button
            secondary
            label="Cancelar"
            title="Cancelar edição"
            onClick={onCloseLayer}
          />
        </Box>
      </Box>
    </Layer>
  );
};

ImageEditorLayer.displayName = 'ImageEditorLayer';

export default ImageEditorLayer;
