const convertToDate = (dateString: string): Date => {
  // Split the string by the hyphen
  let parts = dateString.split('-');
  // Rearrange parts to match the format required by the Date constructor (yyyy, mm, dd)
  let day = parseInt(parts[0], 10);
  // Month is zero-based in JavaScript Date
  let month = parseInt(parts[1], 10) - 1;
  let year = parseInt(parts[2], 10);
  // Create and return the Date object
  return new Date(year, month, day);
};

export default convertToDate;
