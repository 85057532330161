import React from 'react';
import { Box, Button } from 'grommet';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import callAPIError from './Helpers/callAPIError';
import { useErrorBoundary } from 'react-error-boundary';

export interface TestingErrorProps {}

const TestingError: React.FC<TestingErrorProps> = ({}): JSX.Element => {
  const { showBoundary } = useErrorBoundary();

  const onCallApiError = async (): Promise<void> => {
    await callAPIError()
      .then((data): void => {
        if (data.Success) {
          toast.success('API chamada com sucesso');
        } else {
          toast.error('Erro ao chamar a API');
        }
      })
      .catch((): void => {
        toast.error('Erro ao chamar a API');
      })
    ;
  };

  const onThrowError = (): void => {
    showBoundary(new Error('Erro Front End'));
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Testando Erros"
      />
      <Box
        width="large"
        gap="medium"
        direction="row"
        justify="center"
      >
        <Button
          primary
          label="Erro API"
          title="Testar erro da API"
          onClick={onCallApiError}
        />
        <Button
          primary
          label="Erro Front End"
          title="Testar erro do Front End"
          onClick={onThrowError}
        />
      </Box>
    </PageWrapper>
  );
};

TestingError.displayName = 'TestingError';

export default TestingError;
