import axios, {
  AxiosInstance, AxiosResponse, AxiosError,
} from 'axios';

const MyAxiosInstance = (): AxiosInstance => {
  const host: string = import.meta.env.VITE_API_URL;

  const myAxios = axios.create({
    baseURL: `${host}/api/`,
    withCredentials: true,
  });

  myAxios.interceptors.response.use((response): AxiosResponse => response,
    (error: AxiosError): void => {
      if (error.response!.status === 403) {
        window.location.href = '/403';
      }
    });

  return myAxios;
};

export default MyAxiosInstance;
