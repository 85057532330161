import React, { useContext, useEffect } from 'react';
import { Box, ResponsiveContext } from 'grommet';
import useAppContext from '../Context/useAppContext';
import SidebarNav from './Components/SideBarNav/SidebarNav';

export interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = (): JSX.Element => {
  const {
    state: { Drawer },
    // dispatch,
  } = useAppContext();
  const size = useContext(ResponsiveContext);
  const sidebarWidth = (size === 'small') ? '100%' : '254px';

  useEffect((): void => {
  }, [Drawer]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const onHandleDrawer = (): void => {
  //   dispatch({
  //     type: 'changeDrawer',
  //     value: !Drawer,
  //   });
  // };

  return (!Drawer) ? (<></>) : (
    <Box
      pad="none"
      width={sidebarWidth}
      animation={{
        type: 'slideRight',
        duration: 500,
      }}
    >
      <SidebarNav />
    </Box>
  );
};

Sidebar.displayName = 'Sidebar';

export default Sidebar;
