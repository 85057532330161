import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const onTwoFactorRouteAccess = async (): Promise<IBasicReturn> => {
  let twoFactorResponse: IBasicReturn = {
    Success: false,
  };

  await MyAxiosInstance().get<IBasicReturn>('/Identity/GetTwoFactorCode')
    .then((response): IBasicReturn => {
      const { data } = response;

      twoFactorResponse = {
        Success: data.Success,
      };

      return twoFactorResponse;
    })
    .catch((): IBasicReturn => {
      twoFactorResponse = {
        Success: false,
      };
      return twoFactorResponse;
    })
  ;

  return twoFactorResponse;
};

export default onTwoFactorRouteAccess;
