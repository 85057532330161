import { Box, Button, ResponsiveContext, TextInput } from 'grommet';
import { toast } from 'react-toastify';
import { IPagination } from '../../../../../Interfaces/IPagination';
import React, { useContext, useState } from 'react';
import { IEquipamento } from '../../../../../Interfaces/IEquipamento';
import pesquisarEquipamentos from '../../Helpers/pesquisarEquipamentos';
import useAppContext from '../../../Template/Context/useAppContext';

export interface PesquisarEquipamentosProps {
  refreshData: () => void;
  setResults: (results: IPagination<IEquipamento[]>) => void;
}

const PesquisarEquipamentos: React.FC<PesquisarEquipamentosProps> = ({
  refreshData,
  setResults,
}): JSX.Element => {
  const {
    state: { Admin },
  } = useAppContext();

  const size = useContext(ResponsiveContext);

  const [value, setValue] = useState<string>('');
  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  const [submitting, setSubmitting] = useState<boolean>(false);

  const onCancel = (): void => {
    setValue('');
    refreshData();
  };

  const onSearch = async (): Promise<void> => {
    if (value.length < 3) {
      toast.error('O termo de busca deve ter no mínimo 3 caracteres');
      return;
    }

    setSubmitting(true);
    await pesquisarEquipamentos(value, Admin)
      .then((data): void => {
        if (data.Success) {
          setResults(data.Object!);
        } else {
          toast.error('Erro ao buscar equipamentos');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar equipamentos');
        setSubmitting(false);
      });
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <Box
      direction={size === 'small' ? 'column' : 'row'}
      justify="center"
      align="center"
      pad={{
        bottom: 'small',
      }}
      gap="small"
      width="50%"
      alignSelf="end"
    >
      <TextInput
        placeholder="Digite o termo de busca"
        value={value}
        onChange={onChangeValue}
        onKeyUp={onKeyUp}
        disabled={submitting}
      />
      <Button
        primary
        label="Pesquisar"
        title="Pesquisar"
        onClick={onSearch}
        disabled={submitting}
      />
      <Button
        secondary
        label="Limpar"
        title="Limpar pesquisa"
        onClick={onCancel}
        disabled={submitting}
      />
    </Box>
  );
};

PesquisarEquipamentos.displayName = 'PesquisarEquipamentos';

export default PesquisarEquipamentos;
