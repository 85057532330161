import { Anchor, Box, Image, Paragraph, Stack, Text } from 'grommet';
import React, {
  ChangeEvent, useRef, useState, DragEvent,
} from 'react';
import { toast } from 'react-toastify';
import ImageEditorLayer from './Components/ImageEditorLayer';

export interface ImageUploaderProps {
  image: string;
  aspect?: number;
  onSaveResult?: (b64: string) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ image, aspect, onSaveResult }): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [imageBase64, setImageBase64] = useState<string>('');

  const input = useRef<HTMLInputElement>(null);

  const onDragClick = (): void => {
    if (input.current) input.current.click();
  };

  const validateFile = async (file: File): Promise<void> => {
    if (!file.type.startsWith('image/')) {
      setErrorMessage('Arquivo não é do tipo imagem');
      setShowErrorMessage(true);
      return;
    }

    if (file.size > 512000) {
      setErrorMessage('Arquivo muito grande');
      setShowErrorMessage(true);
      return;
    }

    const reader = new FileReader();

    reader.onload = (): void => {
      const { result } = reader;

      const [extension] = file.name.split('.').reverse();

      if (!extension) {
        setErrorMessage('Arquivo sem extensão');
        setShowErrorMessage(true);
        return;
      }

      // @ts-ignore: Object is possibly 'null'.
      setImageBase64(result.toString() as string);
      setShowEditor(true);
    };

    reader.onerror = (): void => {
      toast.error('Erro ao carregar imagem');
    };

    reader.readAsDataURL(file);
  };

  const dropHandler = (ev: DragEvent<HTMLDivElement>): void => {
    ev.preventDefault();

    const file = ev.dataTransfer.files[0];

    validateFile(file);
  };

  const onFileChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    ev.preventDefault();

    if (ev.target.files) validateFile(ev.target.files[0]);
  };

  const onCloseLayer = (): void => {
    setShowEditor(false);
    if (input.current) input.current.value = '';
  };

  const dragOverHandler = (ev: DragEvent<HTMLDivElement>): void => { ev.preventDefault(); };

  return (
    <>
      <Box
        margin="none"
        pad="none"
        direction="column"
        gap="small"
      >
        <Box
          gap="small"
          width="250px"
        >
          <Box
            pad="medium"
            border="all"
            onClick={onDragClick}
            title="Arraste o arquivo ou clique aqui"
            onDrop={dropHandler}
            onDragOver={dragOverHandler}
          >
            <Anchor>Arraste o arquivo ou clique aqui</Anchor>
            <Paragraph
              margin="none"
              textAlign="center"
              size="11px"
              fill
            >
              Tamanho máxímo: 500k
            </Paragraph>
            <input
              type="file"
              name="inputImage"
              ref={input}
              style={{ display: 'none' }}
              accept="image/*"
              multiple={false}
              onChange={onFileChange}
            />
          </Box>
          {
            (showErrorMessage) && (
              <Box background="status-critical">
                <Paragraph
                  textAlign="center"
                  fill
                >
                  {errorMessage}
                </Paragraph>
              </Box>
            )
          }
        </Box>
        <Box
          justify="center"
          align="center"
          border="all"
          flex
        >
          <Stack anchor="top-right" fill>
            <Box
              fill="horizontal"
              align="center"
              justify="center"
              pad="none"
              margin="none"
              gap="small"
            >
              <Image
                alt="Uploaded image"
                src={image}
                style={{
                  maxHeight: 300,
                }}
              />
            </Box>
            <Box
              background="brand"
              pad="xxsmall"
            >
              <Text size="xsmall">
                Preview
              </Text>
            </Box>
          </Stack>
        </Box>
      </Box>
      {
        (showEditor) && (
          <ImageEditorLayer
            onCloseLayer={onCloseLayer}
            b64Image={imageBase64}
            onSaveResult={onSaveResult}
            aspect={aspect}
          />
        )
      }
    </>
  );
};

ImageUploader.displayName = 'ImageUploader';

export default ImageUploader;
