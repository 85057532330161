import { BasicReturn, IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import { IUsuarioDocumento } from '../../../../Interfaces/IUsuarioDocumento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getUsuariosDocumentosInfoByGuid = async (usuarioDocumentoGuid: string): Promise<IBasicReturn<IUsuarioDocumento>> => {
  let basicReturn: IBasicReturn<IEmpresaDocumento> = new BasicReturn(true);

  await MyAxiosInstance().get<IBasicReturn<IUsuarioDocumento>>(`Identity/UsuariosDocumentosInfoByGuid/${usuarioDocumentoGuid}`)
    .then((response): IBasicReturn<IUsuarioDocumento> => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = new BasicReturn(false);

      return basicReturn;
    });

  return basicReturn;
};

export default getUsuariosDocumentosInfoByGuid;
