import {
  Box, Button, FormExtendedEvent, FormField, MaskedInput, ResponsiveContext, TextInput,
} from 'grommet';
import React, { useContext } from 'react';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import FormWrapper from '../../FormWrapper/FormWrapper';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';

export interface EmpresaFormProps {
  initialValues: IEmpresa;
  onSubmit: (values: IEmpresa) => void;
  onCancel?: () => void;
  editing?: boolean;
}

const EmpresaForm: React.FC<EmpresaFormProps> = ({ initialValues, onSubmit, onCancel, editing = false }): JSX.Element => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const [values, setValues] = React.useState<IEmpresa>(initialValues);
  const onChange = (nextValues: IEmpresa): void => setValues(nextValues);

  const activeChange = (value: boolean): void => {
    setValues({ ...values, Ativo: value });
  };

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IEmpresa>): Promise<void> => {
    setSubmitting(true);
    onSubmit(evt.value);
  };

  const size = useContext(ResponsiveContext);

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <Box
        direction={ (size === 'small') ? 'column' : 'row' }
        gap="small"
        justify="end"
      >
        <FormField
          label="CNPJ"
          name="CNPJ"
          width="100%"
          required
        >
          <MaskedInput
            name="CNPJ"
            aria-label="Por favor informe o CNPJ"
            title="Por favor informe o CNPJ"
            mask={[
              {
                length: 2,
                regexp: /^[0-9]{1,2}$/,
                placeholder: '##',
              },
              { fixed: '.' },
              {
                length: 3,
                regexp: /^[0-9]{1,3}$/,
                placeholder: '###',
              },
              { fixed: '.' },
              {
                length: 3,
                regexp: /^[0-9]{1,3}$/,
                placeholder: '###',
              },
              { fixed: '/' },
              {
                length: 4,
                regexp: /^[0-9]{1,4}$/,
                placeholder: '####',
              },
              { fixed: '-' },
              {
                length: 2,
                regexp: /^[0-9]{1,2}$/,
                placeholder: '##',
              },
            ]}
          />
        </FormField>

        <FormField
          label="Razão Social"
          name="RazaoSocial"
          width="100%"
          required
        >
          <TextInput
            name="RazaoSocial"
            aria-label="Por favor informe a Razão Social"
            title="Por favor informe a Razão Social"
          />
        </FormField>
      </Box>

      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="end"
      >
        <FormField
          label="Nome Fantasia"
          name="NomeFantasia"
          width="100%"
          required
        >
          <TextInput
            name="NomeFantasia"
            aria-label="Por favor informe o Nome Fantasia"
            title="Por favor informe o Nome Fantasia"
          />
        </FormField>

        <FormField
          label="Endereço"
          name="Endereco"
          width="100%"
        >
          <TextInput
            name="Endereco"
            aria-label="Por favor informe o Endereço"
            title="Por favor informe o Endereço"
          />
        </FormField>
      </Box>

      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="end"
      >
        <FormField
          label="Responsável Técnico"
          name="ResponsavelTecnico"
          width="100%"
        >
          <TextInput
            name="ResponsavelTecnico"
            aria-label="Por favor informe o Nome"
            title="Por favor informe o Nome"
          />
        </FormField>

        <FormField
          label="Telefone"
          name="Telefone"
          width="100%"
        >
          <TextInput
            name="Telefone"
            aria-label="Por favor informe o Telefone"
            title="Por favor informe o Telefone"
          />
        </FormField>
      </Box>

      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="start"
      >
        <FormField
          label="Email"
          name="Email"
          width="100%"
        >
          <TextInput
            name="Email"
            aria-label="Por favor informe o Email"
            title="Por favor informe o Email"
          />
        </FormField>

        {
          (!editing) && (
            <InterdevSwitch
              activeLabel="Ativo"
              inactiveLabel="Inativo"
              value={values.Ativo || false}
              onChange={activeChange}
              name="Active"
              title="Alterar o Status da empresa"
              text="Status da empresa"
            />
          )
        }
      </Box>

      <Box
        direction="row"
        gap="medium"
        justify="start"
        margin={{
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <Button
          type="submit"
          label="Salvar"
          title="Salvar"
          disabled={submitting}
          primary
          reverse
        />
        <Button
          label="Cancelar"
          title="Cancelar"
          onClick={onClear}
          reverse
        />
      </Box>
    </FormWrapper>
  );
};

EmpresaForm.displayName = 'EmpresaForm';

export default EmpresaForm;
