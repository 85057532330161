import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const adicionarPlantaSubmit = async (values: IPlanta): Promise<IBasicReturn> => {
  let apiResponse: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().post<IBasicReturn>('Empresa/AdicionarPlanta', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default adicionarPlantaSubmit;
