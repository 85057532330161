import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAppContext from '../../Template/Context/useAppContext';
import onTwoFactorRouteAccess from '../Helpers/onTwoFactorRouteAccess';
import PageWrapper from '../../PageWrapper/PageWrapper';
import Loading from '../../Loading/Loading';
import twoFactorCodeInitialValues from '../Helpers/twoFactorCodeInitialValues';
import TwoFactorValidationPage from '../../TwoFactorValidation/TwoFactorValidationPage';

export interface TwoFactorAuthProps {}

const TwoFactorAuth: React.FC<TwoFactorAuthProps> = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    state: { IsAuthenticated },
    dispatch,
  } = useAppContext();

  const onError = (): void => {
    if (IsAuthenticated) {
      dispatch({
        type: 'changeAuth',
        value: false,
      });

      dispatch({
        type: 'changeOwner',
        value: false,
      });
    }
    toast.error('Desculpe, mas não foi possível iniciar a validação em dois fatores');
    navigate(`/login?RedirectUrl=${location.pathname}`);
  };

  const [loading, setLoading] = React.useState<boolean>(true);
  const [validated, setValidated] = React.useState<boolean>(false);
  const changeValidated = (): void => setValidated(true);

  const startValidateWithTwoFactor = async (): Promise<void> => {
    await onTwoFactorRouteAccess()
      .then((data): void => {
        if (data.Success) {
          toast.warning('Por favor informe o código recebido por e-mail');
          setLoading(false);
        } else {
          onError();
        }
      })
      .catch((): void => {
        onError();
      })
    ;
  };

  useEffect((): void => {
    if (!IsAuthenticated) {
      navigate(`/login?RedirectUrl=${location.pathname}`);
      return;
    }

    startValidateWithTwoFactor();
  }, []);

  return (loading) ? (
    <PageWrapper>
      <Loading />
    </PageWrapper>
  ) : (
    <>
      {
        (validated) ? (
          <Outlet />
        ) : (
          <TwoFactorValidationPage
            initialValues={twoFactorCodeInitialValues}
            setValidated={changeValidated}
            onError={onError}
          />
        )
      }
    </>
  );
};

TwoFactorAuth.displayName = 'TwoFactorAuth';

export default TwoFactorAuth;
