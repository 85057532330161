import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import { IPagination } from '../../../../Interfaces/IPagination';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getEquipamentos = async (page: IPagination): Promise<IBasicReturn<IPagination<IEquipamento[]>>> => {
  let apiReturn: IBasicReturn<IPagination<IEquipamento[]>> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPagination<IEquipamento[]>>>(`Empresa/Equipamentos/${page.Page}/${page.PageSize}`)
    .then((response): IBasicReturn<IPagination<IEquipamento[]>> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn<IPagination<IEquipamento[]>> => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default getEquipamentos;
