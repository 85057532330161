import { Box, Text } from 'grommet';
import styled from 'styled-components';

export const ErrorDescription = styled(Text)`
  font-size: 14px;
  font-weight: 400;
`;

export const ErrorTitle = styled(Text)`
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
`;

export const ErrorTag = styled(Text)`
  font-size: 106px;
  font-weight: 600;
  margin-bottom: 40px;

  @media only screen and (max-width: 768px) {
    font-size: 90px;
  }
`;

export const ErrorPageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 16px;
`;