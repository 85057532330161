import {
  Box, Button, Layer, Text,
} from 'grommet';
import { FormClose } from 'grommet-icons';
import React from 'react';
import { IUser } from '../../../../Interfaces/IUser';
import ImportCsvDataTable from './ImportCsvDataTable';

export interface ImportCsvLayerProps {
  users: IUser[];
  onClose: () => void;
}

const ImportCsvLayer: React.FC<ImportCsvLayerProps> = ({ onClose, users }): JSX.Element => {
  return (
    <Layer full>
      <Box fill>
        <Box
          direction="row"
          align="center"
          as="header"
          elevation="small"
          justify="between"
        >
          <Text margin={{ left: 'small' }}>Conferência de usuários</Text>
          <Button icon={<FormClose />} onClick={onClose} />
        </Box>
        <Box flex overflow="auto" pad="xsmall">
          <Box>
            <ImportCsvDataTable users={users} />
          </Box>
        </Box>
        <Box
          as="footer"
          border={{ side: 'top' }}
          pad="small"
          justify="end"
          direction="row"
          align="center"
          gap="medium"
        >
          <Button label="Cancel" onClick={onClose} />
          <Button primary label="Save" onClick={onClose} />
        </Box>
      </Box>
    </Layer>
  );
};

ImportCsvLayer.displayName = 'ImportCsvLayer';

export default ImportCsvLayer;
