import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const deleteEquipamentos = async (Guid: string): Promise<IBasicReturn> => {
  let apiResponse: IBasicReturn<IEquipamento> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn>(`Empresa/DeleteEquipamentos/${Guid}`)
    .then((response): IBasicReturn => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default deleteEquipamentos;
