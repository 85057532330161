import React from 'react';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import { useNavigate } from 'react-router-dom';
import { Anchor } from 'grommet';

export interface ManageEmpresasTotalDocumentosProps {
  empresa: IEmpresa;
}

const ManageEmpresasTotalDocumentos: React.FC<ManageEmpresasTotalDocumentosProps> = ({ empresa }): JSX.Element => {
  const navigate = useNavigate();

  const onDocumentosClick = (): void => {
    navigate(`/owner/manageEmpresaDocumentos/${empresa.Guid}`);
  };

  return (
    <Anchor
      title="Clique para ver os documentos da empresa"
      onClick={onDocumentosClick}
    >
      {empresa.TotalDocumentos || 0}
    </Anchor>
  );
};

ManageEmpresasTotalDocumentos.displayName = 'ManageEmpresasTotalDocumentos';

export default ManageEmpresasTotalDocumentos;
