import React from 'react';
import { Box } from 'grommet';
import { toast } from 'react-toastify';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import ImageUploader from '../../ImageUploader/ImageUploader';
import onChangeSignatureSubmit from './Helpers/onChangeSignatureSubmit';
import { useNavigate } from 'react-router-dom';
import { IUpload } from '../../../../Interfaces/IUpload';
import useAppContext from '../../Template/Context/useAppContext';

export interface ChangeSignatureProps {}

const ChangeSignature: React.FC<ChangeSignatureProps> = ({}): JSX.Element => {
  const {
    state: { Guid },
  } = useAppContext();

  const navigate = useNavigate();

  const onSaveImage = async (b64: string): Promise<void> => {
    const upload: IUpload = {
      UploadBase64: b64,
      UploadExtension: 'png',
      UploadMimeType: 'image/png',
    };

    await onChangeSignatureSubmit(upload)
      .then((data): void => {
        if (data.Success) {
          toast.success('Assinatura alterada com sucesso');
          navigate('/dashboard');
        } else {
          toast.error('Erro ao alterar a assinatura');
        }
      })
      .catch((): void => {
        toast.error('Erro ao alterar a assinatura');
      });
  };

  return (
    <PageWrapper>
      <PageTitle Text="Alterar Assinatura" />
      <Box direction="column" gap="small">
        <ImageUploader
          image={`${import.meta.env.VITE_API_URL}/api/Upload/Assinatura/${Guid}.png`}
          onSaveResult={onSaveImage}
          aspect={600 / 338}
        />
      </Box>
    </PageWrapper>
  );
};

ChangeSignature.displayName = 'ChangeSignature';

export default ChangeSignature;
