import React, { useState } from 'react';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import { ColumnConfig, DataTable, Text } from 'grommet';
import ManagePlantasActions from './ManagePlantasActions';

export interface ManagePlantasTableProps {
  plantas: IPlanta[];
  refreshData: () => void;
}

const ManagePlantasTable: React.FC<ManagePlantasTableProps> = ({ plantas, refreshData }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Nome',
    direction: 'asc',
  });

  const columns: ColumnConfig<IPlanta>[] = [
    {
      property: 'Nome',
      primary: true,
      search: true,
      sortable: true,
      header: 'Nome',
      render: (datum: IPlanta): React.ReactNode => (
        <Text>{datum.Nome}</Text>
      ),
    },
    {
      property: 'Observacao',
      primary: false,
      search: false,
      sortable: false,
      header: 'Observação',
      render: (datum: IPlanta): React.ReactNode => (
        <Text>{datum.Observacao}</Text>
      ),
    },
    {
      property: 'Ativo',
      primary: false,
      search: false,
      sortable: true,
      header: 'Ativo?',
      render: (datum: IPlanta): React.ReactNode => (
        <Text>{(datum.Ativo) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Guid',
      primary: false,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IPlanta): React.ReactNode => (
        <ManagePlantasActions
          planta={datum}
          refreshData={refreshData}
        />
      ),
    },
  ];
  return (
    <DataTable
      fill="horizontal"
      data={plantas}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ManagePlantasTable.displayName = 'ManagePlantasTable';

export default ManagePlantasTable;
