import { IProjetoDocumento } from '../../../../Interfaces/IProjetoDocumento';

const adicionarDocumentoProjetoInitialValues = (projetoGuid: string): IProjetoDocumento => ({
  Titulo: '',
  Observacao: '',
  UploadNome: '',
  Projeto: {
    Guid: projetoGuid,
  },
});

export default adicionarDocumentoProjetoInitialValues;
