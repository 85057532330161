import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getProjetoEquipamentos = async (projetoGuid: string): Promise<IBasicReturn<IEquipamento[]>> => {
  let apiResponse: IBasicReturn<IEquipamento[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IEquipamento[]>>(`Empresa/ProjetoEquipamentos/${projetoGuid}`)
    .then((response): IBasicReturn<IEquipamento[]> => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn<IEquipamento[]> => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default getProjetoEquipamentos;
