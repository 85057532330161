import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const insertUserSubmit = async (values: IUser): Promise<IBasicReturn> => {
  let basicReturn: IBasicReturn<IUser> = { Success: false };

  await MyAxiosInstance().post<IBasicReturn<IUser>>('Identity/InsertUser', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = { Success: false };

      return basicReturn;
    });

  return basicReturn;
};

export default insertUserSubmit;
