import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IPagination, Pagination as PaginationClass } from '../../../Interfaces/IPagination';
import { IEmpresaDocumento } from '../../../Interfaces/IEmpresaDocumento';
import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';
import getDocumentos from './Helpers/getDocumentos';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import totalPages from '../Pagination/Helpers/totalPages';
import PageSize from '../Pagination/Components/PageSize';
import ManageEmpresasDocumentosTable from './Components/ManageEmpresasDocumentosTable';
import PesquisarDocumentos from './Components/PesquisarDocumento/PesquisarDocumentos';

export interface ManageEmpresasDocumentosProps {}

const ManageEmpresasDocumentos: React.FC<ManageEmpresasDocumentosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onAddDocumentoClick = (): void => {
    navigate(`/owner/adicionarDocumento/${guid}`);
  };

  const [documentos, setDocumentos] = useState<IPagination<IEmpresaDocumento[]>>(new PaginationClass(1, 30, 30, []));
  const [loading, setLoading] = useState<boolean>(true);

  const setData = (data: IPagination<IEmpresaDocumento[]>): void => {
    setDocumentos(data);
  };

  const initialPagination: IPagination<IEmpresaDocumento[]> = new PaginationClass(1, 30, 30);

  const getData = async (pagination: IPagination = initialPagination): Promise<void> => {
    setLoading(true);

    await getDocumentos(guid!, pagination)
      .then((data): void => {
        if (data.Success) {
          setDocumentos(data.Object!);
        } else {
          toast.error('Erro ao buscar documentos da empresa');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar documentos da empresas');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(parseInt(newPage, 10), documentos.PageSize);
    getData(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getData(newPagination);
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Documentos"
      />
      <Box
        width="xlarge"
        align="end"
      >
        <Button
          primary
          label="Adicionar Documento"
          title="Adicionar Documento"
          onClick={onAddDocumentoClick}
          icon={<Add />}
          reverse
        />
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
            }}
          >
            <PesquisarDocumentos
              refreshData={getData}
              setResults={setData}
              empresaGuid={guid!}
            />
            <Pagination
              page={documentos.Page!}
              last={totalPages(documentos.Total || 0, documentos.PageSize!)}
              onChange={updatePage}
            />
            <ManageEmpresasDocumentosTable
              documentos={documentos.Object || []}
              refreshData={getData}
            />
            <PageSize
              size={documentos.PageSize!}
              onChange={updatePageSize}
            />
            <Pagination
              page={documentos.Page!}
              last={totalPages(documentos.Total || 0, documentos.PageSize!)}
              onChange={updatePage}
            />
          </Box>
        )
      }
    </PageWrapper>
  );
};

ManageEmpresasDocumentos.displayName = 'ManageEmpresasDocumentos';

export default ManageEmpresasDocumentos;
