import { IBasicReturn } from '../../../../../Interfaces/IBasicReturn';
import { IEmpresa } from '../../../../../Interfaces/IEmpresa';
import MyAxiosInstance from '../../../../../utils/AxiosInstance';

const getMinhaEmpresa = async (): Promise<IBasicReturn<IEmpresa>> => {
  let apiResponse: IBasicReturn<IEmpresa> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IEmpresa>>('Empresa/MinhaEmpresa')
    .then((response): IBasicReturn<IEmpresa> => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn<IEmpresa> => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default getMinhaEmpresa;
