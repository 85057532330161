import { Grommet } from 'grommet';
import styled from 'styled-components';

const GrommetWithStyles = styled(Grommet)`
  display: flex;
  min-height: 100vh;
  width: 100%;
`;

export default GrommetWithStyles;
