import { TagButtonStatus } from '../Styles/ProjetoCardStyles';

export interface ChangeTabButtonStatusProps {
  type?: number | undefined ;
}

export const ChangeTabButtonStatus = (props: ChangeTabButtonStatusProps): JSX.Element => {
  const { type } = props;
  switch (type) {
    case 1:
      return (
        <TagButtonStatus
            label='Não iniciado'
            onClick={() => {}}
            style={{
              backgroundColor: '#F3F3F3',
              color: '#6A6A6A',
              width: 'auto',
            }}
          />
      );
    case 2:
      return (
        <TagButtonStatus
            label='Em andamento'
            onClick={() => {}}
            style={{
              backgroundColor: '#C1F9FF',
              color: '#004271',
              width: 'auto',
            }}
          />
      );
    case 3:
      return (
        <TagButtonStatus
            label='Finalizado'
            onClick={() => {}}
            style={{
              backgroundColor: '#C1FFD1',
              color: '#00711C',
              width: 'auto',
            }}
          />
      );
    default:
      return (
        <TagButtonStatus
        label='Não iniciado'
        onClick={() => {}}
        style={{
          backgroundColor: '#F3F3F3',
          color: '#6A6A6A',
          width: 'auto',
        }}
      />
      );
  }
};