import ReactDOM from 'react-dom/client';
import { I18nextProvider, useSSR } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import AppContextProvider from './components/Template/Context/AppContextProvider';
import MainRouter from './components/Router/MainRouter';
import GrommetWrapper from './components/Template/GrommetWrapper/GrommetWrapper';
import { ICustomWindowWithData } from '../Interfaces/ICustomWindow';
import AppErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import i18n from './i18n';

declare const window: ICustomWindowWithData;

// eslint-disable-next-line react-refresh/only-export-components
const App: React.FC = () => {
  // @ts-expect-error this is a type error from the react-i18next library
  useSSR(window.__INITIAL_DATA__.i18nextInstance.store, window.__INITIAL_DATA__.context.Language || 'pt');
  return (
    <AppContextProvider context={window.__INITIAL_DATA__.context}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <GrommetWrapper>
            <AppErrorBoundary>
              <MainRouter />
            </AppErrorBoundary>
          </GrommetWrapper>
        </BrowserRouter>
      </I18nextProvider>
    </AppContextProvider>
  );
};

ReactDOM.hydrateRoot(document.getElementById('root')!, <App />);
