import { Anchor, Box, Text } from 'grommet';
import React from 'react';
import StyledSwitchLabel from './Styles/InterdevSwitchStyles';

export interface InterdevSwitchProps {
  activeLabel: string;
  inactiveLabel: string;
  value: boolean;
  name: string;
  title: string;
  text: string | JSX.Element;
  onChange: (value: boolean) => void;
  direction?: 'row' | 'column';
  width?: string;
}

const InterdevSwitch: React.FC<InterdevSwitchProps> = ({
  activeLabel, inactiveLabel, value, name, onChange, title, text, direction = 'column', width = '100%',
}): JSX.Element => {
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(evt.target.checked);
  };

  const handleLabelClick = (): void => {
    onChange(!value);
  };

  return (
    <Box
      gap="small"
      pad="small"
      direction={direction}
      align="start"
      width={width}
      justify="center"
      title={title}
    >
      <Text>{text}</Text>
      <Box
        direction="row"
        gap="small"
        alignContent="center"
      >
        <Box>
          <StyledSwitchLabel>
            <input type="checkbox" checked={value} name={name} onChange={handleChange} />
            <span className="slider" />
          </StyledSwitchLabel>
        </Box>
        <Box>
          <Anchor onClick={handleLabelClick} color="text">{(value) ? activeLabel : inactiveLabel}</Anchor>
        </Box>
      </Box>
    </Box>
  );
};

InterdevSwitch.displayName = 'InterdevSwitch';

export default InterdevSwitch;
