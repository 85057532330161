import {
  Box, Button, FormExtendedEvent, FormField, MaskedInput,
} from 'grommet';
import React from 'react';
import { IUser } from '../../../../Interfaces/IUser';
import FormWrapper from '../../FormWrapper/FormWrapper';
import onTwoFactorCodeValidate from '../../Router/Helpers/onTwoFactorCodeValidate';
import { toast } from 'react-toastify';

export interface TwoFactorCodeValidationProps {
  initialValues: IUser;
  setValidated: () => void;
  onError: () => void;
}

const TwoFactorCodeValidation: React.FC<TwoFactorCodeValidationProps> = ({ initialValues, setValidated, onError }): JSX.Element => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<IUser>(initialValues);
  const onChange = (nextValues: IUser): void => setValues(nextValues);

  const handleSubmit = async (evt: FormExtendedEvent<IUser>): Promise<void> => {
    const { value } = evt;
    setSubmitting(true);

    await onTwoFactorCodeValidate(value)
      .then((data): void => {
        if (data.Success) {
          setValidated();
        } else {
          toast.error('Código inválido');
          setSubmitting(false);
        }
      })
      .catch((): void => {
        onError();
      });
  };

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <FormField
        label="Código de verificação"
        name="VerificationCode"
        width="100%"
        required
      >
        <MaskedInput
          mask={[
            {
              regexp: /^([0-9]{1,3})$/,
              placeholder: '000',
              length: 3,
            },
            { fixed: '-' },
            {
              regexp: /^([0-9]{1,3})$/,
              length: 3,
              placeholder: '000',
            },
          ]}
          name="VerificationCode"
        />
      </FormField>

      <Box
        direction="row"
        gap="small"
        justify="end"
        margin={{
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <Button
          type="submit"
          label="Validar"
          title="Validar código"
          disabled={submitting}
          primary
          reverse
        />
      </Box>
    </FormWrapper>
  );
};

TwoFactorCodeValidation.displayName = 'TwoFactorCodeValidation';

export default TwoFactorCodeValidation;
