import { Box, Button, Text } from 'grommet';
import styled from 'styled-components';

export const ArrowIconButton = styled(Button)`
  font-size: 24px; /* Tamanho do ícone */
  color: #ffffff; /* Cor do ícone */
  transition: transform 0.3s ease; /* Transição suave para rotação */
  cursor: pointer; /* Deixa o cursor em forma de pointer */
  text-align: center; /* Centraliza o conteúdo dentro */
`;

export const ProfileCardButtonsBox = styled(Box)`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
  opacity: 0; // Torna o elemento invisível, mas ainda presente
  visibility: hidden; // Também escondemos o conteúdo
  transition: opacity 0.5s ease, visibility 0s 0.5s; // Aumenta o tempo da transição
`;

export const ProfileCardRole = styled(Text)`
  color: #89B1C9;
  font-size: 14px;
  transition: color 0.5s ease; // Transição suave na cor, com tempo maior
`;

const ProfileCardBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-self: end;
  width: 100%;
  padding: 20px;
  background-color: #122136;
  color: #ffffff;
  transition: background-color 0.5s ease; // Transição mais lenta para o fundo

  :hover {
    background-color: #5d30a4;
    
    ${ProfileCardButtonsBox} {
      display: flex; // Mostra o conteúdo quando hover
      opacity: 1; // Torna visível
      visibility: visible; // Torna o elemento visível
      transition: opacity 0.5s ease, visibility 0s 0s; // Transição mais lenta
    }
    
    ${ProfileCardRole} {
      color: #D3A1F7;
    }  

    ${ArrowIconButton} {
      transform: rotate(180deg);
    }
  }

  :active {
    background-color: #2d126d;

    ${ProfileCardRole} {
      color: #D3A1F7;
    }    
  }
`;

export default ProfileCardBox;
