import React, { useEffect, useState } from 'react';
import { Box, Button, Paragraph } from 'grommet';
import { Add } from 'grommet-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IEquipamentoDocumento } from '../../../Interfaces/IEquipamentoDocumento';
import { IPagination, Pagination as PaginationClass } from '../../../Interfaces/IPagination';
import getDocumentosDoEquipamento from './Helpers/getDocumentosDoEquipamento';
import Loading from '../Loading/Loading';
import totalPages from '../Pagination/Helpers/totalPages';
import PageSize from '../Pagination/Components/PageSize';
import Pagination from '../Pagination/Pagination';
import useAppContext from '../Template/Context/useAppContext';
import EquipamentosDocumentosTable from './Components/EquipamentosDocumentosTable';

export interface ManageEquipamentosDocumentosProps {}

const ManageEquipamentosDocumentos: React.FC<ManageEquipamentosDocumentosProps> = ({ }): JSX.Element => {
  const {
    state: { Admin },
  } = useAppContext();

  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onAddDocumentoClick = (): void => {
    if (Admin) {
      navigate(`/admin/adicionarDocumentoEquipamento/${guid}`);
    } else {
      navigate(`/owner/adicionarDocumentoEquipamento/${guid}`);
    }
  };

  const [documentos, setDocumentos] = useState<IPagination<IEquipamentoDocumento[]>>(new PaginationClass(1, 30, 30, []));
  const [loading, setLoading] = useState<boolean>(true);

  // const setData = (data: IPagination<IEquipamentoDocumento[]>): void => {
  //   setDocumentos(data);
  // };

  const initialPagination: IPagination<IEquipamentoDocumento[]> = new PaginationClass(1, 30, 30);

  const getData = async (pagination: IPagination = initialPagination): Promise<void> => {
    setLoading(true);

    await getDocumentosDoEquipamento(guid!, pagination)
      .then((data): void => {
        if (data.Success) {
          setDocumentos(data.Object!);
        } else {
          toast.error('Erro ao buscar documentos da empresa');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar documentos da empresas');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(parseInt(newPage, 10), documentos.PageSize);
    getData(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getData(newPagination);
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Documentos do equipamento" />
        <Button
          primary
          label="Adicionar"
          title="Adicionar Documento"
          onClick={onAddDocumentoClick}
          icon={<Add size="16px" />}
        />
      </Box>
      <Box
        alignSelf='start'
        align="start"
        width="60%"
      >

      <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae ante
        semper, faucibus urna quis, bibendum urna. Suspendisse consequat luctus
        dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam facilisis
        hendrerit quis non orci. Duis vel sodales dui. Suspendisse potenti.
        Donec dignissim est aliquet risus fermentum consectetur. 
      </Paragraph>
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
            }}
          >
            {/* <PesquisarDocumentos
              refreshData={getData}
              setResults={setData}
              empresaGuid={guid!}
            /> */}
            <EquipamentosDocumentosTable
              documentos={documentos.Object || []}
              refreshData={getData}
            />
            <PageSize
              size={documentos.PageSize!}
              onChange={updatePageSize}
            />
            <Pagination
              page={documentos.Page!}
              last={totalPages(documentos.Total || 0, documentos.PageSize!)}
              onChange={updatePage}
            />
          </Box>
        )
      }
    </PageWrapper>
  );
};

ManageEquipamentosDocumentos.displayName = 'ManageEquipamentosDocumentos';

export default ManageEquipamentosDocumentos;
