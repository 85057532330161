import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const onChangeActivePropertyForDocumentos = async (empresaDocumentoGuid: string): Promise<IBasicReturn> => {
  let documentos: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn>(`Empresa/ChangeActiveProperty/${empresaDocumentoGuid}`)
    .then((response): IBasicReturn => {
      const { data } = response;

      documentos = data;

      return documentos;
    })
    .catch((): IBasicReturn => {
      documentos.Success = false;

      return documentos;
    });

  return documentos;
};

export default onChangeActivePropertyForDocumentos;
