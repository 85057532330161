import { Heading, HeadingProps } from 'grommet';

interface IPageTitle extends HeadingProps {
  Text: string | JSX.Element;
}

const PageTitle = (props: IPageTitle): JSX.Element => {
  const {
    Text, level = 3, textAlign = 'center', ...styles
  } = props;

  return (
    <Heading
      level={level}
      textAlign={textAlign}
      {...styles}
    >
      {Text}
    </Heading>
  );
};

PageTitle.displayName = 'PageTitle';

export default PageTitle;
