import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IError } from '../../../../Interfaces/IError';
import MyAxiosInstance from '../../../../utils/AxiosInstance';


const saveError = async (values: IError): Promise<IBasicReturn> => {
  let savedError: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().post<IBasicReturn>('Error/Add', values)
    .then((response): IBasicReturn => {
      const { data } = response;
      savedError = data;
      return savedError;
    })
    .catch((): IBasicReturn => {
      savedError.Success = false;
      return savedError;
    });

  return savedError;
};

export default saveError;
