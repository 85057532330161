import { BasicReturn, IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getDocumentoInfoByGuid = async (empresaDocumentoGuid: string): Promise<IBasicReturn<IEmpresaDocumento>> => {
  let basicReturn: IBasicReturn<IEmpresaDocumento> = new BasicReturn(true);

  await MyAxiosInstance().get<IBasicReturn<IEmpresaDocumento>>(`Empresa/DocumentoInfoByGuid/${empresaDocumentoGuid}`)
    .then((response): IBasicReturn<IEmpresaDocumento> => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = new BasicReturn(false);

      return basicReturn;
    });

  return basicReturn;
};

export default getDocumentoInfoByGuid;
