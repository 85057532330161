import { BasicReturn, IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const onCheckingIsOwner = async (): Promise<IBasicReturn<boolean>> => {
  let basicReturn: IBasicReturn<boolean> = new BasicReturn(true);

  await MyAxiosInstance().get<IBasicReturn<boolean>>('Identity/IsOwner')
    .then((response): IBasicReturn<boolean> => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = new BasicReturn(false);

      return basicReturn;
    });

  return basicReturn;
};

export default onCheckingIsOwner;
