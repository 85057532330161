import { Box, NameValueList, NameValuePair, Text } from 'grommet';
import React from 'react';
import { IUpload } from '../../../Interfaces/IUpload';

export interface UploadInfoProps {
  upload: IUpload;
}

const UploadInfo: React.FC<UploadInfoProps> = ({ upload }): JSX.Element => {
  return (
    <Box width="medium">
      <NameValueList>
        <NameValuePair name="Nome">
          <Text>{upload.UploadName}</Text>
        </NameValuePair>
        <NameValuePair name="Mime Type">
          <Text>{upload.UploadMimeType}</Text>
        </NameValuePair>
        <NameValuePair name="Size">
          <Text>{upload.UploadSize} bytes</Text>
        </NameValuePair>
      </NameValueList>
    </Box>
  );
};

UploadInfo.displayName = 'UploadInfo';

export default UploadInfo;
