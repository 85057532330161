import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { IStreamingVideo } from '../../../../../Interfaces/IStreamingVideo';
import editVideoDoProjetoGetInitialValues from '../../Helpers/editVideoDoProjetoGetInitialValues';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { Box } from 'grommet';
import Loading from '../../../Loading/Loading';
import AddVideoForm from '../../../Forms/AddVideo/AddVideoForm';
import editVideoDoProjetoSubmit from '../../Helpers/editVideoDoProjetoSubmit';

export interface EditarVideoDoProjetoProps {}

const EditarVideoDoProjeto: React.FC<EditarVideoDoProjetoProps> = ({}): JSX.Element => {
  const { videoGuid, projetoGuid } = useParams<{ videoGuid: string, projetoGuid: string }>();

  const navigate = useNavigate();
  const onCancel = (): void => navigate(`/admin/manageProjetosVideos/${projetoGuid}`);

  const [loading, setLoading] = useState<boolean>(true);
  const [values, setValues] = useState<IStreamingVideo>({});

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await editVideoDoProjetoGetInitialValues(videoGuid!)
      .then((data): void => {
        if (data.Success) {
          setValues({
            ...data.Object!,
            Guid: videoGuid,
          });
          setLoading(false);
        } else {
          toast.error('Erro ao buscar informações do vídeo');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar informações do vídeo');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Editar Vídeo"
      />
      <Box
        width="large"
      >
        {
          (loading) ? (<Loading />) : (
            <AddVideoForm
              initialValues={values}
              onSubmit={editVideoDoProjetoSubmit}
              onCancel={onCancel}
              editing
            />
          )
        }
      </Box>
    </PageWrapper>
  );
};

EditarVideoDoProjeto.displayName = 'EditarVideoDoProjeto';

export default EditarVideoDoProjeto;
