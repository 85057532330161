import React, { useEffect, useState } from 'react';
import { Box } from 'grommet';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import AddVideoForm from '../../../Forms/AddVideo/AddVideoForm';
import editVideoSubmit from '../../Helpers/editVideoSubmit';
import { IStreamingVideo } from '../../../../../Interfaces/IStreamingVideo';
import editVideoGetInitialValues from '../../Helpers/editVideoGetInitialValues';
import Loading from '../../../Loading/Loading';

export interface VideoEditProps { }

const VideoEdit: React.FC<VideoEditProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();

  const navigate = useNavigate();
  const onCancel = (): void => navigate('/owner/manageVideos');

  const [loading, setLoading] = useState<boolean>(true);
  const [values, setValues] = useState<IStreamingVideo>({});

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await editVideoGetInitialValues(guid!)
      .then((data): void => {
        if (data.Success) {
          setValues({
            ...data.Object!,
            Guid: guid,
          });
          setLoading(false);
        } else {
          toast.error('Erro ao buscar informações do vídeo');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar informações do vídeo');
      });
  };

  // useEffect to get data
  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Editar Vídeo"
      />
      <Box
        width="large"
      >
        {
          (loading) ? (<Loading />) : (
            <AddVideoForm
              initialValues={values}
              onSubmit={editVideoSubmit}
              onCancel={onCancel}
              editing
            />
          )
        }
      </Box>
    </PageWrapper>
  );
};

VideoEdit.displayName = 'VideoEdit';

export default VideoEdit;
