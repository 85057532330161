import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAppContext from '../../Template/Context/useAppContext';
import PageWrapper from '../../PageWrapper/PageWrapper';
import Loading from '../../Loading/Loading';
import onCheckingIsOwner from '../Helpers/onCheckingIsOwner';

export interface IsOwnerAuthProps { }

const IsOwnerAuth: React.FC<IsOwnerAuthProps> = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    state: { IsAuthenticated },
  } = useAppContext();

  const onError = (): void => {
    if (IsAuthenticated) {
      navigate('/403');
    } else {
      navigate(`/login?RedirectUrl=${location.pathname}`);
    }
    toast.error('Desculpe, mas não foi possível verificar a autenticação');
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [validated, setValidated] = useState<boolean>(false);
  const changeValidated = (): void => setValidated(true);

  const startValidateWithAuthentication = async (): Promise<void> => {
    await onCheckingIsOwner()
      .then((data): void => {
        if (data.Success && data.Object) {
          setLoading(false);
          changeValidated();
        } else {
          onError();
        }
      })
      .catch((): void => {
        onError();
      })
    ;
  };

  useEffect((): void => {
    if (!IsAuthenticated) {
      navigate(`/login?RedirectUrl=${location.pathname}`);
      return;
    }

    startValidateWithAuthentication();
  }, []);

  return (loading) ? (
    <PageWrapper>
      <Loading />
    </PageWrapper>
  ) : (
    <>{(validated) && <Outlet />}</>
  );
};

IsOwnerAuth.displayName = 'IsOwnerAuth';

export default IsOwnerAuth;
