import { Route } from 'react-router-dom';
import IsAuthenticatedAuth from './IsAuthenticatedAuth';
import ImportCsv from '../../ImportCsv/ImportCsv';
import Error404 from '../../Error404/Error404';
import ManageEmpresasUsuarios from '../../ManageEmpresasUsuarios/ManageEmpresasUsuarios';
import MinhaEmpresa from '../../MinhaConta/MinhaEmpresa/MinhaEmpresa';
import EditarMinhaEmpresa from '../../MinhaConta/EditarMinhaEmpresa/EditarMinhaEmpresa';
import ManageUsuariosDocumentos from '../../ManageUsuariosDocumentos/ManageUsuariosDocumentos';
import AdicionarDocumentoUsuario from '../../ManageUsuariosDocumentos/Components/AdicionarDocumentoUsuario/AdicionarDocumentoUsuario';
import EditarUsuariosDocumentos from '../../ManageUsuariosDocumentos/Components/EditarUsuariosDocumentos/EditarUsuariosDocumentos';
import EditUser from '../../ManageUsers/Components/EditUser/EditUser';
import ManageAccount from '../../ManageAccount/ManageAccount';
import ManageProjetos from '../../ManageProjetos/ManageProjetos';
import ManageProjetosRelatorio from '../../ManageProjetosRelatorio/ManageProjetosRelatorio';

const useIsAuthenticatedRouter = (): JSX.Element => {
  return (
    <>
      <Route path="auth" element={<IsAuthenticatedAuth />} >
        <Route path="importCsv" element={<ImportCsv />} />
        <Route path="manageEmpresasUsuarios" element={<ManageEmpresasUsuarios />} />
        <Route path="minhaConta" element={<ManageAccount />} />
        {/* Empresas */}
        <Route path="minhaEmpresa" element={<MinhaEmpresa />} />
        <Route path="editarMinhaEmpresa" element={<EditarMinhaEmpresa />} />
        {/* /Empresas */}
        {/* Manage Usuario Documentos */}
        <Route path="manageUsuariosDocumentos/:guid" element={<ManageUsuariosDocumentos />} />
        <Route path="adicionarDocumentoUsuario/:guid" element={<AdicionarDocumentoUsuario />} />
        <Route path="EditarUsuariosDocumentos/:guid" element={<EditarUsuariosDocumentos />} />
        <Route path="editUser/:guid" element={<EditUser />} />
        {/* /Manage Usuario Documentos */}
        {/* Projetos */}
        <Route path="projetos" element={<ManageProjetos />} />
        <Route path="relatorioProjeto/:guid" element={<ManageProjetosRelatorio />} />
        {/* /Projetos */}
        <Route path="*" element={<Error404 />} />
      </Route>
    </>
  );
};

export default useIsAuthenticatedRouter;
