import { IUsuarioDocumento } from '../../../../Interfaces/IUsuarioDocumento';

const adicionarDocumentoUsuarioInitialValues = (usuarioGuid: string): IUsuarioDocumento => {
  // add two years to the current date
  let dataVencimento: Date = new Date();
  dataVencimento.setFullYear(dataVencimento.getFullYear() + 2);

  return {
    Usuario: {
      Guid: usuarioGuid,
    },
    Tipo: {
      Guid: '',
    },
    TipoDocumento: '',
    Upload: {
      UploadMimeType: '',
      UploadBase64: '',
      UploadExtension: '',
      UploadGuid: '',
    },
    UploadNome: [],
    Nome: '',
    Descricao: '',
    Ativo: true,
    Vencimento: false,
    // add two years to the current date
    DataVencimento: dataVencimento.toISOString(),
  };
};

export default adicionarDocumentoUsuarioInitialValues;
