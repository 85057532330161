import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const editarDocumentoSubmit = async (values: IEmpresaDocumento): Promise<IBasicReturn> => {
  let basicReturn: IBasicReturn<IEmpresaDocumento> = { Success: false };

  await MyAxiosInstance().post<IBasicReturn<IEmpresaDocumento>>('Empresa/DocumentoUpdateInfo', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = { Success: false };

      return basicReturn;
    });

  return basicReturn;
};

export default editarDocumentoSubmit;
