import { IBasicReturn } from '../../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../../utils/AxiosInstance';

const onLogout = async (): Promise<IBasicReturn> => {
  let logoutResult: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn>('Identity/SignOut')
    .then((response): IBasicReturn => {
      const { data } = response;

      logoutResult = data;

      return logoutResult;
    })
    .catch((): IBasicReturn => {
      logoutResult.Success = false;

      return logoutResult;
    });

  return logoutResult;
};

export default onLogout;
