import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IPagination, Pagination as PaginationClass } from '../../../Interfaces/IPagination';
import { IStreamingVideo } from '../../../Interfaces/IStreamingVideo';
import getVideos from './Helpers/getVideos';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import totalPages from '../Pagination/Helpers/totalPages';
import PageSize from '../Pagination/Components/PageSize';
import ManageVideosDataTable from './Components/ManageVideosDataTable';
import PesquisarVideos from './Components/PesquisarVideos/PesquisarVideos';

export interface ManageVideosProps {}

const ManageVideos: React.FC<ManageVideosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onAddVideoClick = (): void => {
    navigate('/owner/videoInsert');
  };

  const [videos, setVideos] = useState<IPagination<IStreamingVideo[]>>(new PaginationClass(1, 30, 30, []));
  const [loading, setLoading] = useState<boolean>(true);

  const changeLoading = (newLoading: boolean): void => {
    setLoading(newLoading);
  };

  const setData = (data: IPagination<IStreamingVideo[]>): void => {
    setVideos(data);
  };

  const initialPagination: IPagination<IStreamingVideo[]> = new PaginationClass(1, 30, 30);

  const getData = async (pagination: IPagination = initialPagination): Promise<void> => {
    setLoading(true);

    await getVideos(pagination)
      .then((data): void => {
        if (data.Success) {
          setVideos(data.Object!);
        } else {
          toast.error('Erro ao buscar empresas');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar empresas');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(parseInt(newPage, 10), videos.PageSize);
    getData(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getData(newPagination);
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Gerenciar Vídeos"
      />
      <Box
        width="xlarge"
        align="end"
      >
        <Button
          primary
          label="Adicionar Vídeo"
          title="Adicionar Vídeo"
          onClick={onAddVideoClick}
          icon={<Add />}
          reverse
        />
      </Box>
      <Box
        fill="horizontal"
        direction="column"
        gap="none"
        margin={{
          top: 'medium',
        }}
      >
        <PesquisarVideos
          refreshData={getData}
          setResults={setData}
          changeLoading={changeLoading}
        />
        {
          (loading) ? (<Loading />) : (
            <>
              <Pagination
                page={videos.Page!}
                last={totalPages(videos.Total || 0, videos.PageSize!)}
                onChange={updatePage}
              />
              <ManageVideosDataTable
                videos={videos.Object || []}
                refreshData={getData}
              />
              <PageSize
                size={videos.PageSize!}
                onChange={updatePageSize}
              />
              <Pagination
                page={videos.Page!}
                last={totalPages(videos.Total || 0, videos.PageSize!)}
                onChange={updatePage}
              />
            </>
          )
        }
      </Box>
    </PageWrapper>
  );
};

ManageVideos.displayName = 'ManageVideos';

export default ManageVideos;
