import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import { IPagination } from '../../../../Interfaces/IPagination';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const pesquisarEmpresas = async (searchTerm: string): Promise<IBasicReturn<IPagination<IEmpresa[]>>> => {
  let empresas: IBasicReturn<IPagination<IEmpresa[]>> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPagination<IEmpresa[]>>>(`Empresa/PesquisarEmpresa/${searchTerm}`)
    .then((response): IBasicReturn<IPagination<IEmpresa[]>> => {
      const { data } = response;

      empresas = data;

      return empresas;
    })
    .catch((): IBasicReturn<IPagination<IEmpresa[]>> => {
      empresas.Success = false;

      return empresas;
    });

  return empresas;
};

export default pesquisarEmpresas;
