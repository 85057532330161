import React, { useContext, useState } from 'react';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import { Box, Button, FormExtendedEvent, ResponsiveContext } from 'grommet';
import FormWrapper from '../../FormWrapper/FormWrapper';
import DropDownEquipamentosPorEmpresa from '../../DropDownEquipamentosPorEmpresa/DropDownEquipamentosPorEmpresa';

export interface ProjetoEquipamentoFormProps {
  initialValues: IEquipamento;
  onSubmit: (values: IEquipamento) => void;
  submitting: boolean;
  onCancel?: () => void;
}

const ProjetoEquipamentoForm: React.FC<ProjetoEquipamentoFormProps> = ({ initialValues, onSubmit, submitting, onCancel }): JSX.Element => {
  const [values, setValues] = useState<IEquipamento>(initialValues);
  const onChange = (nextValues: IEquipamento): void => setValues(nextValues);

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IEquipamento>): Promise<void> => {
    onSubmit(evt.value);
  };

  const size = useContext(ResponsiveContext);

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="end"
      >
        <DropDownEquipamentosPorEmpresa
          name="Guid"
          label="Equipamento"
          title="Por favor selecione o equipamento"
          disabled={submitting}
        />
      </Box>

      <Box
        direction="row"
        gap="medium"
        justify="start"
        margin={{
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <Button
          type="submit"
          label="Salvar"
          title="Salvar"
          disabled={submitting}
          primary
          reverse
        />
        <Button
          label="Cancelar"
          title="Cancelar"
          onClick={onClear}
          reverse
        />
      </Box>
    </FormWrapper>
  );
};

ProjetoEquipamentoForm.displayName = 'ProjetoEquipamentoForm';

export default ProjetoEquipamentoForm;
