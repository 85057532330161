import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { Box } from 'grommet';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../Loading/Loading';
import DocumentosForm from '../../../Forms/Documentos/DocumentosForm';
import getUsuariosDocumentosInfoByGuid from '../../Helpers/editarUsuariosDocumentosInitialValues';
import { IUsuarioDocumento } from '../../../../../Interfaces/IUsuarioDocumento';
import editarUsuariosDocumentosSubmit from '../../Helpers/editarUsuariosDocumentosSubmit';
import useAppContext from '../../../Template/Context/useAppContext';

export interface EditarUsuariosDocumentosProps {}

const EditarUsuariosDocumentos: React.FC<EditarUsuariosDocumentosProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const {
    state: { Admin },
  } = useAppContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [documento, setDocumento] = useState<IUsuarioDocumento>({});

  const onCancel = (): void => {
    const url = (Admin) ? `/auth/manageUsuariosDocumentos/${documento.Usuario!.Guid}` : `/owner/manageUsuariosDocumentos/${documento.Usuario!.Guid}`;
    console.log(url);
    navigate(url);
  };

  const getInitialData = async (): Promise<void> => {
    await getUsuariosDocumentosInfoByGuid(guid!)
      .then((data): void => {
        if (data.Success) {
          const initialValues: IUsuarioDocumento = data.Object!;
          setDocumento(initialValues);
        } else {
          toast.error('Erro ao buscar informações do documento');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar informações do documento');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Editar documento"
      />
      <Box
        width="large"
      >
        {
          (loading) ? (<Loading />)
            : (
              <DocumentosForm
                initialValues={documento}
                onSubmit={editarUsuariosDocumentosSubmit}
                onCancel={onCancel}
                categoriaDocs={1}
                editing
              />
            )
        }
      </Box>
    </PageWrapper>
  );
};

EditarUsuariosDocumentos.displayName = 'EditarUsuariosDocumentos';

export default EditarUsuariosDocumentos;
