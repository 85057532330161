import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const onChangeActivePropertyForEquipamentos = async (Guid: string): Promise<IBasicReturn> => {
  let equipamentos: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn>(`Empresa/ChangeActivePropertyForEquipamentos/${Guid}`)
    .then((response): IBasicReturn => {
      const { data } = response;

      equipamentos = data;

      return equipamentos;
    })
    .catch((): IBasicReturn => {
      equipamentos.Success = false;

      return equipamentos;
    });

  return equipamentos;
};

export default onChangeActivePropertyForEquipamentos;
