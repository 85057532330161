import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { Box } from 'grommet';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../Loading/Loading';
import EmpresaForm from '../../../Forms/Empresa/EmpresaForm';
import { IEmpresa } from '../../../../../Interfaces/IEmpresa';
import getEmpresaInfoByGuid from '../../Helpers/editarEmpresaInitialValues';
import editarEmpresaSubmit from '../../Helpers/editarEmpresaSubmit';

export interface EditarEmpresaProps {}

const EditarEmpresa: React.FC<EditarEmpresaProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();
  const onCancel = (): void => navigate('/owner/manageEmpresas');

  const [loading, setLoading] = useState<boolean>(true);
  const [empresa, setEmpresa] = useState<IEmpresa>({});

  const getInitialData = async (): Promise<void> => {
    await getEmpresaInfoByGuid(guid!)
      .then((data): void => {
        if (data.Success) {
          const initialValues: IEmpresa = data.Object!;
          setEmpresa(initialValues);
        } else {
          toast.error('Erro ao buscar informações da empresa');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar informações da empresa');
      });
  };

  const onSubmit = async (values: IEmpresa): Promise<void> => {
    await editarEmpresaSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Informações da empresa salvas com sucesso');
          navigate('/owner/manageEmpresas');
        } else {
          toast.error('Erro ao salvar informações da empresa');
        }
      })
      .catch((): void => {
        toast.error('Erro ao salvar informações da empresa');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Editar empresa"
      />
      <Box
        width="large"
      >
        {
          (loading) ? (<Loading />)
            : (
              <EmpresaForm
                initialValues={empresa}
                onSubmit={onSubmit}
                onCancel={onCancel}
              />
            )
        }
      </Box>
    </PageWrapper>
  );
};

EditarEmpresa.displayName = 'EditarEmpresa';

export default EditarEmpresa;
