import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const EditarEquipamentoSubmit = async (values: IEquipamento): Promise<IBasicReturn> => {
  let basicReturn: IBasicReturn<IEquipamento> = { Success: false };

  await MyAxiosInstance().post<IBasicReturn<IEquipamento>>('Empresa/EquipamentosUpdateInfo', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = { Success: false };

      return basicReturn;
    });

  return basicReturn;
};

export default EditarEquipamentoSubmit;
