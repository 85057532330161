import { Box, Paragraph, Text } from 'grommet';
import styled from 'styled-components';


export const CardBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 16px;
  width: 332px;

  @media only screen and (max-width: 768px) {
  }
`;

export const CardSection = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 80px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
  }
`;

export const ContentSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  align-self: self-start;

  @media only screen and (max-width: 768px) {
    margin-top: 60px;
  }
`;

export const Title = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.35;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const TitleSmall = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Description = styled(Paragraph)`
  font-size: 16px;
  font-weight: 600;
  color: #929a9f;
  line-height: 1.65;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const MidSectionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 80px;

  @media only screen and (max-width: 768px) {
    gap: 32px;
  }
`;
