import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { Box } from 'grommet';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../Loading/Loading';
import DocumentosForm from '../../../Forms/Documentos/DocumentosForm';
import { IEquipamentoDocumento } from '../../../../../Interfaces/IEquipamentoDocumento';
import getEquipamentoDocumentoInfoByGuid from '../../Helpers/editarEquipamentoDocumentoInitialValues';
import editarEquipamentoDocumentoSubmit from '../../Helpers/editarEquipamentoDocumentoSubmit';

export interface EditarEquipamentoDocumentoProps {}

const EditarEquipamentoDocumento: React.FC<EditarEquipamentoDocumentoProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [documento, setDocumento] = useState<IEquipamentoDocumento>({});

  const onCancel = (): void => navigate(`/admin/manageEquipamentosDocumentos/${documento.Equipamento!.Guid}`);

  const getInitialData = async (): Promise<void> => {
    await getEquipamentoDocumentoInfoByGuid(guid!)
      .then((data): void => {
        if (data.Success) {
          const initialValues: IEquipamentoDocumento = data.Object!;
          initialValues.TipoDocumento = initialValues.Tipo!.Guid!;
          setDocumento(initialValues);
        } else {
          toast.error('Erro ao buscar informações do documento');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar informações do documento');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Editar documento do equipamento"
      />
      <Box
        width="large"
      >
        {
          (loading) ? (<Loading />)
            : (
              <DocumentosForm
                initialValues={documento}
                onSubmit={editarEquipamentoDocumentoSubmit}
                onCancel={onCancel}
                editing
              />
            )
        }
      </Box>
    </PageWrapper>
  );
};

EditarEquipamentoDocumento.displayName = 'EditarEquipamentoDocumento';

export default EditarEquipamentoDocumento;