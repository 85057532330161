import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IPagination } from '../../../../Interfaces/IPagination';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getPlantas = async (empresaGuid: string, page: IPagination): Promise<IBasicReturn<IPagination<IPlanta[]>>> => {
  let apiReturn: IBasicReturn<IPagination<IPlanta[]>> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPagination<IPlanta[]>>>(`Empresa/Plantas/${empresaGuid}/${page.Page}/${page.PageSize}`)
    .then((response): IBasicReturn<IPagination<IPlanta[]>> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn<IPagination<IPlanta[]>> => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default getPlantas;
