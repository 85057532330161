import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const passwordRecoveryValidateCode = async (userData: IUser): Promise<IBasicReturn<string>> => {
  let apiResponse: IBasicReturn<string> = {
    Success: true,
  };

  await MyAxiosInstance().post<IBasicReturn<string>>('Identity/PasswordRecoveryValidateCode', userData)
    .then((response): IBasicReturn<string> => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn<string> => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default passwordRecoveryValidateCode;
