import React from 'react';
import { Box } from 'grommet';
import {
  AboutText, FooterText, MediaNameText, RDOCardContainer,
} from './RDOCardStyles';
import { IProjetoDocumento } from '../../../../../Interfaces/IProjetoDocumento';
import CriadoPor from '../../../CriadoPor/CriadoPor';

export interface RDOCardProps {
  documento: IProjetoDocumento;
}

const RDOCard: React.FC<RDOCardProps> = ({ documento }): JSX.Element => {
  const onDocumentoClick = (): void => {
    window.open(`${import.meta.env.VITE_API_URL}/api/Empresa/DownloadDocumentoProjeto/${documento.Guid}.${documento.Upload?.UploadExtension}`, '_blank');
  };

  return (
    <RDOCardContainer
      hoverIndicator
      onClick={onDocumentoClick}
    >
      <MediaNameText>{documento.Titulo}</MediaNameText>
      <AboutText>{documento.Observacao}</AboutText>
      <Box fill='horizontal' direction='row' align='center' justify='between'>
      <FooterText>
        <CriadoPor
          criadoPor={documento.CreatedBy!}
        />
      </FooterText>
      <FooterText alignSelf='end'>{documento.Created}</FooterText>
      </Box>
    </RDOCardContainer>
  );
};

RDOCard.displayName = 'RDOCard';

export default RDOCard;
