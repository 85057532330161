import React from 'react';
import { DocumentImage, DocumentPdf } from 'grommet-icons';
import { Button } from 'grommet';
import { IUsuarioDocumento } from '../../../../Interfaces/IUsuarioDocumento';

export interface ManageUsuariosDocumentoDownloadProps {
  documento: IUsuarioDocumento;
}

const ManageUsuariosDocumentoDownload: React.FC<ManageUsuariosDocumentoDownloadProps> = ({ documento }): JSX.Element => {
  const Icon = (): JSX.Element => {
    if (documento.Upload?.UploadMimeType === 'application/pdf') {
      return (
        <DocumentPdf color='brand' size='16px' />
      );
    }

    return (
      <DocumentImage color='brand' size='16px' />
    );
  };

  const onClick = (): void => {
    window.open(`${import.meta.env.VITE_API_URL}/api/Identity/DownloadDocumentoUsuario/${documento.Guid}.${documento.Upload?.UploadExtension}`, '_blank');
  };

  return (
    <Button
      icon={<Icon />}
      onClick={onClick}
      title='Visualizar documento'
    />
  );
};

ManageUsuariosDocumentoDownload.displayName = 'ManageUsuariosDocumentoDownload';

export default ManageUsuariosDocumentoDownload;
