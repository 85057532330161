import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from 'grommet';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import DocumentosForm from '../../../Forms/Documentos/DocumentosForm';
import adicionarDocumentoEquipInitialValues from '../../Helpers/adicionarDocumentoEquipInitialValues';
import adicionarDocumentoEquipSubmit from '../../Helpers/adicionarDocumentoEquipSubmit';
import useAppContext from '../../../Template/Context/useAppContext';

export interface AdicionarEquipamentoDocumentoProps {}

const AdicionarEquipamentoDocumento: React.FC<AdicionarEquipamentoDocumentoProps> = ({ }): JSX.Element => {
  const {
    state: { Admin },
  } = useAppContext();

  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onCancel = (): void => {
    if (Admin) {
      navigate(`/admin/manageEquipamentosDocumentos/${guid}`);
    } else {
      navigate(`/owner/manageEquipamentosDocumentos/${guid}`);
    }
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Documento"
      />
      <Box
        width="large"
      >
        <DocumentosForm
          initialValues={adicionarDocumentoEquipInitialValues(guid!)}
          onSubmit={adicionarDocumentoEquipSubmit}
          onCancel={onCancel}
          categoriaDocs={2}
          esconderCategorias
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarEquipamentoDocumento.displayName = 'AdicionarEquipamentoDocumento';

export default AdicionarEquipamentoDocumento;
