export interface IError {
  ErrorId?: number;
  ErrorSource?: string;
  ErrorName?: string;
  ErrorMessage?: string;
  ErrorStack?: string;
  ComponentStack?: string;
  Url?: string;
  DevEnv?: boolean;
  CreatedBy?: number;
  Created?: string;
}

export class CustomError implements IError {
  ErrorSource: string;

  ErrorName: string;

  ErrorMessage: string;

  ErrorStack: string;

  ComponentStack: string;

  Url: string;

  DevEnv: boolean;

  constructor(
    errorName: string,
    errorMessage: string,
    errorStack: string,
    componentStack: string,
  ) {
    this.ErrorSource = 'React';
    this.ErrorName = errorName;
    this.ErrorMessage = errorMessage;
    this.ErrorStack = errorStack;
    this.ComponentStack = componentStack;
    this.Url = window.location.href;
    this.DevEnv = (window.location.href).includes('localhost');
  }
}
