import { Route } from 'react-router-dom';
import TwoFactorAuth from './TwoFactorAuth';
import ValidarEmail from '../../ManageAccount/ValidarEmail/ValidarEmail';
import Error404 from '../../Error404/Error404';
import ChangePassword from '../../ManageAccount/ChangePassword/ChangePassword';
import ChangeName from '../../ManageAccount/ChangeName/ChangeName';
import ChangeEmail from '../../ManageAccount/ChangeEmail/ChangeEmail';
import ChangeSignature from '../../ManageAccount/ChangeSignature/ChangeSignature';

const useTwoFactorRouter = (): JSX.Element => {
  return (
    <>
      <Route path="two-factor" element={<TwoFactorAuth />} >
        <Route path="validarEmail" element={<ValidarEmail />} />
        <Route path="alterarSenha" element={<ChangePassword />} />
        <Route path="alterarNome" element={<ChangeName />} />
        <Route path="alterarEmail" element={<ChangeEmail />} />
        <Route path="alterarAssinatura" element={<ChangeSignature />} />
        <Route path="*" element={<Error404 />} />
      </Route>
    </>
  );
};

export default useTwoFactorRouter;
