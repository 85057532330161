import React from 'react';
import {
  ActionButton, CompanyNameText, DateProjectText, ProfileCardContainer, ProfileCardFooter, TagDocNumber,
} from './Styles/ProjetoCardStyles';
import { Box, Button, Heading } from 'grommet';
import {
  Attachment, CirclePlay, Cpu, DocumentText, Edit, Organization, Trash, UserWorker,
} from 'grommet-icons';
import { toast } from 'react-toastify';
import { ChangeTabButtonStatus } from './Components/ChangeTabButtonStatus';
import { useNavigate } from 'react-router-dom';
import { IProjeto } from '../../../../../Interfaces/IProjeto';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import projetoExcluir from '../../Helpers/projetoExcluir';
import ProjetoIniciar from '../ProjetoStatus/ProjetoIniciar';
import ProjetoFinalizar from '../ProjetoStatus/ProjetoFinalizar';
import useAppContext from '../../../Template/Context/useAppContext';
import AdminChecker from '../../../AdminChecker/AdminChecker';

export interface ProjetoCardProps {
  projeto: IProjeto;
  refreshData: () => void;
}

const ProjetoCard: React.FC<ProjetoCardProps> = ({
  projeto,
  refreshData,
}): JSX.Element => {
  const navigate = useNavigate();

  const {
    state: { Admin },
  } = useAppContext();

  const onProjetoConsolidadoClick = (): void => {
    if (Admin) {
      navigate(`/admin/relatorioProjeto/${projeto.Guid}`);
    } else {
      navigate(`/auth/relatorioProjeto/${projeto.Guid}`);
    }
  };

  const onDocumentosClick = (): void => {
    navigate(`/admin/manageProjetosDocumentos/${projeto.Guid}`);
  };

  const onTecnicosClick = (): void => {
    navigate(`/admin/manageProjetosTecnicos/${projeto.Guid}`);
  };

  const onVideosClick = (): void => {
    navigate(`/admin/manageProjetosVideos/${projeto.Guid}`);
  };
  
  const onEquipamentosClick = (): void => {
    navigate(`/admin/manageProjetosEquipamentos/${projeto.Guid}`);
  };

  const onEditProjetoClick = (): void => {
    navigate(`/admin/editarProjeto/${projeto.Guid}`);
  };

  const [showExcluir, setShowExcluir] = React.useState<boolean>(false);
  const invertChangeExcluir = (): void => setShowExcluir(!showExcluir);

  const onExcluirProjetoClick = (): void => {
    invertChangeExcluir();
  };

  const onExcluirProjeto = async (): Promise<void> => {
    await projetoExcluir(projeto.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Projeto excluído com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir projeto');
        }
        invertChangeExcluir();
      })
      .catch((): void => {
        toast.error('Erro ao excluir projeto');
        invertChangeExcluir();
      });
  };

  const datasDoProjeto = (): string => {
    if (projeto.Status === 1) {
      return `${projeto.PrevisaoInicio} até ${projeto.PrevisaoTermino}`;
    }

    if (projeto.Status === 2) {
      return `${projeto.Inicio} até ${projeto.PrevisaoTermino}`;
    }

    return `${projeto.Inicio} até ${projeto.Termino}`;
  };

  const empresaPlanta = (): string => `${projeto.Empresa?.NomeFantasia} - ${projeto.Planta?.Nome}`;

  return (
    <ProfileCardContainer>
      <Box fill="horizontal" direction="row" align="center" justify="between">
        <DateProjectText>
          {datasDoProjeto()}
        </DateProjectText>
        <Box direction="row" align="center" gap="8px">
          <Organization size="14px" />
          <CompanyNameText>{empresaPlanta()}</CompanyNameText>
        </Box>
      </Box>
      <Box gap="none" fill="horizontal" pad={{ bottom: '16px' }}>
        <Heading margin={{ bottom: '16px', top: '0' }} level={5}>
          {projeto.Titulo}
        </Heading>
        <Box fill="horizontal" direction="row" align="center" justify="between">
          <Box>
            <ChangeTabButtonStatus type={projeto.Status} />
          </Box>
          {projeto.Status === 1 && (
            <AdminChecker>
              <ProjetoIniciar projeto={projeto} refreshData={refreshData} />
            </AdminChecker>
          )}
          {projeto.Status === 2 && (
            <AdminChecker>
              <ProjetoFinalizar projeto={projeto} refreshData={refreshData} />
            </AdminChecker>
          )}
        </Box>
      </Box>

      <Box width="100%">
        <Box
          alignSelf="start"
          pad={{ bottom: '4px' }}
          flex
          direction="row"
          justify="between"
          align="center"
          width="100%"
        >
          <Box direction="row" gap="8px">
            <AdminChecker>
              <ActionButton
                style={{ fontSize: '14px' }}
                gap='4px'
                icon={<DocumentText size="16px" color="#47435F" />}
                label="Relatório"
                size="xsmall"
                onClick={onProjetoConsolidadoClick}
              />
            </AdminChecker>
            <AdminChecker>
              <ActionButton
                style={{ fontSize: '14px' }}
                gap='4px'
                icon={<CirclePlay size="16px" color="#47435F" />}
                label={
                  projeto?.TotalVideos
                    ? projeto?.TotalVideos + ' Vídeos'
                    : 0 + ' Vídeo'
                }
                size="xsmall"
                onClick={onVideosClick}
              />
            </AdminChecker>
          </Box>
          <Box direction="row" gap="1rem">
            <AdminChecker>
              <Button
                icon={<Edit size="16px" color="#47435F" />}
                title="Editar Projeto"
                onClick={onEditProjetoClick}
              />
            </AdminChecker>
            <AdminChecker>
              <Button
                icon={<Trash size="16px" color="#47435F" />}
                title="Excluir Projeto"
                onClick={onExcluirProjetoClick}
              />
            </AdminChecker>
          </Box>
        </Box>
        <ProfileCardFooter>
          <AdminChecker>
            <TagDocNumber
              icon={<Attachment color="#47435F" size="12px" />}
              title="Total de Documentos"
              size="small"
              onClick={onDocumentosClick}
              label={
                projeto?.TotalDocumentos
                  ? projeto?.TotalDocumentos + ' Docs'
                  : 0 + ' Doc'
              }
            />
          </AdminChecker>
          <AdminChecker>
            <TagDocNumber
              icon={<UserWorker color="#47435F" size="12px" />}
              title="Total de Técnicos"
              size="small"
              onClick={onTecnicosClick}
              label={
                projeto.TotalTecnicos
                  ? projeto.TotalTecnicos + ' Técnicos'
                  : 0 + ' Técnico'
              }
            />
          </AdminChecker>
          <AdminChecker>
            <TagDocNumber
              icon={<Cpu color="#47435F" size="12px" />}
              title="Total de Equipamentos"
              size="small"
              onClick={onEquipamentosClick}
              label={
                projeto.TotalEquipamentos
                  ? projeto.TotalEquipamentos + ' Equipamentos'
                  : 0 + ' Equipamento'
              }
            />
          </AdminChecker>
          {showExcluir && (
            <ConfirmModal
              title="Excluir Projeto"
              text="Esta ação excluirá definitivamente o projeto e seus documentos. Deseja continuar?"
              onConfirm={onExcluirProjeto}
              onCancel={invertChangeExcluir}
            />
          )}
        </ProfileCardFooter>
      </Box>
    </ProfileCardContainer>
  );
};

ProjetoCard.displayName = 'ProjetoCard';

export default ProjetoCard;
