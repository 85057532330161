import { Button } from 'grommet';
import { DocumentImage, DocumentPdf } from 'grommet-icons';
import React from 'react';
import { IProjetoDocumento } from '../../../../Interfaces/IProjetoDocumento';

export interface ProjetoRelatorioDocumentoDownloadProps {
  documento: IProjetoDocumento;
}

const ProjetoRelatorioDocumentoDownload: React.FC<ProjetoRelatorioDocumentoDownloadProps> = ({ documento }): JSX.Element => {
  const Icon = (): JSX.Element => {
    if (documento.Upload?.UploadMimeType === 'application/pdf') {
      return (
        <DocumentPdf color='brand' size='16px' />
      );
    }

    return (
      <DocumentImage color='brand' size='16px' />
    );
  };

  const onClick = (): void => {
    window.open(`${import.meta.env.VITE_API_URL}/api/Empresa/DownloadDocumentoProjeto/${documento.Guid}.${documento.Upload?.UploadExtension}`, '_blank');
  };

  return (
    <Button
      icon={<Icon />}
      title='Baixar Documento'
      onClick={onClick}
    />
  );
};

ProjetoRelatorioDocumentoDownload.displayName = 'ProjetoRelatorioDocumentoDownload';

export default ProjetoRelatorioDocumentoDownload;
