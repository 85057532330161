import {
  Box, Button, FormExtendedEvent, FormField,
} from 'grommet';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import importCsvInitialValues from './Helpers/importCsvInitialValues';
import { IUpload } from '../../../Interfaces/IUpload';
import onUploadCsv from './Helpers/onUploadCsv';
import FormWrapper from '../FormWrapper/FormWrapper';
import { UploadInvisible } from '../InputInvisible/InputInvisible';
import UploadInfo from '../UploadInfo/UploadInfo';
import { IUser } from '../../../Interfaces/IUser';
import ImportCsvLayer from './Components/ImportCsvLayer';

export interface ImportCsvProps {}

const ImportCsv: React.FC<ImportCsvProps> = ({}): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<IUpload>(importCsvInitialValues);
  const onChange = (newValues: IUpload): void => {
    setValues(newValues);
  };

  const onClear = (): void => {
    setValues(importCsvInitialValues);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [loadedFile, setLoadedFile] = useState<Blob>(new Blob());

  const [users, setUsers] = useState<IUser[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const onChangeShowModal = (): void => {
    setShowModal(!showModal);
  };

  const onSubmit = async (event: FormExtendedEvent<IUpload>): Promise<void> => {
    setSubmitting(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { value } = event;

    // await onUploadCsv(loadedFile)
    await onUploadCsv(loadedFile)
      .then((data): void => {
        if (data.Success) {
          if (data.Object?.length === 0) {
            toast.warn('Nenhum usuário carregado');
            return;
          }

          onClear();
          setUsers(data.Object!);
          setShowModal(true);
        } else {
          toast.error('Erro ao enviar arquivo');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        toast.error('Erro ao enviar arquivo');
      });
  };

  const pickFile = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    // @ts-ignore: Object is possibly 'null'.
    const file = event.target.files[0];

    if (file === null) {
      toast.error('Nenhum arquivo selecionado');
      return;
    }

    if (file.size >= 2097152) {
      toast.error('Tamanho máximo é 2mb');
      return;
    }

    const reader = new FileReader();

    reader.onload = (): void => {
      // const { result } = reader;
      const [extension] = file.name.split('.').reverse();

      if (!extension) {
        toast.error('Extensão inválida');
        return;
      }

      setValues({
        ...values,
        UploadName: file.name,
        UploadMimeType: file.type,
        UploadSize: file.size,
        // UploadBase64: result?.toString(),
        UploadExtension: extension,
        UploadInput: file.name,
      });

      setLoadedFile(file);
    };

    reader.onerror = (): void => {
      toast.error('Erro ao carregar arquivo');
    };

    reader.readAsDataURL(file);
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Import CSV"
      />
      <FormWrapper
        values={values}
        onChange={onChange}
        onSubmit={onSubmit}
        >
          <FormField
            label="Selecione um arquivo .CSV"
            width="large"
            name="UploadInput"
            required
          >
            <Box
              fill="horizontal"
              height="xsmall"
              align="center"
              justify="center"
              border="all"
              a11yTitle="Selecione um arquivo .CSV"
              title="Selecione um arquivo .CSV"
              hoverIndicator
              onClick={pickFile}
            >
              Selecione um arquivo .CSV
              <UploadInvisible
                ref={fileInputRef}
                name="UploadInput"
                type="file"
                id="UploadInput"
                onChange={onChangeFile}
              />
            </Box>
          </FormField>
        {
          (values.UploadMimeType) && <UploadInfo upload={values} />
        }
        <Box
          direction="row"
          justify="end"
          gap="small"
          margin={{
            top: 'medium',
          }}
        >
          <Button
            type="submit"
            label="Enviar arquivo"
            title="Enviar arquivo"
            disabled={submitting}
            primary
            reverse
          />
          <Button
            label="Cancelar"
            title="Cancelar"
            onClick={onClear}
            disabled={submitting}
            reverse
          />
        </Box>
        </FormWrapper>
        {
          (showModal) && (
            <ImportCsvLayer
              users={users}
            onClose={onChangeShowModal}
            />
          )
        }
    </PageWrapper>
  );
};

ImportCsv.displayName = 'ImportCsv';

export default ImportCsv;
