import React, { useEffect, useState } from 'react';
import {
  Box, Button, Layer, Paragraph, Tab, Tabs,
} from 'grommet';
import {
  Briefcase, Calendar, Cpu, DocumentText, FormClose, LinkPrevious, UserWorker, VirtualMachine,
} from 'grommet-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import Loading from '../Loading/Loading';
import getProjetoRelatorio from './Helpers/getProjetoRelatorio';
import ProjetoRelatorioDocumentosTable from './Components/ProjetoRelatorioDocumentosTable';
import { IProjetoDocumento } from '../../../Interfaces/IProjetoDocumento';
import { IProjeto } from '../../../Interfaces/IProjeto';
import { ChangeTabButtonStatus } from '../ManageProjetos/Components/ProjetoCard/Components/ChangeTabButtonStatus';
import CriadoPor from '../CriadoPor/CriadoPor';
import RDOCard from './Components/RDOCard/RDOCard';
import { TagDocNumber } from '../ManageProjetos/Components/ProjetoCard/Styles/ProjetoCardStyles';
import useAppContext from '../Template/Context/useAppContext';
import ImageCard from './Components/ImageCard';
import EmptyStateImage from './Components/EmptyStateImage';
import VideoCard from './Components/VideoCard';

export interface ManageProjetosRelatorioProps {}

const ManageProjetosRelatorio: React.FC<ManageProjetosRelatorioProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const {
    state: { Admin },
  } = useAppContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [relatorio, setRelatorio] = useState<IProjeto>({});

  const [imagens, setImagens] = useState<IProjetoDocumento[]>([]);
  const [rdo, setRdo] = useState<IProjetoDocumento[]>([]);
  const [relatorios, setRelatorios] = useState<IProjetoDocumento[]>([]);
  const [adicionais, setAdicionais] = useState<IProjetoDocumento[]>([]);
  const [showTecnicos, setShowTecnicos] = useState<boolean>(false);
  const [showEquipamentos, setShowEquipamentos] = useState<boolean>(false);

  const invertChangeTecnicos = (): void => setShowTecnicos(!showTecnicos);
  const invertChangeEquipamentos = (): void => setShowEquipamentos(!showEquipamentos);

  const onVoltarClick = (): void => {
    if (Admin) {
      navigate('/admin/projetos');
    } else {
      navigate('/auth/projetos');
    }
  };

  const onEquipamentosClick = (): void => {
    invertChangeEquipamentos();
  };

  const onTecnicosClick = (): void => {
    invertChangeTecnicos();
  };

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await getProjetoRelatorio(guid!)
      .then((data): void => {
        if (data.Success) {
          const { Object } = data;
          setRelatorio(Object!);

          if (Object?.Documentos && Object.Documentos.length > 0) {
            const docImagens = Object.Documentos.filter(
              (documento) => documento.Tipo?.Id === 2,
            );
            setImagens(docImagens);

            const docRdo = Object.Documentos.filter(
              (documento) => documento.Tipo?.Id === 4,
            );
            setRdo(docRdo);

            const docRelatorios = Object.Documentos.filter(
              (documento) => documento.Tipo?.Id === 3,
            );
            setRelatorios(docRelatorios);

            const docAdicionais = Object.Documentos.filter(
              (documento) => documento.Tipo?.Id === 1,
            );
            setAdicionais(docAdicionais);
          }
        } else {
          toast.error('Erro ao buscar relatório do projeto');
          onVoltarClick();
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar relatório do projeto');
        onVoltarClick();
      });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box width="100%" justify="between" align="center" direction="row">
            <Box direction="row" gap="1rem" align="center">
              <PageTitle textAlign="start" fill Text={relatorio.Titulo!} />
              <Box>
                <ChangeTabButtonStatus type={relatorio.Status} />
              </Box>
            </Box>
            <Button
              label="Voltar"
              title="Voltar"
              onClick={onVoltarClick}
              icon={<LinkPrevious size="14px" />}
              primary
            />
          </Box>
          <Box
            alignSelf="start"
            direction="row"
            gap="1rem"
            margin={{ top: '-24px', bottom: '32px' }}
          >
            <TagDocNumber
              icon={<UserWorker color="#47435F" size="12px" />}
              title="Total de Técnicos"
              size="small"
              onClick={onTecnicosClick}
              disabled={!relatorio.Tecnicos?.length}
              label={
                relatorio.Tecnicos?.length
                  ? relatorio.Tecnicos.length + ' Técnicos'
                  : 0 + ' Técnicos'
              }
            />
            <TagDocNumber
              icon={<Cpu color="#47435F" size="12px" />}
              title="Total de Equipamentos"
              size="small"
              onClick={onEquipamentosClick}
              disabled={!relatorio.Equipamentos?.length}
              label={
                relatorio.Equipamentos?.length
                  ? relatorio.Equipamentos.length + ' Equipamentos'
                  : 0 + ' Equipamentos'
              }
            />
          </Box>
          <Box
            direction="column"
            fill="horizontal"
            align="center"
            justify="between"
          >
            <Box
              direction="row"
              fill="horizontal"
              align="center"
              justify="start"
              gap="6rem"
            >
              <Box pad="small" gap="8px">
                <Box direction="row" gap="1rem" align="center">
                  <Briefcase color="#716F8A" size="14px" />
                  <Paragraph margin="none" color="#716F8A" size="small">
                    Empresa / Planta
                  </Paragraph>
                </Box>
                <Paragraph margin="none" color="#122136">
                  {relatorio.Empresa?.NomeFantasia +
                    ' / ' +
                    relatorio.Planta?.Nome}
                </Paragraph>
              </Box>

              {(relatorio.Status === 2 || relatorio.Status === 3) && (
                <Box pad="small" gap="8px">
                  <Box direction="row" gap="1rem" align="center">
                    <Calendar color="#716F8A" size="14px" />
                    <Paragraph margin="none" color="#716F8A" size="small">
                      Início / Término
                    </Paragraph>
                  </Box>
                  <Paragraph margin="none" color="#122136">
                    {relatorio.Inicio + ' / ' + relatorio.Termino}
                  </Paragraph>
                </Box>
              )}
              {(relatorio.Status === 2 || relatorio.Status === 3) && (
                <>
                  <Box pad="small" gap="8px">
                    <Box direction="row" gap="1rem" align="center">
                      <Calendar color="#716F8A" size="14px" />
                      <Paragraph margin="none" color="#716F8A" size="small">
                        Iniciado por:
                      </Paragraph>
                    </Box>
                    <Paragraph margin="none" color="#122136">
                      <CriadoPor criadoPor={relatorio.IniciadoPor!} />
                    </Paragraph>
                  </Box>
                </>
              )}
              <Box pad="small" gap="8px">
                <Box direction="row" gap="1rem" align="center">
                  <VirtualMachine color="#716F8A" size="14px" />
                  <Paragraph margin="none" color="#716F8A" size="small">
                    Número
                  </Paragraph>
                </Box>
                <Paragraph margin="none" color="#122136">
                  {relatorio.Numero}
                </Paragraph>
              </Box>
            </Box>
            {relatorio.Observacoes && relatorio.Observacoes.length > 0 && (
              <Box
                direction="row"
                fill="horizontal"
                align="center"
                justify="between"
              >
                <Box pad="small" gap="8px">
                  <Box direction="row" gap="1rem" align="center">
                    <DocumentText color="#716F8A" size="14px" />
                    <Paragraph margin="none" color="#716F8A" size="small">
                      Observações
                    </Paragraph>
                  </Box>
                  <Paragraph margin="none" color="#122136">
                    {relatorio.Observacoes}
                  </Paragraph>
                </Box>
              </Box>
            )}

            <Box
              direction="row"
              fill="horizontal"
              align="center"
              justify="between"
            >
              <Box pad="small" gap="8px">
                <Box direction="row" gap="1rem" align="center">
                  <DocumentText color="#716F8A" size="14px" />
                  <Paragraph margin="none" color="#716F8A" size="small">
                    Escopo
                  </Paragraph>
                </Box>
                <Paragraph margin="none" color="#122136">
                  {relatorio.Escopo}
                </Paragraph>
              </Box>
            </Box>
          </Box>

          {/* <ProjetoInfo projeto={relatorio} /> */}

          <PageTitle
            alignSelf="start"
            level="4"
            textAlign="start"
            Text="Dados do Projeto"
          />

          <Tabs
            flex="shrink"
            style={{
              width: '100%',
            }}
          >
            <Tab title={`Vídeos (${relatorio.Videos?.length || 0})`}>
              <Box gap="16px" direction="row" margin={{ top: '2rem' }}>
                {relatorio.Videos && relatorio.Videos.length > 0 ? (
                  <Box direction="column">
                    <Box gap="16px" direction="row" overflow="auto">
                      {relatorio.Videos &&
                        relatorio.Videos.map((item) => (
                          <VideoCard
                            key={item.Guid}
                            video={item}
                          />
                        ))}
                    </Box>
                    {/* <ProjetoRelatorioVideosTable videos={relatorio.Videos} /> */}
                  </Box>
                ) : (
                  <EmptyStateImage />
                )}
              </Box>
            </Tab>
            <Tab title={`Imagens (${imagens.length})`}>
              <Box gap="16px" direction="row" margin={{ top: '2rem' }}>
                {imagens.length > 0 ? (
                  <Box gap="16px" direction="row" overflow="auto">
                    {imagens.map((item): JSX.Element => (
                      <ImageCard
                        key={item.Guid}
                        image={item}
                      />
                    ))}
                  </Box>
                ) : (
                  <EmptyStateImage />
                )}
              </Box>
            </Tab>
            <Tab title={`RDOs (${rdo.length})`}>
              <Box gap="16px" direction="row" margin={{ top: '2rem' }}>
                {rdo.length > 0 ? (
                  <Box gap="16px" direction="row" overflow="auto">
                    {rdo.map((item) => (
                      <RDOCard
                        key={item.Guid}
                        documento={item}
                      />
                    ))}
                  </Box>
                ) : (
                  <EmptyStateImage />
                )}
              </Box>
            </Tab>
            <Tab title={`Rel. de Serviços (${relatorios.length})`}>
              <Box gap="16px" direction="row" margin={{ top: '2rem' }}>
                <Box flex align="center" justify="center" width="100%">
                  {relatorios.length > 0 ? (
                    <>
                      <ProjetoRelatorioDocumentosTable
                        documentos={relatorios}
                        refreshData={getInitialData}
                      />
                    </>
                  ) : (
                    <EmptyStateImage />
                  )}
                </Box>
              </Box>
            </Tab>
            <Tab title={`Documentos (${adicionais.length})`}>
              <Box gap="16px" direction="row" margin={{ top: '2rem' }}>
                {adicionais.length > 0 ? (
                  <>
                    <ProjetoRelatorioDocumentosTable
                      documentos={adicionais}
                      refreshData={getInitialData}
                    />
                  </>
                ) : (
                  <EmptyStateImage />
                )}
              </Box>
            </Tab>
          </Tabs>
          {showTecnicos && (
            <Layer
              position="center"
              onClickOutside={invertChangeTecnicos}
              onEsc={invertChangeTecnicos}
              style={{ borderRadius: '8px' }}
            >
              <Box pad="medium" width="auto" style={{ minWidth: '320px' }}>
                <Box
                  direction="row"
                  margin={{ bottom: '16px' }}
                  justify="between"
                  align="center"
                >
                  <Paragraph margin="0" style={{ fontWeight: 600 }}>
                    Lista de Técnicos:
                  </Paragraph>
                  <Button
                    onClick={invertChangeTecnicos}
                    icon={<FormClose fontSize="18px" />}
                  />
                </Box>
                <Box direction="column" overflow="auto">
                  {relatorio.Tecnicos &&
                    relatorio.Tecnicos.map((item) => (
                      <Paragraph
                        key={item.Guid}
                        margin={{ top: '4px', bottom: '4px' }}
                        fill
                      >
                        {'- ' + item.FirstName + ' ' + item.LastName!}
                      </Paragraph>
                    ))}
                </Box>
              </Box>
            </Layer>
          )}
          {showEquipamentos && (
            <Layer
              position="center"
              onClickOutside={invertChangeEquipamentos}
              onEsc={invertChangeEquipamentos}
              style={{ borderRadius: '8px' }}
            >
              <Box pad="medium" width="auto" style={{ minWidth: '320px' }}>
                <Box
                  direction="row"
                  margin={{ bottom: '16px' }}
                  justify="between"
                  align="center"
                >
                  <Paragraph margin="0" style={{ fontWeight: 600 }}>
                    Lista de Equipamentos:
                  </Paragraph>
                  <Button
                    onClick={invertChangeEquipamentos}
                    icon={<FormClose fontSize="18px" />}
                  />
                </Box>
                <Box direction="column" overflow="auto">
                  {relatorio.Equipamentos &&
                    relatorio.Equipamentos.map((item) => (
                      <Paragraph
                        key={item.Guid}
                        margin={{ top: '4px', bottom: '4px' }}
                        fill
                      >
                        {'- ' + item.Nome}
                      </Paragraph>
                    ))}
                </Box>
              </Box>
            </Layer>
          )}
        </>
      )}
    </PageWrapper>
  );
};

ManageProjetosRelatorio.displayName = 'ManageProjetosRelatorio';

export default ManageProjetosRelatorio;
