import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IUser } from '../../../../Interfaces/IUser';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import ManageUsersActions from './ManageUsersActions';
import ManageUsersTotalDocumentos from './ManageUsersTotalDocumentos';
import useAppContext from '../../Template/Context/useAppContext';

export interface ManageUsersTableProps {
  users: IUser[];
  refreshData: () => void;
  showAdmin?: boolean;
}

const ManageUsersTable: React.FC<ManageUsersTableProps> = ({ users, refreshData, showAdmin = false }): JSX.Element => {
  const {
    state: { Owner, Empresa },
  } = useAppContext();

  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Email',
    direction: 'asc',
  });

  const columns: ColumnConfig<IUser>[] = [
    {
      property: 'FirstName',
      primary: false,
      search: false,
      sortable: true,
      header: 'Nome',
      render: (datum: IUser): React.ReactNode => (
        <Text>{datum.FirstName}</Text>
      ),
    },
    {
      property: 'LastName',
      primary: false,
      search: false,
      sortable: true,
      header: 'Sobrenome',
      render: (datum: IUser): React.ReactNode => (
        <Text>{datum.LastName}</Text>
      ),
    },
    {
      property: 'Email',
      primary: false,
      search: true,
      sortable: true,
      header: 'Email',
      render: (datum: IUser): React.ReactNode => (
        <Text>{datum.Email}</Text>
      ),
    },
    {
      property: 'Technician',
      primary: false,
      search: false,
      sortable: true,
      header: 'Técnico',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Technician) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Active',
      primary: false,
      search: false,
      sortable: true,
      header: 'Ativo?',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Active) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Validated',
      primary: false,
      search: false,
      sortable: true,
      header: 'Validado?',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Validated) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'TotalDocumentos',
      primary: false,
      search: false,
      sortable: true,
      header: 'Documentos',
      render: (datum: IUser): React.ReactNode => (
        <ManageUsersTotalDocumentos
          usuario={datum}
        />
      ),
    },
    {
      property: 'Guid',
      primary: true,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IUser): React.ReactNode => (
        <ManageUsersActions
          user={datum}
          refreshData={refreshData}
          showAdmin={showAdmin}
        />
      ),
    },
  ];

  if (Owner) {
    // add a column to position 3 in columns array
    columns.splice(3, 0, {
      property: 'Owner',
      primary: false,
      search: false,
      sortable: true,
      header: 'Owner',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Owner) ? 'Sim' : 'Não'}</Text>
      ),
    });
  }

  if (showAdmin) {
    // add a column to position 3 in columns array
    columns.splice(4, 0, {
      property: 'Admin',
      primary: false,
      search: false,
      sortable: true,
      header: 'Admin?',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Admin) ? 'Sim' : 'Não'}</Text>
      ),
    });
  }

  if (Empresa && Empresa.Tipo && Empresa.Tipo === 2) {
    columns.splice(5, 0, {
      property: 'Plantas',
      primary: false,
      search: false,
      sortable: true,
      header: 'Plantas',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.TodasPlantas) ? 'Todas' : (datum.TotalPlantas || 0)}</Text>
      ),
    });
  }

  return (
    <DataTable
      fill="horizontal"
      data={users}
      border={{
        body: 'bottom',
      }}
      sortable={true}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'small',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ManageUsersTable.displayName = 'ManageUsersTable';

export default ManageUsersTable;
