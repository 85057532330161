import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from 'grommet';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import DocumentosForm from '../../../Forms/Documentos/DocumentosForm';
import adicionarDocumentoUsuarioInitialValues from '../../Helpers/adicionarDocumentoInitialValues';
import adicionarDocumentoUsuarioSubmit from '../../Helpers/adicionarDocumentoUsuarioSubmit';
import useAppContext from '../../../Template/Context/useAppContext';

export interface AdicionarDocumentoUsuarioProps {}

const AdicionarDocumentoUsuario: React.FC<AdicionarDocumentoUsuarioProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const {
    state: { Admin },
  } = useAppContext();

  const onCancel = (): void => {
    const url: string = (Admin) ? `/auth/manageUsuariosDocumentos/${guid}` : `/owner/manageUsuariosDocumentos/${guid}`;

    navigate(url);
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Documento"
      />
      <Box
        width="large"
      >
        <DocumentosForm
          initialValues={adicionarDocumentoUsuarioInitialValues(guid!)}
          onSubmit={adicionarDocumentoUsuarioSubmit}
          onCancel={onCancel}
          categoriaDocs={1}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarDocumentoUsuario.displayName = 'AdicionarDocumentoUsuario';

export default AdicionarDocumentoUsuario;
