import React from 'react';
import StatsBox from './Components/StatsBox/StatsBox';
import PageWrapper from '../PageWrapper/PageWrapper';
import GuideChart from './Components/GuideChart/GuideChart';
import BottomBarChart from './Components/BottomBarChart/BottomBarChart';
import RadarChart from './Components/RadarChart/RadarChart';

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = ({}): JSX.Element => {
  return (
    <PageWrapper>
    <StatsBox />
    <GuideChart />
    <RadarChart />
    <BottomBarChart />
    </PageWrapper>
  );
};

Dashboard.displayName = 'Dashboard';

export default Dashboard;
