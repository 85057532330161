import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';
import { IUser } from '../../../../Interfaces/IUser';

const onUploadCsv = async (file: Blob): Promise<IBasicReturn<IUser[]>> => {
  let newUpload: IBasicReturn<IUser[]> = {
    Success: true,
  };

  const formData = new FormData();
  formData.append('file', file);

  await MyAxiosInstance().post<IBasicReturn<IUser[]>>('Identity/UploadCsv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {},
  })
    .then((response): IBasicReturn<IUser[]> => {
      const { data } = response;
      newUpload = data;
      return newUpload;
    })
    .catch((): IBasicReturn => {
      newUpload.Success = false;
      return newUpload;
    });

  return newUpload;
};

export default onUploadCsv;
