import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'grommet';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import onChangeEmailInitialValues from './Helpers/onChangeEmailInitialValues';
import onChangeEmailSubmit from './Helpers/onChangeEmailSubmit';
import AlterarEmailForm from '../../Forms/AlterarEmail/AlterarEmailForm';
import useAppContext from '../../Template/Context/useAppContext';

export interface ChangeEmailProps { }

const ChangeEmail: React.FC<ChangeEmailProps> = (): JSX.Element => {
  const navigate = useNavigate();
  const onCancel = (): void => {
    navigate('/dashboard');
  };

  const {
    state: { Email },
  } = useAppContext();

  return (
    <PageWrapper>
      <PageTitle
        Text="Alterar Email"
      />
      <PageTitle
        Text={`Email atual: ${Email}`}
        level={4}
      />
      <Box
        width="medium"
      >
        <AlterarEmailForm
          initialValues={onChangeEmailInitialValues}
          onSubmit={onChangeEmailSubmit}
          onCancel={onCancel}
          emailAtual={Email || ''}
        />
      </Box>
    </PageWrapper>
  );
};

ChangeEmail.displayName = 'ChangeEmail';

export default ChangeEmail;
