import { BasicReturn, IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getEmpresaInfoByGuid = async (empresaGuid: string): Promise<IBasicReturn<IEmpresa>> => {
  let basicReturn: IBasicReturn<IEmpresa> = new BasicReturn(true);

  await MyAxiosInstance().get<IBasicReturn<IEmpresa>>(`Empresa/EmpresaInfoByGuid/${empresaGuid}`)
    .then((response): IBasicReturn<IEmpresa> => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = new BasicReturn(false);

      return basicReturn;
    });

  return basicReturn;
};

export default getEmpresaInfoByGuid;
