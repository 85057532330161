import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const projetoEquipamentoExcluir = async (projetoEquipamentoGuid: string): Promise<IBasicReturn> => {
  let apiResponse: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn>(`Empresa/ProjetoEquipamentoExcluir/${projetoEquipamentoGuid}`)
    .then((response): IBasicReturn => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default projetoEquipamentoExcluir;
