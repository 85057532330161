import React from 'react';
import {
  MediaAboutText,
  MediaCardContainer,
  MediaNameText,
} from './MediaCardStyles';
import { Box, Image } from 'grommet';

export interface MediaCardProps {
  preview?: string;
  name?: string;
  about?: string;
  onClick?: () => void;
}

const MediaCard: React.FC<MediaCardProps> = ({ about, name, preview, onClick }): JSX.Element => {
  return (
    <MediaCardContainer
      hoverIndicator={(onClick) ? true : false}
      onClick={onClick}
    >
      <Box alignSelf="center">
        <Image
          src={preview}
          alt="Midía para visualização"
          fill
          style={{
            width: '100%',
            justifySelf: 'center !important',
            alignSelf: 'center',
          }}
        />
      </Box>
      <MediaNameText>{name}</MediaNameText>
      <MediaAboutText>{about}</MediaAboutText>
    </MediaCardContainer>
  );
};

MediaCard.displayName = 'MediaCard';

export default MediaCard;
