import React, { useEffect, useState } from 'react';
import { Box, Paragraph } from 'grommet';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IVencimentos } from '../../../Interfaces/IVencimentos';
import getVencimentos from './Helpers/getVencimentos';
import Loading from '../Loading/Loading';
import VencimentosDocumentosEmpresa from './Components/DocumentosEmpresa/VencimentosDocumentosEmpresa';
import VencimentosDocumentosEquipamentos from './Components/DocumentosEquipamentos/VencimentosDocumentosEquipamentos';
import VencimentosDocumentosUsuarios from './Components/DocumentosUsuários/VencimentosDocumentosUsuarios';

export interface VencimentosProps {}

const Vencimentos: React.FC<VencimentosProps> = ({}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [vencimentos, setVencimentos] = useState<IVencimentos>({});

  const getData = async (): Promise<void> => {
    await getVencimentos()
      .then((data): void => {
        if (data.Success) {
          setVencimentos(data.Object!);
          setLoading(false);
        } else {
          toast.error('Erro ao buscar os vencimentos');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar os vencimentos');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Vencimentos" />
      </Box>
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur. 
        </Paragraph>
      </Box>
      <Box width="xlarge" fill="horizontal" gap="small" direction="column">
        {loading ? (
          <Loading />
        ) : (
          <>
            {
              (vencimentos.DocumentosDaEmpresa && vencimentos.DocumentosDaEmpresa.length > 0) && (
                <>
                  <PageTitle
                    size="22px"
                    textAlign="start"
                    margin={{ top: '2rem' }}
                    Text="Documentos da Empresa"
                  />
                  <VencimentosDocumentosEmpresa
                    documentos={vencimentos.DocumentosDaEmpresa}
                  />
                </>
              )
            }
            {
              (vencimentos.DocumentosDoEquipamento && vencimentos.DocumentosDoEquipamento.length > 0) && (
                <>
                  <PageTitle
                    size="22px"
                    textAlign="start"
                    margin={{ top: '4rem' }}
                    Text="Documentos dos Equipamentos"
                  />
                  <VencimentosDocumentosEquipamentos
                    documentos={vencimentos.DocumentosDoEquipamento}
                  />
                </>
              )
            }
            {
              (vencimentos.DocumentosDoUsuario && vencimentos.DocumentosDoUsuario.length > 0) && (
                <>
                  <PageTitle
                    size="22px"
                    textAlign="start"
                    margin={{ top: '4rem' }}
                    Text="Documentos dos Usuários"
                  />
                  <VencimentosDocumentosUsuarios
                    documentos={vencimentos.DocumentosDoUsuario}
                  />
                </>
              )
            }
          </>
        )}
      </Box>
    </PageWrapper>
  );
};

Vencimentos.displayName = 'Vencimentos';

export default Vencimentos;
