import React from 'react';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import { Box, Button, Paragraph } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';

export interface ErrorBoundaryFallbackProps {
  onReset?: () => void;
}

const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({ onReset }): JSX.Element => {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();
  const onClick = (): void => {
    if (onReset) onReset();
    resetBoundary();
    navigate('/');
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Desculpe, mas houve um erro"
      />
      <Paragraph fill>
        Não se preocupe, nossos técnicos já foram notificados.
      </Paragraph>
      <Box>
        <Button
          title="Voltar à Home Page"
          label="Voltar à Home Page"
          primary
          onClick={onClick}
        />
      </Box>
    </PageWrapper>
  );
};

ErrorBoundaryFallback.displayName = 'ErrorBoundaryFallback';

export default ErrorBoundaryFallback;
