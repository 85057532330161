import { Box, Button, NameValueList, NameValuePair, Text } from 'grommet';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import useAppContext from '../Template/Context/useAppContext';
import { Edit } from 'grommet-icons';

export interface ManageAccountProps {}

const ManageAccount: React.FC<ManageAccountProps> = ({}): JSX.Element => {
  const {
    state: { FirstName, LastName, Email },
  } = useAppContext();

  const navigate = useNavigate();

  // /* Usuário */
  const onAlterarSenhaClick = (): void => {
    navigate('/two-factor/alterarSenha');
  };

  const onAlterarNomeClick = (): void => {
    navigate('/two-factor/alterarNome');
  };

  const onAlterarEmailClick = (): void => {
    navigate('/two-factor/alterarEmail');
  };

  // const onAlterarAssinaturaClick = (): void => {
  //   navigate('/two-factor/alterarAssinatura');
  // };
  // /* /Usuário */

  return (
    <PageWrapper>
      <PageTitle
        Text="Gerenciar minha conta"
      />
      <Box
        width="large"
      >
        <NameValueList>
          <NameValuePair key="Nome" name="Nome">
            <Box
              direction="row"
              gap="small"
            >
              <Text>{`${FirstName} ${LastName}`}</Text>
              <Button
                title="Editar"
                icon={<Edit size="16px" />}
                onClick={onAlterarNomeClick}
              />
            </Box>
          </NameValuePair>
          <NameValuePair key="Email" name="Email">
            <Box
              direction="row"
              gap="small"
            >
              <Text>{`${Email}`}</Text>
              <Button
                title="Editar"
                icon={<Edit size="16px" />}
                onClick={onAlterarEmailClick}
              />
            </Box>
          </NameValuePair>
          <NameValuePair key="Senha" name="Senha">
            <Box
              direction="row"
              gap="small"
            >
              <Text>******</Text>
              <Button
                title="Editar"
                icon={<Edit size="16px" />}
                onClick={onAlterarSenhaClick}
              />
            </Box>
          </NameValuePair>
        </NameValueList>
      </Box>
    </PageWrapper>
  );
};

ManageAccount.displayName = 'ManageAccount';

export default ManageAccount;
