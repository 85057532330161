import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IProjetoDocumento } from '../../../../Interfaces/IProjetoDocumento';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import ProjetoRelatorioDocumentosActions from './ProjetoRelatorioDocumentosActions';
import CriadoPor from '../../CriadoPor/CriadoPor';

export interface ProjetoRelatorioDocumentosTableProps {
  documentos: IProjetoDocumento[];
  refreshData: () => void;
  completo?: boolean;
}

const ProjetoRelatorioDocumentosTable: React.FC<ProjetoRelatorioDocumentosTableProps> = ({ documentos, refreshData, completo = false }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Nome',
    direction: 'asc',
  });

  const columns: ColumnConfig<IProjetoDocumento>[] = [
    {
      property: 'Nome',
      primary: true,
      search: true,
      sortable: true,
      header: 'Nome',
      render: (datum: IProjetoDocumento): React.ReactNode => (
        <Text>{datum.Titulo}</Text>
      ),
    },
    {
      property: 'TipoDocumento',
      primary: true,
      search: true,
      sortable: true,
      header: 'Tipo',
      render: (datum: IProjetoDocumento): React.ReactNode => (
        <Text>{datum.Tipo!.Nome}</Text>
      ),
    },
    {
      property: 'Descricao',
      primary: true,
      search: true,
      sortable: true,
      header: 'Descrição',
      render: (datum: IProjetoDocumento): React.ReactNode => (
        <Text>{datum.Observacao}</Text>
      ),
    },
    {
      property: 'Created',
      primary: false,
      search: false,
      sortable: true,
      header: 'Criado em',
      render: (datum: IProjetoDocumento): React.ReactNode => (
        <Text>{datum.Created}</Text>
      ),
    },
    {
      property: 'CreatedBy',
      primary: false,
      search: false,
      sortable: true,
      header: 'Criado por',
      render: (datum: IProjetoDocumento): React.ReactNode => (
        <CriadoPor
          criadoPor={datum.CreatedBy!}
        />
      ),
    },
    {
      property: 'Guid',
      primary: false,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IProjetoDocumento): React.ReactNode => (
        <ProjetoRelatorioDocumentosActions
          documento={datum}
          refreshData={refreshData}
          completo={completo}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={documentos}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'small',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ProjetoRelatorioDocumentosTable.displayName = 'ProjetoRelatorioDocumentosTable';

export default ProjetoRelatorioDocumentosTable;
