import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IEquipamento } from '../../../Interfaces/IEquipamento';
import getDropDownEquipamentosPorEmpresa from './Helpers/getDropDownEquipamentosPorEmpresa';
import { FormField, SelectMultiple, Spinner } from 'grommet';

export interface DropDownEquipamentosPorEmpresaProps {
  name: string;
  label: string;
  title: string;
  disabled?: boolean;
  multiple?: boolean;
}

const DropDownEquipamentosPorEmpresa: React.FC<DropDownEquipamentosPorEmpresaProps> = ({
  name, label, title, disabled, multiple = false,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [equipamentos, setEquipamentos] = useState<IEquipamento[]>([]);

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await getDropDownEquipamentosPorEmpresa()
      .then((data): void => {
        if (data.Success) {
          setEquipamentos(data.Object!);
        } else {
          toast.error('Erro ao buscar equipamentos');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar equipamentos');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (loading) ? (<Spinner />) : (
    <FormField
      label={label}
      name={name}
      width="100%"
      required
    >
      <SelectMultiple
        name="EquipamentoGuid"
        aria-label={title}
        title={title}
        placeholder={label}
        options={equipamentos}
        valueKey={{ key: 'Guid', reduce: true }}
        labelKey="Nome"
        disabled={disabled}
        multiple={multiple}
        searchPlaceholder="Pesquisar"
        messages={{
          selected: '{selected} SELECIONADOS',
          clearAll: 'Limpar seleção',
          selectAll: 'Selecionar todos',
          summarizedValue: '{selected} de {total} selecionados',
          selectedOfTotal: '{selected} de {total} selecionados',
          showMore: 'Mostrar mais',
        }}
        showSelectedInline
      />
    </FormField>
  );
};

DropDownEquipamentosPorEmpresa.displayName = 'DropDownEquipamentosPorEmpresa';

export default DropDownEquipamentosPorEmpresa;
