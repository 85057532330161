import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { Box } from 'grommet';
import DocumentosForm from '../../../Forms/Documentos/DocumentosForm';
import adicionarMeuDocumentoInitialValues from '../../Helpers/adicionarMeuDocumentoInitialValues';
import adicionarMeuDocumentoSubmit from '../../Helpers/adicionarMeuDocumentoSubmit';

export interface AdicionarMeuDocumentoProps {}

const AdicionarMeuDocumento: React.FC<AdicionarMeuDocumentoProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onCancel = (): void => {
    navigate('/admin/meusDocumentos');
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Documento"
      />
      <Box
        width="large"
      >
        <DocumentosForm
          initialValues={adicionarMeuDocumentoInitialValues()}
          onSubmit={adicionarMeuDocumentoSubmit}
          onCancel={onCancel}
          categoriaDocs={1}
          esconderCategorias
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarMeuDocumento.displayName = 'AdicionarMeuDocumento';

export default AdicionarMeuDocumento;
