export interface IBasicReturn<T = {}> {
  Success: boolean;
  Message?: string;
  Details?: string;
  Code?: string;
  Url?: string;
  Object?: T;
}

export class BasicReturn implements IBasicReturn {
  Success: boolean;

  Code: string;

  Message: string;

  constructor(success: boolean, code?: string, message?: string) {
    this.Success = success;
    this.Code = code || '';
    this.Message = message || '';
  }
}
