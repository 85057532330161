import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IPagination } from '../../../../Interfaces/IPagination';
import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getVideos = async (page: IPagination): Promise<IBasicReturn<IPagination<IStreamingVideo[]>>> => {
  let apiResponse: IBasicReturn<IPagination<IStreamingVideo[]>> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPagination<IStreamingVideo[]>>>(`Streaming/Videos/${page.Page}/${page.PageSize}`)
    .then((response): IBasicReturn<IPagination<IStreamingVideo[]>> => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn<IPagination<IStreamingVideo[]>> => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default getVideos;
