import { Box, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { ChartTitle } from '../StatsBox/StatsBoxStiles';

export interface GuideChartProps {}

const GuideChart: React.FC<GuideChartProps> = ({}): JSX.Element => {
  const data = [
    {
      name: 'Janeiro',
      Vendas: 4000,
      Pedidos: 2400,
      amt: 2400,
    },
    {
      name: 'Fevereiro',
      Vendas: 3000,
      Pedidos: 1398,
      amt: 2210,
    },
    {
      name: 'Março',
      Vendas: 2000,
      Pedidos: 9800,
      amt: 2290,
    },
    {
      name: 'Abril',
      Vendas: 2780,
      Pedidos: 3908,
      amt: 2000,
    },
    {
      name: 'Maio',
      Vendas: 1890,
      Pedidos: 4800,
      amt: 2181,
    },
    {
      name: 'Junho',
      Vendas: 2390,
      Pedidos: 3800,
      amt: 2500,
    },
    {
      name: 'Julho',
      Vendas: 3490,
      Pedidos: 4300,
      amt: 2100,
    },
  ];

  const size = useContext(ResponsiveContext);

  return (
    <Box
      width={size === 'small' ? '100%' : '50%'}
      height={size === 'small' ? '300px' : '450px'}
    >
      <ChartTitle>Overview do ano vigente:</ChartTitle>
      <ResponsiveContainer>
        <LineChart
          width={900}
          height={450}
          data={data}
          margin={{
            top: 12,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" fontWeight={600} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="Pedidos"
            stroke="#8884d8"
            strokeDasharray="5 5"
          />
          <Line
            type="monotone"
            dataKey="Vendas"
            stroke="#82ca9d"
            strokeDasharray="3 4 5 2"
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

GuideChart.displayName = 'GuideChart';

export default GuideChart;
