import React, { useContext, useState } from 'react';
import {
  Box, Button, FormExtendedEvent, FormField, ResponsiveContext, TextInput, TextArea,
} from 'grommet';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';
import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';

export interface AddVideoFormProps {
  initialValues: IStreamingVideo;
  onSubmit: (values: IStreamingVideo) => Promise<IBasicReturn<IStreamingVideo>>;
  onCancel?: () => void;
  editing?: boolean;
}

const AddVideoForm: React.FC<AddVideoFormProps> = ({ initialValues, onSubmit, onCancel, editing = false }): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<IStreamingVideo>(initialValues);
  const size = useContext(ResponsiveContext);

  const { projetoGuid } = useParams<{ videoGuid: string, projetoGuid: string }>();

  const navigate = useNavigate();

  const onChange = (nextValues: IStreamingVideo): void => setValues(nextValues);

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IStreamingVideo>): Promise<void> => {
    const { value } = evt;
    setSubmitting(true);

    await onSubmit(value)
      .then((data): void => {
        if (data.Success) {
          toast.success('Vídeo salvo com sucesso');
          
          if (editing) {
            navigate(`/admin/manageProjetosVideos/${projetoGuid}`);
          } else {
            navigate(`/admin/videoUpload/${data.Object!.Guid}`);
          }
        } else {
          toast.error('Erro ao salvar vídeo');
          setSubmitting(false);
        }
      })
      .catch((): void => {
        toast.error('Erro ao salvar vídeo');
        setSubmitting(false);
      });
  };

  const validateTitle = (value: string): string | undefined => {
    if (value && (value.length < 5 || value.length > 100)) {
      return 'O título deve ter entre 5 e 100 caracteres';
    }
  };

  return (
    <FormWrapper values={values} onChange={onChange} onSubmit={handleSubmit}>
      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="end">
        <FormField
          label="Título"
          name="Title"
          width="100%"
          validate={validateTitle}
          required
        >
          <TextInput
            name="Title"
            aria-label="Por favor informe o título do vídeo"
            title="Por favor informe o título do vídeo"
            maxLength={98}
          />
        </FormField>
      </Box>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="end">
        <FormField
          label="Descrição"
          name="Description"
          width="100%"
        >
          <TextArea
            name="Description"
            aria-label="Por favor informe a descrição do vídeo"
            title="Por favor informe a descrição do vídeo"
            placeholder="Por favor informe a descrição do vídeo"
            rows={5}
            maxLength={990}
          />
        </FormField>
      </Box>

      <Box direction="row" gap="medium" justify="start" margin={{ top: 'medium', bottom: 'medium' }}>
        <Button type="submit" label="Salvar" disabled={submitting} primary reverse />
        <Button label="Cancelar" onClick={onClear} reverse />
      </Box>
    </FormWrapper>
  );
};

AddVideoForm.displayName = 'AddVideoForm';

export default AddVideoForm;
