import React, { useEffect, useState } from 'react';
import { Box, Button } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { Add } from 'grommet-icons';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IEmpresa } from '../../../Interfaces/IEmpresa';
import { IPagination, Pagination as PaginationClass } from '../../../Interfaces/IPagination';
import Loading from '../Loading/Loading';
import getEmpresas from './Helpers/getEmpresas';
import Pagination from '../Pagination/Pagination';
import PageSize from '../Pagination/Components/PageSize';
import totalPages from '../Pagination/Helpers/totalPages';
import ManageEmpresasTable from './Components/ManageEmpresasTable';
import PesquisarEmpresas from './Components/PesquisarEmpresas/PesquisarEmpresas';

export interface ManageEmpresasProps {}

const ManageEmpresas: React.FC<ManageEmpresasProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onAddEmpresaClick = (): void => {
    navigate('/owner/adicionarEmpresa');
  };

  const [empresas, setEmpresas] = useState<IPagination<IEmpresa[]>>(new PaginationClass(1, 30, 30, []));
  const [loading, setLoading] = useState<boolean>(true);

  const setData = (data: IPagination<IEmpresa[]>): void => {
    setEmpresas(data);
  };

  const initialPagination: IPagination<IEmpresa[]> = new PaginationClass(1, 30, 30);

  const getData = async (pagination: IPagination = initialPagination): Promise<void> => {
    setLoading(true);

    await getEmpresas(pagination)
      .then((data): void => {
        if (data.Success) {
          setEmpresas(data.Object!);
        } else {
          toast.error('Erro ao buscar empresas');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar empresas');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(parseInt(newPage, 10), empresas.PageSize);
    getData(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getData(newPagination);
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Gerenciar Empresas"
      />
      <Box
        width="xlarge"
        align="end"
      >
        <Button
          primary
          label="Adicionar Empresa"
          title="Adicionar Empresa"
          onClick={onAddEmpresaClick}
          icon={<Add />}
          reverse
        />
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
            }}
          >
            <PesquisarEmpresas
              refreshData={getData}
              setResults={setData}
            />
            <Pagination
              page={empresas.Page!}
              last={totalPages(empresas.Total || 0, empresas.PageSize!)}
              onChange={updatePage}
            />
            <ManageEmpresasTable
              empresas={empresas.Object || []}
              refreshData={getData}
            />
            <PageSize
              size={empresas.PageSize!}
              onChange={updatePageSize}
            />
            <Pagination
              page={empresas.Page!}
              last={totalPages(empresas.Total || 0, empresas.PageSize!)}
              onChange={updatePage}
            />
          </Box>
        )
      }
    </PageWrapper>
  );
};

ManageEmpresas.displayName = 'ManageEmpresas';

export default ManageEmpresas;
