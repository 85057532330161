import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEquipamentoDocumento } from '../../../../Interfaces/IEquipamentoDocumento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const editarEquipamentoDocumentoSubmit = async (values: IEquipamentoDocumento): Promise<IBasicReturn> => {
  let basicReturn: IBasicReturn<IEquipamentoDocumento> = { Success: false };

  await MyAxiosInstance().post<IBasicReturn<IEquipamentoDocumento>>('Empresa/EquipamentoDocumentoUpdateInfo', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = { Success: false };

      return basicReturn;
    });

  return basicReturn;
};

export default editarEquipamentoDocumentoSubmit;
