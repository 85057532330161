import React from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';

export interface ManageEmpresasUsuariosProps {}

const ManageEmpresasUsuarios: React.FC<ManageEmpresasUsuariosProps> = ({}): JSX.Element => {
  return (
    <PageWrapper>
      <PageTitle
        Text="Usuarios por empresa"
      />
    </PageWrapper>
  );
};

ManageEmpresasUsuarios.displayName = 'ManageEmpresasUsuarios';

export default ManageEmpresasUsuarios;
