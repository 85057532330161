import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import LoginPage from '../Login/LoginPage';
import PasswordRecovery from '../PasswordRecovery/PasswordRecovery';
import useTwoFactorRouter from './Components/useTwoFactorRouter';
import useIsAuthenticatedRouter from './Components/useIsAuthenticatedRouter';
import useIsOwnerRouter from './Components/useIsOwnerRouter';
import Dashboard from '../Dashboard/Dashboard';
import Error404 from '../Error404/Error404';
import Error403 from '../Error403/Error403';
import Error500 from '../Error500/Error500';
import useIsAdminRouter from './Components/useIsAdminRouter';
import AnalisarConvite from '../AnalisarConvite/AnalisarConvite';


export interface MainRouterProps {}

const MainRouter: React.FC<MainRouterProps> = ({}): JSX.Element => {
  const IsTwoFactorRouter = useTwoFactorRouter();
  const IsAuthenticatedRouter = useIsAuthenticatedRouter();
  const IsOwnerRoute = useIsOwnerRouter();
  const IsAdminRoute = useIsAdminRouter();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/passwordRecovery" element={<PasswordRecovery />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/404" element={<Error404 />} />
      <Route path="/403" element={<Error403 />} />
      <Route path="/500" element={<Error500 />} />
      <Route path="/analisarConvite/:guid" element={<AnalisarConvite />} />

      {IsTwoFactorRouter}
      {IsAuthenticatedRouter}
      {IsOwnerRoute}
      {IsAdminRoute}

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

MainRouter.displayName = 'MainRouter';

export default MainRouter;
