import {
  ButtonExtendedProps, Menu, ResponsiveContext,
} from 'grommet';
import {
  Alert, ContactInfo, Edit, FingerPrint, Logout, MailOption, User,
} from 'grommet-icons';
import { toast } from 'react-toastify';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppContext from '../Context/useAppContext';
import onLogout from './Helpers/onLogout';

export interface UserMenuOptionsProps {}

const UserMenuOptions: React.FC<UserMenuOptionsProps> = (): JSX.Element => {
  const size = useContext(ResponsiveContext);
  const navigate = useNavigate();

  const {
    state: { FirstName, LastName, Validated },
    dispatch,
  } = useAppContext();

  const logoutUser = async (): Promise<void> => {
    await onLogout()
      .then((): void => {
        dispatch({
          type: 'changeAuth',
          value: false,
        });

        dispatch({
          type: 'changeDrawer',
          value: false,
        });

        dispatch({
          type: 'changeFirstName',
          value: '',
        });

        dispatch({
          type: 'changeLastName',
          value: '',
        });

        dispatch({
          type: 'changeEmail',
          value: '',
        });

        dispatch({
          type: 'changeOwner',
          value: false,
        });

        dispatch({
          type: 'changeValidated',
          value: false,
        });

        navigate('/');
      })
      .catch((): void => {
        toast.error('Erro ao sair do sistema');
        navigate('/');
      });
  };

  const onAlterarSenhaClick = (): void => {
    navigate('/two-factor/alterarSenha');
  };

  const onAlterarNomeClick = (): void => {
    navigate('/two-factor/alterarNome');
  };

  const onAlterarEmailClick = (): void => {
    navigate('/two-factor/alterarEmail');
  };

  const onAlterarAssinaturaClick = (): void => {
    navigate('/two-factor/alterarAssinatura');
  };

  const menuItems: ButtonExtendedProps[][] = [
    [
      {
        label: 'Alterar assinatura',
        title: 'Alterar assinatura',
        icon: <Edit />,
        gap: 'small',
        onClick: onAlterarAssinaturaClick,
      },
      {
        label: 'Alterar email',
        title: 'Alterar email',
        icon: <MailOption />,
        gap: 'small',
        onClick: onAlterarEmailClick,
      },
      {
        label: 'Alterar nome',
        title: 'Alterar nome',
        icon: <ContactInfo />,
        gap: 'small',
        onClick: onAlterarNomeClick,
      },
      {
        label: 'Alterar senha',
        title: 'Alterar minha senha',
        icon: <FingerPrint />,
        gap: 'small',
        onClick: onAlterarSenhaClick,
      },
    ],
    [
      {
        label: 'Sair',
        title: 'Sair do sistema',
        icon: <Logout />,
        gap: 'small',
        onClick: logoutUser,
      },
    ],
  ];

  if (!Validated) {
    const validateClick = (): void => {
      navigate('/two-factor/validarEmail');
    };

    menuItems.unshift([
      {
        label: 'Validar conta',
        title: 'Validar conta de usuário',
        icon: <Alert color="status-critical" />,
        gap: 'small',
        onClick: validateClick,
      },
    ]);
  }

  return (
    <Menu
      dropProps={{
        align: { top: 'bottom', right: 'right' },
        elevation: 'xlarge',
        background: 'background-back',
        width: '200px',
      }}
      icon={(Validated) ? <User /> : <Alert color="status-critical" />}
      label={(size === 'small') ? '' : `${FirstName} ${LastName}`}
      items={menuItems}
    />
  );
};

UserMenuOptions.displayName = 'UserMenuOptions';

export default UserMenuOptions;
