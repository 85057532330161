import React from 'react';
import { Box, Button } from 'grommet';
import { IProjetoDocumento } from '../../../../Interfaces/IProjetoDocumento';
import ProjetoRelatorioDocumentoDownload from './ProjetoRelatorioDocumentoDownload';
import useAppContext from '../../Template/Context/useAppContext';
import { Trash } from 'grommet-icons';
import excluirDocumentoDoProjeto from '../../ManageProjetosDocumentos/Helpers/excluirDocumentoDoProjeto';
import { toast } from 'react-toastify';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';

export interface ProjetoRelatorioDocumentosActionsProps {
  documento: IProjetoDocumento;
  refreshData: () => void;
  completo?: boolean;
}

const ProjetoRelatorioDocumentosActions: React.FC<ProjetoRelatorioDocumentosActionsProps> = ({ documento, refreshData, completo }): JSX.Element => {
  const {
    state: { Admin },
  } = useAppContext();

  const [showExcluir, setShowExcluir] = React.useState<boolean>(false);
  const invertChangeExcluir = (): void => setShowExcluir(!showExcluir);

  const onExcluirDocumentoClick = (): void => {
    invertChangeExcluir();
  };

  const onExcluirDocumento = async (): Promise<void> => {
    await excluirDocumentoDoProjeto(documento.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Projeto excluído com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir projeto');
        }
        invertChangeExcluir();
      })
      .catch((): void => {
        toast.error('Erro ao excluir projeto');
        invertChangeExcluir();
      });
  };

  return (
    <Box
      direction="row"
      gap="1rem"
      pad="none"
    >
      <ProjetoRelatorioDocumentoDownload
        documento={documento}
      />
      {
        (Admin && completo) && (
          <Button
            icon={<Trash size='16px' />}
            title='Excluir Documento'
            onClick={onExcluirDocumentoClick}
          />
        )
      }
      {
        (showExcluir) && (
          <ConfirmModal
            title="Excluir Projeto"
            text="Deseja realmente excluir este documento?"
            onConfirm={onExcluirDocumento}
            onCancel={invertChangeExcluir}
          />
        )
      }
    </Box>
  );
};

ProjetoRelatorioDocumentosActions.displayName = 'ProjetoRelatorioDocumentosActions';

export default ProjetoRelatorioDocumentosActions;
