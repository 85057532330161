import { IUser } from '../../../../Interfaces/IUser';

const passwordRecoveryInitialValues: IUser = {
  Email: '',
  VerificationCode: '',
  Guid: '',
  Password: '',
  ConfirmPassword: '',
};

export default passwordRecoveryInitialValues;
