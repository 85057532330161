import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { Add, Attachment } from 'grommet-icons';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import useAppContext from '../../Template/Context/useAppContext';

export interface ManageEquipamentosTotalDocumentosProps {
  equipamento: IEquipamento;
}

const ManageEquipamentosTotalDocumentos: React.FC<ManageEquipamentosTotalDocumentosProps> = ({ equipamento }): JSX.Element => {
  const {
    state: { Admin },
  } = useAppContext();

  const navigate = useNavigate();

  const onDocumentosClick = (): void => {
    if (Admin) {
      navigate(`/admin/manageEquipamentosDocumentos/${equipamento.Guid}`);
    } else {
      navigate(`/owner/manageEquipamentosDocumentos/${equipamento.Guid}`);
    }
  };

  const onNavigateToAddDocumento = (): void => {
    if (Admin) {
      navigate(`/admin/adicionarDocumentoEquipamento/${equipamento.Guid}`);
    } else {
      navigate(`/owner/adicionarDocumentoEquipamento/${equipamento.Guid}`);
    }
  };

  return (
    <Box
      direction="row"
      gap="1rem"
      align="center"
    >
      <Button 
      size='xsmall'
      style={{ minWidth: '80px' }}
      secondary
      icon={<Attachment size="12px" />}
      title="Clique para ver os documentos da empresa"
        onClick={onDocumentosClick}
        label={equipamento.TotalDocumentos || '0'}
      />
      <Button size='small' icon={<Add size='14px' />} hoverIndicator onClick={onNavigateToAddDocumento} />
      {/* to remove after done aproved rebrand 
      <Anchor
        title="Clique para ver os documentos da empresa"
        onClick={onDocumentosClick}
      >
        {equipamento.TotalDocumentos || 0}
      </Anchor>
      <Button
        title="Adicionar documento"
        icon={<Add size="12px" />}
        onClick={onNavigateToAddDocumento}
      /> */}
    </Box>
  );
};

ManageEquipamentosTotalDocumentos.displayName = 'ManageEquipamentosTotalDocumentos';

export default ManageEquipamentosTotalDocumentos;
