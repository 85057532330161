import { Box, Select, Text } from 'grommet';
import React from 'react';

export interface PageSizeProps {
  size: number;
  onChange: (page: string) => void;
}

const PageSize: React.FC<PageSizeProps> = ({ size, onChange }): JSX.Element => {
  const onChangeOption = (option: { option: string }): void => {
    onChange(option.option);
  };
  return (
    <Box
      fill="horizontal"
      justify="end"
      align="center"
      pad="small"
      direction="row"
      gap="small"
      height={{
        min: '50px',
      }}
    >
      <Text>
        Itens por página:
      </Text>
      <Select
        options={['30', '50', '100']}
        value={size.toString()}
        onChange={onChangeOption}
        a11yTitle="Selecione o tamanho da página"
        size="small"
      />
    </Box>
  );
};

PageSize.displayName = 'PageSize';

export default PageSize;
