import { BasicReturn, IBasicReturn } from '../../../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../../../utils/AxiosInstance';

const getUserInfoByGuid = async (userGuid: string): Promise<IBasicReturn<IUser>> => {
  let basicReturn: IBasicReturn<IUser> = new BasicReturn(true);

  await MyAxiosInstance().get<IBasicReturn<IUser>>(`Identity/UserInfoByGuid/${userGuid}`)
    .then((response): IBasicReturn<IUser> => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = new BasicReturn(false);

      return basicReturn;
    });

  return basicReturn;
};

export default getUserInfoByGuid;
