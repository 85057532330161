import { IUpload } from '../../../../Interfaces/IUpload';

const importCsvInitialValues: IUpload = {
  UploadInput: '',
  UploadName: '',
  UploadExtension: '',
  UploadDescription: '',
  UploadMimeType: '',
  UploadBase64: '',
  TotalChunks: 0,
  UploadSize: 0,
};

export default importCsvInitialValues;
