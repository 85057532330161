import React, { useState } from 'react';
import { IUser } from '../../../../Interfaces/IUser';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import { ColumnConfig, DataTable, Text } from 'grommet';

export interface ImportCsvDataTableProps {
  users: IUser[];
}

const ImportCsvDataTable: React.FC<ImportCsvDataTableProps> = ({ users }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Email',
    direction: 'asc',
  });

  const columns: ColumnConfig<IUser>[] = [
    {
      property: 'Email',
      primary: true,
      search: true,
      header: 'Email',
      sortable: true,
    },
    {
      property: 'LastName',
      header: 'Last Name',
      search: true,
      sortable: true,
    },
    {
      property: 'FirstName',
      header: 'First Name',
      search: true,
      sortable: true,
    },
    {
      property: 'Admin',
      header: 'Admin',
      search: false,
      sortable: true,
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Admin) ? 'Yes' : 'No'}</Text>
      ),
    },
    {
      property: 'ConsumesLicense',
      header: 'Consumes License',
      search: false,
      sortable: true,
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.ConsumesLicense) ? 'Yes' : 'No'}</Text>
      ),
    },
  ];

  return (
    <DataTable
      data={users}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={true}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ImportCsvDataTable.displayName = 'ImportCsvDataTable';

export default ImportCsvDataTable;
