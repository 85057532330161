import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import { Edit, Trash } from 'grommet-icons';
import { toast } from 'react-toastify';
import onChangeActivePropertyForEquipamentos from '../Helpers/onChangeActivePropertyForEquipamentos';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import deleteEquipamentos from '../Helpers/deleteEquipamentos';
import useAppContext from '../../Template/Context/useAppContext';

export interface ManageEquipamentosActionsProps {
  equipamento: IEquipamento;
  refreshData: () => void;
}

const ManageEquipamentosActions: React.FC<ManageEquipamentosActionsProps> = ({ equipamento, refreshData }): JSX.Element => {
  const {
    state: { Admin },
  } = useAppContext();

  const [action, setAction] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const changeShowModal = (): void => {
    setShowModal(!showModal);
  };

  const navigate = useNavigate();

  const changeActiveProp = async (): Promise<void> => {
    await onChangeActivePropertyForEquipamentos(equipamento.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Propriedade ATIVO do equipamento alterada com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao alterar a propriedade ATIVO do equipamento');
        }
      })
      .catch((): void => {
        toast.error('Erro ao alterar a propriedade ATIVO do equipamento');
      });
  };

  // const onChangeActiveClick = (): void => {
  //   setAction('changeActive');
  //   changeShowModal();
  // };

  const confirmModalTitle = (): string => {
    switch (action) {
      case 'changeActive':
        return 'Alterar propriedade ATIVO?';
      case 'delete':
        return 'Excluir equipamento?';
      default:
        return '';
    }
  };

  const confirmModalText = (): string => {
    switch (action) {
      case 'changeActive':
        return `O equipamento ${equipamento.Nome} está ${equipamento.Ativo ? 'ATIVO' : 'INATIVO'}. Deseja alterar?`;
      case 'delete':
        return `Deseja excluir permanentemente o equipamento ${equipamento.Nome}?`;
      default:
        return '';
    }
  };

  const onEditEquipamentoClick = (): void => {
    if (Admin) {
      navigate(`/admin/editarEquipamento/${equipamento.Guid}`);
    } else {
      navigate(`/owner/editarEquipamento/${equipamento.Guid}`);
    }
  };

  const onDeleteEquipamentos = async (): Promise<void> => {
    await deleteEquipamentos(equipamento.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Equipamento excluído com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir o equipamento');
        }
      })
      .catch((): void => {
        toast.error('Erro ao excluir o equipamento');
      });
  };

  const onDeleteClick = (): void => {
    setAction('delete');
    changeShowModal();
  };

  const onConfirm = (): void => {
    switch (action) {
      case 'changeActive':
        changeActiveProp();
        break;
      case 'delete':
        onDeleteEquipamentos();
        break;
      default:
        break;
    }

    changeShowModal();
  };

  return (
    <>
      <Box
        direction="row"
        gap="1rem"
        pad="none"
      >
        <Button
          icon={<Edit size='16px' />}
          onClick={onEditEquipamentoClick}
        />
        {/* <Button
          icon={<Checkmark />}
          title="Alterar a propriedade 'ATIVO' do equipamento"
          onClick={onChangeActiveClick}
        /> */}
        <Button
          icon={<Trash size='16px' />}
          onClick={onDeleteClick}
          title="Excluir equipamento"
        />
      </Box>
      {
        (showModal) && (
          <ConfirmModal
            title={confirmModalTitle()}
            text={confirmModalText()}
            onCancel={changeShowModal}
            onConfirm={onConfirm}
          />
        )
      }
    </>
  );
};

ManageEquipamentosActions.displayName = 'ManageEquipamentosActions';

export default ManageEquipamentosActions;
