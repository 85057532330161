import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const editarProjetoSubmit = async (values: IProjeto): Promise<IBasicReturn> => {
  let apiResponse: IBasicReturn = {
    Success: true,
  };

  const empresa: IEmpresa = {
    Guid: values.EmpresaGuid,
  };

  const planta: IPlanta = {
    Guid: values.PlantaGuid,
  };

  const dataToSubmit = {
    ...values,
    Empresa: empresa,
    Planta: planta,
  };

  await MyAxiosInstance().post<IBasicReturn>('Empresa/EditarProjeto', dataToSubmit)
    .then((response): IBasicReturn => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default editarProjetoSubmit;
