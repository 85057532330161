import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import { IPagination } from '../../../../Interfaces/IPagination';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getEmpresas = async (page: IPagination): Promise<IBasicReturn<IPagination<IEmpresa[]>>> => {
  let users: IBasicReturn<IPagination<IEmpresa[]>> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPagination<IEmpresa[]>>>(`Empresa/Empresas/${page.Page}/${page.PageSize}`)
    .then((response): IBasicReturn<IPagination<IEmpresa[]>> => {
      const { data } = response;

      users = data;

      return users;
    })
    .catch((): IBasicReturn<IPagination<IEmpresa[]>> => {
      users.Success = false;

      return users;
    });

  return users;
};

export default getEmpresas;
