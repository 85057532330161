import React, { useState } from 'react';
import { Box, Button } from 'grommet';
import { Trash } from 'grommet-icons';
import { toast } from 'react-toastify';
import { IUser } from '../../../../../Interfaces/IUser';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import excluirConvite from '../Helpers/excluirConvite';

export interface UsuariosConvidadosActionsProps {
  usuario: IUser;
  refreshData: () => void;
}

const UsuariosConvidadosActions: React.FC<UsuariosConvidadosActionsProps> = ({ usuario, refreshData }): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const changeShowModal = (): void => {
    setShowModal(!showModal);
  };

  const onExcluirConvite = async (): Promise<void> => {
    await excluirConvite(usuario.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Convite excluído com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir convite');
        }
      })
      .catch((): void => {
        toast.error('Erro ao excluir convite');
      });
  };

  return (
    <>
      <Box
        direction="row"
        gap="1rem"
        pad="none"
      >
        <Button
          icon={<Trash size='16px' color="status-critical" />}
          title="excluir Usuário"
          onClick={changeShowModal}
        />
      </Box>
      {
        (showModal) && (
          <ConfirmModal
            title="Deseja excluir o convite?"
            text="Esta ação é irreversível. Tem certeza que deseja excluir o convite?"
            onCancel={changeShowModal}
            onConfirm={onExcluirConvite}
          />
        )
      }
    </>
  );
};

UsuariosConvidadosActions.displayName = 'UsuariosConvidadosActions';

export default UsuariosConvidadosActions;
