import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAppContext from '../../Template/Context/useAppContext';
import PageWrapper from '../../PageWrapper/PageWrapper';
import Loading from '../../Loading/Loading';
import onCheckingIsAuthenticated from '../Helpers/onCheckingIsAuthenticated';

export interface IsAuthenticatedAuthProps {}

const IsAuthenticatedAuth: React.FC<IsAuthenticatedAuthProps> = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    state: { IsAuthenticated },
    dispatch,
  } = useAppContext();

  const onError = (): void => {
    if (IsAuthenticated) {
      dispatch({
        type: 'changeAuth',
        value: false,
      });
    }
    toast.error('Desculpe, mas não foi possível verificar a autenticação');
    navigate(`/login?RedirectUrl=${location.pathname}`);
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [validated, setValidated] = useState<boolean>(false);
  const changeValidated = (): void => setValidated(true);

  const startValidateWithAuthentication = async (): Promise<void> => {
    await onCheckingIsAuthenticated()
      .then((data): void => {
        if (data.Success && data.Object) {
          setLoading(false);
          changeValidated();
        } else {
          onError();
        }
      })
      .catch((): void => {
        onError();
      })
    ;
  };

  useEffect((): void => {
    if (!IsAuthenticated) {
      navigate(`/login?RedirectUrl=${location.pathname}`);
      return;
    }

    startValidateWithAuthentication();
  }, []);

  return (loading) ? (
    <PageWrapper>
      <Loading />
    </PageWrapper>
  ) : (
    <>{ (validated) && <Outlet /> }</>
  );
};

IsAuthenticatedAuth.displayName = 'IsAuthenticatedAuth';

export default IsAuthenticatedAuth;
