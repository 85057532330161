import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, div#root {
    display: flex;
    place-items: center;
    margin: 0;
    padding: 0;
    min-height: 100%;
    width: 100%;
  }
`;

GlobalStyle.displayName = 'GlobalStyle';

export default GlobalStyle;
