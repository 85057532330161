import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ITipoEquipamento } from '../../../Interfaces/ITipoEquipamento';
import getDropDownTiposDeEquipamentos from './Helpers/getDropDownTiposDeEquipamentos';
import { FormField, Select, Spinner } from 'grommet';

export interface DropDownTiposDeEquipamentosProps {
  name: string;
  label: string;
  title: string;
  disabled?: boolean;
}

const DropDownTiposDeEquipamentos: React.FC<DropDownTiposDeEquipamentosProps> = ({
  name, label, title, disabled,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tiposDeEquipamentos, setTiposDeEquipamentos] = useState<ITipoEquipamento[]>([]);

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await getDropDownTiposDeEquipamentos()
      .then((data): void => {
        if (data.Success) {
          setTiposDeEquipamentos(data.Object!);
        } else {
          toast.error('Erro ao buscar tipos de equipamentos');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar tipos de equipamentos');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (loading) ? (<Spinner />) : (
    <FormField
      label={label}
      name={name}
      width="100%"
      // validate={validate}
      required
    >
      <Select
        name={name}
        aria-label={title}
        title={title}
        placeholder={label}
        options={tiposDeEquipamentos}
        valueKey={{ key: 'Guid', reduce: true }}
        labelKey="Nome"
        disabled={disabled}
      />
    </FormField>
  );
};

DropDownTiposDeEquipamentos.displayName = 'DropDownTiposDeEquipamentos';

export default DropDownTiposDeEquipamentos;
