import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { ITipoEquipamento } from '../../../../Interfaces/ITipoEquipamento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getDropDownTiposDeEquipamentos = async (): Promise<IBasicReturn<ITipoEquipamento[]>> => {
  let apiReturn: IBasicReturn<ITipoEquipamento[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<ITipoEquipamento[]>>('Empresa/DropDownTiposDeEquipamentos')
    .then((response): IBasicReturn<ITipoEquipamento[]> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn<ITipoEquipamento[]> => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default getDropDownTiposDeEquipamentos;
