import {
  Box, Button, FormExtendedEvent, FormField, TextInput,
} from 'grommet';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../../Interfaces/IUser';
import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import useAppContext from '../../Template/Context/useAppContext';
import { toast } from 'react-toastify';
import FormWrapper from '../../FormWrapper/FormWrapper';

export interface AlterarNomeFormProps {
  initialValues: IUser;
  onSubmit: (values: IUser) => Promise<IBasicReturn<boolean>>;
  onCancel: () => void;
}

const AlterarNomeForm: React.FC<AlterarNomeFormProps> = ({ initialValues, onSubmit, onCancel }): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState(initialValues);
  const onChange = (newValues: IUser): void => {
    setValues(newValues);
  };

  const navigate = useNavigate();

  const onGoLogin = (): void => {
    navigate('/login');
  };

  const {
    state: { },
    dispatch,
  } = useAppContext();

  const leavePage = (): void => {
    dispatch({
      type: 'changeAuth',
      value: false,
    });

    dispatch({
      type: 'changeDrawer',
      value: false,
    });

    dispatch({
      type: 'changeFirstName',
      value: '',
    });

    dispatch({
      type: 'changeLastName',
      value: '',
    });

    dispatch({
      type: 'changeEmail',
      value: '',
    });

    dispatch({
      type: 'changeOwner',
      value: false,
    });

    dispatch({
      type: 'changeValidated',
      value: false,
    });

    onGoLogin();
  };

  const handleSubmit = async (evt: FormExtendedEvent<IUser>): Promise<void> => {
    const { value } = evt;
    setSubmitting(true);

    if (value.FirstName === initialValues.FirstName && value.LastName === initialValues.LastName) {
      toast.success('Nome alterado com sucesso');
      navigate('/dashboard');
      return;
    }

    await onSubmit(value)
      .then((data): void => {
        if (data.Success && data.Object) {
          toast.success('Nome alterado com sucesso');
          leavePage();
        } else {
          toast.error('Erro ao alterar o nome. Provavelmente a senha está errada');
          setSubmitting(false);
        }
      })
      .catch((): void => {
        setSubmitting(false);
        toast.error('Erro ao alterar o nome');
      })
    ;
  };

  const validatePassword = (value: string): string | undefined => {
    if (value.length < 6) {
      return 'A senha deve ter no mínimo 6 caracteres';
    }
    if (value.length > 15) {
      return 'A senha deve ter no máximo 15 caracteres';
    }
  };

  const validateNames = (value: string): string | undefined => {
    if (value.length < 3) {
      return 'O nome deve ter no mínimo 3 caracteres';
    }
  };

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <FormField
        label="Senha Atual"
        name="Password"
        width="100%"
        validate={validatePassword}
        required
      >
        <TextInput
          name="Password"
          type="password"
          aria-label="Por favor informe sua senha atual"
          title="Por favor informe sua senha atual"
          placeholder="******"
        />
      </FormField>

      <FormField
        label="Nome"
        name="FirstName"
        width="100%"
        validate={validateNames}
        required
      >
        <TextInput
          name="FirstName"
          aria-label="Por favor informe seu nome"
          title="Por favor informe seu nome"
          placeholder="Nome"
        />
      </FormField>

      <FormField
        label="Sobrenome"
        name="LastName"
        width="100%"
        validate={validateNames}
        required
      >
        <TextInput
          name="LastName"
          aria-label="Por favor informe seu sobrenome"
          title="Por favor informe seu sobrenome"
          placeholder="Sobrenome"
        />
      </FormField>

      <Box
        direction="row"
        gap="small"
        justify="end"
        margin={{
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <Button
          type="submit"
          label="Salvar"
          title="Salvar"
          disabled={submitting}
          primary
          reverse
        />
        <Button
          type="button"
          label="Cancelar"
          title="Cancelar"
          disabled={submitting}
          onClick={onCancel}
          secondary
        />
      </Box>
    </FormWrapper>
  );
};

AlterarNomeForm.displayName = 'AlterarNomeForm';

export default AlterarNomeForm;
