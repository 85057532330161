import { IBasicReturn } from '../../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../../utils/AxiosInstance';

const getConvidadosDaMinhaEmpresa = async (): Promise<IBasicReturn<IUser[]>> => {
  let users: IBasicReturn<IUser[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IUser[]>>('Empresa/UsuariosConvidadosDaMinhaEmpresa')
    .then((response): IBasicReturn<IUser[]> => {
      const { data } = response;

      users = data;

      return users;
    })
    .catch((): IBasicReturn<IUser[]> => {
      users.Success = false;

      return users;
    });

  return users;
};

export default getConvidadosDaMinhaEmpresa;
