import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import { IPagination } from '../../../../Interfaces/IPagination';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const pesquisarMeusDocumentos = async (searchTerm: string): Promise<IBasicReturn<IPagination<IEmpresaDocumento[]>>> => {
  let documentos: IBasicReturn<IPagination<IEmpresaDocumento[]>> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IPagination<IEmpresaDocumento[]>>>(`Empresa/PesquisarMeusDocumentos/${searchTerm}`)
    .then((response): IBasicReturn<IPagination<IEmpresaDocumento[]>> => {
      const { data } = response;

      documentos = data;

      return documentos;
    })
    .catch((): IBasicReturn<IPagination<IEmpresaDocumento[]>> => {
      documentos.Success = false;

      return documentos;
    });

  return documentos;
};

export default pesquisarMeusDocumentos;
