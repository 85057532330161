import { Box, Button, Paragraph } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IPagination, Pagination as PaginationClass } from '../../../Interfaces/IPagination';
import { IUsuarioDocumento } from '../../../Interfaces/IUsuarioDocumento';
import getUsuariosDocumentos from './Helpers/getUsuariosDocumentos';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import totalPages from '../Pagination/Helpers/totalPages';
import PageSize from '../Pagination/Components/PageSize';
import ManageUsuariosDocumentosTable from './Components/ManageUsuariosDocumentosTable';
import PesquisarUsuariosDocumentos from './Components/PesquisarUsuariosDocumentos/PesquisarUsuariosDocumentos';
import useAppContext from '../Template/Context/useAppContext';

export interface ManageUsuariosDocumentosProps {}

const ManageUsuariosDocumentos: React.FC<ManageUsuariosDocumentosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const {
    state: { Admin },
  } = useAppContext();

  const onAddDocumentolick = (): void => {
    const url: string = (Admin) ? `/auth/adicionarDocumentoUsuario/${guid}` : `/owner/adicionarDocumentoUsuario/${guid}`;

    navigate(url);
  };

  const [documentos, setDocumentos] = useState<IPagination<IUsuarioDocumento[]>>(new PaginationClass(1, 30, 30, []));
  const [loading, setLoading] = useState<boolean>(true);

  const setData = (data: IPagination<IUsuarioDocumento[]>): void => {
    setDocumentos(data);
  };

  const initialPagination: IPagination<IUsuarioDocumento[]> = new PaginationClass(1, 30, 30);

  const getData = async (pagination: IPagination = initialPagination): Promise<void> => {
    setLoading(true);

    await getUsuariosDocumentos(guid!, pagination)
      .then((data): void => {
        if (data.Success) {
          setDocumentos(data.Object!);
        } else {
          toast.error('Erro ao buscar documentos da empresa');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar documentos da empresas');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(parseInt(newPage, 10), documentos.PageSize);
    getData(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getData(newPagination);
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
        <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Gerenciar Documentos de Usuários" />
        <Button
          primary
          label="Adicionar"
          title="Adicionar Documento"
          onClick={onAddDocumentolick}
          icon={<Add size="16px" />}
        />
      </Box>
      <Box
        alignSelf='start'
        align="start"
        width="60%"
      >

      <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae ante
        semper, faucibus urna quis, bibendum urna. Suspendisse consequat luctus
        dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam facilisis
        hendrerit quis non orci. Duis vel sodales dui. Suspendisse potenti.
        Donec dignissim est aliquet risus fermentum consectetur. 
      </Paragraph>
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
            }}
          >
            <PesquisarUsuariosDocumentos
              refreshData={getData}
              setResults={setData}
              Guid={guid!}
            />
            <ManageUsuariosDocumentosTable
              documentos={documentos.Object || []}
              refreshData={getData}
            />
            <PageSize
              size={documentos.PageSize!}
              onChange={updatePageSize}
            />
            <Pagination
              page={documentos.Page!}
              last={totalPages(documentos.Total || 0, documentos.PageSize!)}
              onChange={updatePage}
            />
          </Box>
        )
      }
    </PageWrapper>
  );
};

ManageUsuariosDocumentos.displayName = 'ManageUsuariosDocumentos';

export default ManageUsuariosDocumentos;
