import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getProjetoTecnicos = async (projetoGuid: string): Promise<IBasicReturn<IUser[]>> => {
  let apiResponse: IBasicReturn<IUser[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IUser[]>>(`Empresa/TecnicosPorProjeto/${projetoGuid}`)
    .then((response): IBasicReturn<IUser[]> => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn<IUser[]> => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default getProjetoTecnicos;
