import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Paragraph, Tab, Tabs } from 'grommet';
import { Add } from 'grommet-icons';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IPagination, Pagination as PaginationClass } from '../../../Interfaces/IPagination';
import { IProjeto } from '../../../Interfaces/IProjeto';
import getProjetos from './Helpers/getProjetos';
import Loading from '../Loading/Loading';
import PesquisarEmpresas from '../ManageEmpresas/Components/PesquisarEmpresas/PesquisarEmpresas';
import ProjetoTabContent from './Components/ProjetoTab/ProjetoTabContent';

export interface ManageProjetosProps {}

const ManageProjetos: React.FC<ManageProjetosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onAddProjetoClick = (): void => {
    navigate('/admin/projetosAdicionar');
  };

  const [projetos, setProjetos] = useState<IPagination<IProjeto[]>>(
    new PaginationClass(1, 30, 30, []),
  );
  const [loading, setLoading] = useState<boolean>(true);

  const setData = (data: IPagination<IProjeto[]>): void => {
    setProjetos(data);
  };

  const initialPagination: IPagination<IProjeto[]> = new PaginationClass(1, 30, 30);

  const getData = async (pagination: IPagination = initialPagination, status: number = 1): Promise<void> => {
    setLoading(true);

    await getProjetos(pagination, status)
      .then((data): void => {
        if (data.Success) {
          setProjetos(data.Object!);
        } else {
          toast.error('Erro ao buscar empresas');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar empresas');
      });
  };

  const [activeTab, setActiveTab] = useState<number>(0);
  const onUpdateTab = (index: number): void => {
    setActiveTab(index);
  };

  useEffect((): void => {
    getData(initialPagination, activeTab + 1);
  }, [activeTab]);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Projetos" />
        <Button
          primary
          label="Adicionar"
          title="Adicionar Projeto"
          onClick={onAddProjetoClick}
          icon={<Add size="16px" />}
        />
      </Box>
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur.
        </Paragraph>
      </Box>
      {
        (loading) ? (
          <Loading />
        ) : (
          <>
            <Box
              fill="horizontal"
              direction="column"
              gap="none"
              margin={{
                top: 'medium',
              }}
            >
              <PesquisarEmpresas refreshData={getData} setResults={setData} />

              <Tabs
                justify="start"
                margin={{
                  top: '-46px',
                }}
                activeIndex={activeTab}
                onActive={onUpdateTab}
              >
                <Tab title="Não iniciados">
                  <ProjetoTabContent
                    projetos={projetos.Object || []}
                    refreshData={getData}
                  />
                </Tab>
                <Tab title="Em andamento">
                  <ProjetoTabContent
                    projetos={projetos.Object || []}
                    refreshData={getData}
                  />
                </Tab>
                <Tab title="Concluídos">
                  <ProjetoTabContent
                    projetos={projetos.Object || []}
                    refreshData={getData}
                  />
                </Tab>
              </Tabs>
            </Box>
          </>
        )}
    </PageWrapper>
  );
};

ManageProjetos.displayName = 'ManageProjetos';

export default ManageProjetos;
