import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, NameValueList, NameValuePair, Paragraph } from 'grommet';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IUser } from '../../../Interfaces/IUser';
import getConviteInfo from './Helpers/getConviteInfo';
import Loading from '../Loading/Loading';
import useAppContext from '../Template/Context/useAppContext';
import onLogout from '../Template/Header/Helpers/onLogout';
import aceitarConvite from './Helpers/aceitarConvite';

export interface AnalisarConviteProps {}

const AnalisarConvite: React.FC<AnalisarConviteProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const {
    state: { IsAuthenticated },
    dispatch,
  } = useAppContext();

  const [convite, setConvite] = useState<IUser>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await getConviteInfo(guid!)
      .then((data): void => {
        if (data.Success) {
          setConvite(data.Object!);
          setLoading(false);
        } else {
          toast.error('Este convite não existe ou já expirou');
          navigate('/login');
        }
      })
      .catch((): void => {
        toast.error('Este convite não existe ou já expirou');
        navigate('/login');
      });
  };

  const logoutUser = async (): Promise<void> => {
    await onLogout()
      .then((): void => {
        dispatch({
          type: 'changeAuth',
          value: false,
        });

        dispatch({
          type: 'changeDrawer',
          value: false,
        });

        dispatch({
          type: 'changeFirstName',
          value: '',
        });

        dispatch({
          type: 'changeLastName',
          value: '',
        });

        dispatch({
          type: 'changeEmail',
          value: '',
        });

        dispatch({
          type: 'changeOwner',
          value: false,
        });

        dispatch({
          type: 'changeValidated',
          value: false,
        });

        getInitialData();
      })
      .catch((): void => {
        toast.error('Erro ao sair do sistema');
        navigate('/');
      });
  };

  const onVoltarClick = (): void => {
    navigate('/');
  };

  useEffect((): void => {
    if (IsAuthenticated) {
      logoutUser();
    } else {
      getInitialData();
    }
  }, []);

  const onAceitarConvite = async (): Promise<void> => {
    setSubmitting(true);

    await aceitarConvite(guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Convite aceito com sucesso');
          navigate('/login');
        } else {
          toast.error(
            'Erro ao aceitar o convite. Talvez este convite tenha sido aceito ou cancelado',
          );
          setSubmitting(false);
        }
      })
      .catch((): void => {
        toast.error(
          'Erro ao aceitar o convite. Talvez este convite tenha sido aceito ou cancelado',
        );
        setSubmitting(false);
      });
  };

  return (
    <PageWrapper>
      <PageTitle
        margin={{ top: '3rem' }}
        alignSelf="start"
        Text="Deseja ingressar na empresa?"
      />
      <Box
        alignSelf="start"
        align="start"
        width="60%"
        margin={{
          bottom: 'medium',
        }}
      >
        <Paragraph>
          Você foi convidado para ingressar em uma empresa que já utiliza o
          sistema Sinapse Flow. Caso aceite o convite, você terá acesso a todas
          as funcionalidades da empresa. Porém, caso já tenha acesso a outra
          empresa ou seja um proprietário do sistema, o acesso atual será
          substituído. Na página de login será necessário utilizar a recuperação
          de senha para o primeiro acesso.
        </Paragraph>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <Box width="large" fill="horizontal" gap="medium">
          <Box style={{ border: '1px solid #716F8A', borderRadius: '12px', padding: '20px', width: 'min-content' }}>
            <NameValueList>
              <NameValuePair key="NomeFantasia" name="Empresa">
                {convite.Empresa?.NomeFantasia}
              </NameValuePair>
              <NameValuePair key="Responsável" name="Responsável Técnico">
                {convite.Empresa?.ResponsavelTecnico}
              </NameValuePair>
              <NameValuePair key="Email" name="E-mail">
                {convite.Empresa?.Email}
              </NameValuePair>
              <NameValuePair key="Telefone" name="Telefone">
                {convite.Empresa?.Telefone}
              </NameValuePair>
            </NameValueList>
          </Box>
          <Box
            direction="row"
            gap="medium"
            justify="start"
            margin={{
              top: 'medium',
              bottom: 'medium',
            }}
          >
            <Button
              label="Aceitar"
              primary
              onClick={onAceitarConvite}
              disabled={submitting}
            />
            <Button
              secondary
              label="Voltar ao início"
              onClick={onVoltarClick}
              disabled={submitting}
            />
          </Box>
        </Box>
      )}
    </PageWrapper>
  );
};

AnalisarConvite.displayName = 'AnalisarConvite';

export default AnalisarConvite;
