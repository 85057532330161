'use client';

import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from './Components/ErrorBoundaryFallback';
import { CustomError } from '../../../Interfaces/IError';
import saveError from './Helpers/saveError';

export interface ErrorBoundaryProps {
  children: JSX.Element;
}

const AppErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }): JSX.Element => {
  const onSaveError = async (error: Error, info: React.ErrorInfo): Promise<void> => {
    const errorToReport = new CustomError(
      error.name,
      error.message,
      error.stack || '',
      info.componentStack || '',
    );

    await saveError(errorToReport);
  };
  
  const onError = (error: Error, info: React.ErrorInfo): void => {
    onSaveError(error, info);
  };

  return (
    <ErrorBoundary
      fallback={<ErrorBoundaryFallback />}
      onError={onError}
    >
      {children}
    </ErrorBoundary>
  );
};

AppErrorBoundary.displayName = 'AppErrorBoundary';

export default AppErrorBoundary;
