import React from 'react';
import { Anchor, Box } from 'grommet';
import PageWrapper from '../PageWrapper/PageWrapper';
import LoginForm from '../Forms/Login/LoginForm';
import loginInitialValues from './Helpers/loginInitialValues';
import onLoginSubmit from './Helpers/onLoginSubmit';
import { useNavigate } from 'react-router-dom';

export interface LoginPageProps { }

const LoginPage: React.FC<LoginPageProps> = ({ }): JSX.Element => {
  const navigate = useNavigate();

  const onRecuperarSenhaClick = (): void => {
    navigate('/passwordRecovery');
  };

  return (
    <PageWrapper>
      <Box
        width="medium"
        alignSelf="center"
        pad="medium"
        gap="medium"
        direction="column"
      >
        <Box
          border="bottom"
        >
          <LoginForm
            initialValues={loginInitialValues}
            onSubmit={onLoginSubmit}
          />
        </Box>
        <Box
          align="center"
        >
          <Anchor
            label="Esqueceu a senha?"
            title="Esqueceu a senha?"
            onClick={onRecuperarSenhaClick}
          />
        </Box>
      </Box>
    </PageWrapper>
  );
};

LoginPage.displayName = 'LoginPage';

export default LoginPage;
