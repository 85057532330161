import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'grommet';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import PlantaForm from '../../../Forms/Planta/PlantaForm';
import adicionarPlantaNaMinhaEmpresaValues from '../Helpers/adicionarPlantaNaMinhaEmpresaValues';
import adicionarPlantaNaMinhaEmpresaSubmit from '../Helpers/adicionarPlantaNaMinhaEmpresaSubmit';

export interface AdicionarPlantaNaMinhaEmpresaProps {}

const AdicionarPlantaNaMinhaEmpresa: React.FC<AdicionarPlantaNaMinhaEmpresaProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onCancel = (): void => {
    navigate('/admin/plantas');
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Planta"
      />
      <Box
        width="large"
      >
        <PlantaForm
          initialValues={adicionarPlantaNaMinhaEmpresaValues()}
          onSubmit={adicionarPlantaNaMinhaEmpresaSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarPlantaNaMinhaEmpresa.displayName = 'AdicionarPlantaNaMinhaEmpresa';

export default AdicionarPlantaNaMinhaEmpresa;
