import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import ManageEmpresasActions from './ManageEmpresasActions';
import ManageEmpresasTotalPlantas from './ManageEmpresasTotalPlantas';
import ManageEmpresasTotalDocumentos from './ManageEmpresasTotalDocumentos';
import ManageEmpresasTotalUsuarios from './ManageEmpresasTotalUsuarios';

export interface ManageEmpresasTableProps {
  empresas: IEmpresa[];
  refreshData: () => void;
}

const ManageEmpresasTable: React.FC<ManageEmpresasTableProps> = ({ empresas, refreshData }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'RazaoSocial',
    direction: 'asc',
  });

  const columns: ColumnConfig<IEmpresa>[] = [
    {
      property: 'CNPJ',
      primary: true,
      search: true,
      sortable: true,
      header: 'CNPJ',
      render: (datum: IEmpresa): React.ReactNode => (
        <Text>{datum.CNPJ}</Text>
      ),
    },
    {
      property: 'RazaoSocial',
      primary: false,
      search: true,
      sortable: true,
      header: 'Razão Social',
      render: (datum: IEmpresa): React.ReactNode => (
        <Text>{datum.RazaoSocial}</Text>
      ),
    },
    {
      property: 'NomeFantasia',
      primary: false,
      search: true,
      sortable: true,
      header: 'Nome Fantasia',
      render: (datum: IEmpresa): React.ReactNode => (
        <Text>{datum.NomeFantasia}</Text>
      ),
    },
    {
      property: 'Ativo',
      primary: false,
      search: false,
      sortable: true,
      header: 'Ativo?',
      render: (datum: IEmpresa): React.ReactNode => (
        <Text>{(datum.Ativo) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'TotalPlantas',
      primary: false,
      search: false,
      sortable: true,
      header: 'Plantas',
      render: (datum: IEmpresa): React.ReactNode => (
        <ManageEmpresasTotalPlantas
          empresa={datum}
        />
      ),
    },
    {
      property: 'TotalDocumentos',
      primary: false,
      search: false,
      sortable: true,
      header: 'Documentos',
      render: (datum: IEmpresa): React.ReactNode => (
        <ManageEmpresasTotalDocumentos
          empresa={datum}
        />
      ),
    },
    {
      property: 'TotalUsuarios',
      primary: false,
      search: false,
      sortable: true,
      header: 'Usuários',
      render: (datum: IEmpresa): React.ReactNode => (
        <ManageEmpresasTotalUsuarios
          empresa={datum}
        />
      ),
    },
    {
      property: 'Guid',
      primary: false,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IEmpresa): React.ReactNode => (
        <ManageEmpresasActions
          empresa={datum}
          refreshData={refreshData}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={empresas}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ManageEmpresasTable.displayName = 'ManageEmpresasTable';

export default ManageEmpresasTable;
