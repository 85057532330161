import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IUser } from '../../../Interfaces/IUser';
import getProjetoTecnicos from './Helpers/getProjetoTecnicos';
import Loading from '../Loading/Loading';
import { Box, Button, Paragraph } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import ProjetoTecnicosDataTable from './Components/ProjetoTecnicosDataTable';

export interface ManageProjetoTecnicosProps {}

const ManageProjetoTecnicos: React.FC<ManageProjetoTecnicosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onBackToProjetos = (): void => {
    navigate('/admin/projetos');
  };

  const [tecnicos, setTecnicos] = useState<IUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async (): Promise<void> => {
    setLoading(true);

    await getProjetoTecnicos(guid!)
      .then((data): void => {
        if (data.Success) {
          setTecnicos(data.Object!);
        } else {
          toast.error('Erro ao buscar técnicos do projeto');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar técnicos do projeto');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
       <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Técnicos do Projeto" />
       
      </Box>
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur. 
        </Paragraph>
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
              bottom: 'medium',
            }}
          >
            <ProjetoTecnicosDataTable
              tecnicos={tecnicos}
              refreshData={getData}
              completo
            />
          </Box>
        )
      }

<Box
        width="100%"
        align="center"
        direction="row"
        gap="xsmall"
        justify="start"
      >
        <Button
          primary
          label="Voltar"
          title="Voltar"
          onClick={onBackToProjetos}
          icon={<LinkPrevious  size="16px"/>}
        />
      </Box>
    </PageWrapper>
  );
};

ManageProjetoTecnicos.displayName = 'ManageProjetoTecnicos';

export default ManageProjetoTecnicos;
