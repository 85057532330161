import React, { useContext } from 'react';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { ChartTitle } from '../StatsBox/StatsBoxStiles';
import { Box, ResponsiveContext } from 'grommet';

export interface BottomBarChartProps {}

const BottomBarChart: React.FC<BottomBarChartProps> = ({}): JSX.Element => {
  const data = [
    {
      name: 'Máquinas',
      Metas: 4000,
      Realizadas: 2400,
      amt: 2400,
    },
    {
      name: 'Bombas',
      Metas: 3000,
      Realizadas: 1398,
      amt: 2210,
    },
    {
      name: 'Pedidos',
      Metas: 2000,
      Realizadas: 9800,
      amt: 2290,
    },
    {
      name: 'Perdas',
      Metas: 2780,
      Realizadas: 3908,
      amt: 2000,
    },
    {
      name: 'Revisões',
      Metas: 1890,
      Realizadas: 4800,
      amt: 2181,
    },
  ];

  const size = useContext(ResponsiveContext);

  return (
    <Box
      width={size === 'small' ? '100%' : '50%'}
      height={size === 'small' ? '300px' : '450px'}
    >
      <ChartTitle>Resultado acumulado:</ChartTitle>
      <ResponsiveContainer>
        <BarChart
          width={900}
          height={450}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="Metas"
            fill="#82ca9d"
            activeBar={<Rectangle fill="#82ca9d" />}
          />
          <Bar
            dataKey="Realizadas"
            fill="#8884d8"
            activeBar={<Rectangle fill="#8884d8" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

BottomBarChart.displayName = 'BottomBarChart';

export default BottomBarChart;
