import {
  Box, Button, DateInput, FormExtendedEvent, FormField, ResponsiveContext, Select, TextArea, TextInput,
} from 'grommet';
import React, { useContext } from 'react';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';
import DropDownEmpresas from '../../DropDownEmpresas/DropDownEmpresas';
import DropDownPlantas from '../../DropDownPlantas/DropDownPlantas';
import DropDownEquipamentosPorEmpresa from '../../DropDownEquipamentosPorEmpresa/DropDownEquipamentosPorEmpresa';
import DropDownTecnicosPorEmpresa from '../../DropDownTecnicosPorEmpresa/DropDownTecnicosPorEmpresa';
  
export interface ProjetoFormProps {
  initialValues: IProjeto;
  onSubmit: (values: IProjeto) => void;
  submitting: boolean;
  onCancel?: () => void;
}
  
const ProjetoForm: React.FC<ProjetoFormProps> = ({ initialValues, onSubmit, onCancel, submitting }): JSX.Element => {
  const [values, setValues] = React.useState<IProjeto>(initialValues);
  const onChange = (nextValues: IProjeto): void => setValues(nextValues);

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IProjeto>): Promise<void> => {
    onSubmit(evt.value);
  };

  const size = useContext(ResponsiveContext);

  const onShoreChange = (value: boolean): void => {
    setValues({ ...values, OnShore: value });
  };

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <Box
        direction={ (size === 'small') ? 'column' : 'row' }
        gap="small"
        justify="end"
      >
        <FormField
          label="Título do Projeto"
          name="Titulo"
          width="100%"
          required
        >
          <TextInput
            name="Titulo"
            aria-label="Por favor informe o Título do Projeto"
            title="Por favor informe o Título do Projeto"
            maxLength={98}
          />
        </FormField>

        <FormField
          label="Número do Projeto"
          name="Numero"
          width="100%"
          required
        >
          <TextInput
            name="Numero"
            aria-label="Por favor informe o Número do Projeto"
            title="Por favor informe o Número do Projeto"
            maxLength={98}
          />
        </FormField>
      </Box>

      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="end"
      >
          <FormField
          label="Escopo"
          name="Escopo"
          width="100%"
          required
        >
          <TextArea
            name="Escopo"
            aria-label="Por favor informe o Escopo do Projeto"
            title="Por favor informe o Escopo do Projeto"
            maxLength={998}
            rows={5}
          />
        </FormField>
      </Box>

      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="end"
      >
        <DropDownEmpresas
          name="EmpresaGuid"
          label="Empresa"
          title="Por favor selecione o cliente"
          disabled={submitting}
        />

        <DropDownPlantas
          name="PlantaGuid"
          label="Planta"
          title="Por favor selecione a planta"
          empresaGuid={values.EmpresaGuid || ''}
          disabled={submitting}
        />
      </Box>

      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="end"
      >
        <DropDownEquipamentosPorEmpresa
          name="EquipamentoGuid"
          label="Equipamentos"
          title="Por favor selecione os equipamentos"
          disabled={submitting}
          multiple
        />

        <DropDownTecnicosPorEmpresa
          name="TecnicoGuid"
          label="Técnicos"
          title="Por favor selecione os técnicos"
          disabled={submitting}
          multiple
        />
      </Box>

      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="end"
      >
        <FormField
          label="Previsão"
          name="Previsao"
          width="100%"
        >
        <TextInput
          name="Previsao"
          aria-label="Por favor informe a previsão para execução do projeto"
          title="Por favor informe a previsão para execução do projeto"
          type="number"
        />
        </FormField>

        <FormField
          label="Previsão"
          name="PrevisaoUnidade"
          width="100%"
        >
          <Select
            options={['Horas', 'Dias', 'Meses']}
            name="PrevisaoUnidade"
          />
        </FormField>
      </Box>

      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="end"
      >
        <FormField
          label="Previsão de Início"
          name="PrevisaoInicio"
          width="100%"
        >
          <DateInput
            name="PrevisaoInicio"
            format="dd/mm/yyyy"
          />
        </FormField>

        <FormField
          label="Previsão de Término"
          name="PrevisaoTermino"
          width="100%"
        >
          <DateInput
            name="PrevisaoTermino"
            format="dd/mm/yyyy"
          />
        </FormField>
      </Box>

      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="end"
      >
        <FormField
          label="Local de Atendimento"
          name="LocalAtendimento"
          width="100%"
        >
          <TextInput
            name="LocalAtendimento"
            aria-label="Por favor informe o Local de Atendimento"
            title="Por favor informe o Local de Atendimento"
            maxLength={98}
          />
        </FormField>

        <InterdevSwitch
          activeLabel="OnShore"
          inactiveLabel="OffShore"
          value={values.OnShore || false}
          onChange={onShoreChange}
          name="OnShore"
          title="Alterar o Tipo de Serviço"
          text="Tipo de Serviço"
        />
      </Box>

      <Box
        direction={(size === 'small') ? 'column' : 'row'}
        gap="small"
        justify="end"
      >
        <FormField
          label="Observações"
          name="Observacoes"
          width="100%"
        >
          <TextArea
            name="Observacoes"
            aria-label="Por favor informe as Observações"
            title="Por favor informe as Observações"
            rows={5}
            maxLength={998}
          />
        </FormField>
      </Box>
  
        <Box
          direction="row"
          gap="medium"
          justify="start"
          margin={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <Button
            type="submit"
            label="Salvar"
            title="Salvar"
            disabled={submitting}
            primary
            reverse
          />
          <Button
            label="Cancelar"
            title="Cancelar"
            onClick={onClear}
            reverse
          />
        </Box>
      </FormWrapper>
  );
};
  
ProjetoForm.displayName = 'ProjetoForm';
  
export default ProjetoForm;
  