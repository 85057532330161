import React, { useState } from 'react';
import { Box, Button } from 'grommet';
import { StopFill } from 'grommet-icons';
import { IProjeto } from '../../../../../Interfaces/IProjeto';
import projetoFinalizar from '../../Helpers/projetoFinalizar';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';

export interface ProjetoFinalizarProps {
  projeto: IProjeto;
  refreshData: () => void;
  disabled?: boolean;
}

const ProjetoFinalizar: React.FC<ProjetoFinalizarProps> = ({ projeto, refreshData, disabled = false }): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(disabled);
  const changeShowModal = (): void => setShowModal(!showModal);

  const onFinalizarProjetoClick = (): void => {
    changeShowModal();
  };

  const onFinalizarProjeto = async (): Promise<void> => {
    setLoading(true);

    await projetoFinalizar(projeto.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Projeto finalizado com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao finalizar projeto');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao finalizar projeto');
        setLoading(false);
      });
  };

  return (
    <>
      <Box round="full" pad="small" background="#DBDEFD">
        <Button
          size="small"
          title="Finalizar Projeto"
          onClick={onFinalizarProjetoClick}
          disabled={disabled || loading}
          icon={<StopFill size="14px" />}
        />
      </Box>
      {showModal && (
        <ConfirmModal
          title="Finalizar Projeto"
          text="Deseja realmente finalizar o projeto?"
          onCancel={changeShowModal}
          onConfirm={onFinalizarProjeto}
        />
      )}
    </>
  );
};

ProjetoFinalizar.displayName = 'ProjetoFinalizar';

export default ProjetoFinalizar;
