import { IUser } from '../../../../../Interfaces/IUser';

const onChangeNameInitialValues = (firstName: string, lastName: string): IUser => {
  return {
    Password: '',
    FirstName: firstName,
    LastName: lastName,
  };
};

export default onChangeNameInitialValues;
