import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getProjetoRelatorio = async (projetoGuid: string): Promise<IBasicReturn<IProjeto>> => {
  let apiResponse: IBasicReturn<IProjeto> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IProjeto>>(`Empresa/ProjetoRelatorio/${projetoGuid}`)
    .then((response): IBasicReturn<IProjeto> => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn<IProjeto> => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default getProjetoRelatorio;
