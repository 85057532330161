import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Paragraph } from 'grommet';
import { Add } from 'grommet-icons';
import { toast } from 'react-toastify';
import {
  IPagination,
  Pagination as PaginationClass,
} from '../../../../../Interfaces/IPagination';
import { IEquipamento } from '../../../../../Interfaces/IEquipamento';
import getEquipamentosPorEmpresa from '../../Helpers/getEquipamentosPorEmpresa';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import Loading from '../../../Loading/Loading';
import PesquisarEquipamentos from '../PesquisarEquipamento/PesquisarEquipamentos';
import Pagination from '../../../Pagination/Pagination';
import totalPages from '../../../Pagination/Helpers/totalPages';
import ManageEquipamentosDataTable from '../ManageEquipamentosDataTable';
import PageSize from '../../../Pagination/Components/PageSize';

export interface EquipamentosPorEmpresaProps {}

const EquipamentosPorEmpresa: React.FC<
EquipamentosPorEmpresaProps
> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onAddEquipamentolick = (): void => {
    navigate('/admin/adicionarEquipamento');
  };

  const [equipamentos, setEquipamentos] = useState<IPagination<IEquipamento[]>>(
    new PaginationClass(1, 30, 30, []),
  );
  const [loading, setLoading] = useState<boolean>(true);

  const setData = (data: IPagination<IEquipamento[]>): void => {
    setEquipamentos(data);
  };

  const initialPagination: IPagination<IEquipamento[]> = new PaginationClass(
    1,
    30,
    30,
  );

  const getData = async (
    pagination: IPagination = initialPagination,
  ): Promise<void> => {
    setLoading(true);

    await getEquipamentosPorEmpresa(pagination)
      .then((data): void => {
        if (data.Success) {
          setEquipamentos(data.Object!);
        } else {
          toast.error('Erro ao buscar equipamentos');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar equipamentos');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(
      parseInt(newPage, 10),
      equipamentos.PageSize,
    );
    getData(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getData(newPagination);
  };

  useEffect((): void => {
    getData();
  }, []);
  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Equipamentos" />
        <Button
          primary
          label="Adicionar"
          title="Adicionar Equipamento"
          onClick={onAddEquipamentolick}
          icon={<Add size="16px" />}
        />
      </Box>
      <Box
        alignSelf='start'
        align="start"
        width="60%"
      >

      <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae ante
        semper, faucibus urna quis, bibendum urna. Suspendisse consequat luctus
        dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam facilisis
        hendrerit quis non orci. Duis vel sodales dui. Suspendisse potenti.
        Donec dignissim est aliquet risus fermentum consectetur. 
      </Paragraph>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <Box
          fill="horizontal"
          direction="column"
          gap="none"
          margin={{
            top: 'medium',
          }}
        >
          <PesquisarEquipamentos
            refreshData={getData}
            setResults={setData}
          />

          <ManageEquipamentosDataTable
            equipamentos={equipamentos.Object || []}
            refreshData={getData}
          />

          <Box
            fill="horizontal"
            justify="center"
            align="center"
            direction="row"
            gap="none"
            margin="0"
            flex
          >
            <Pagination
              page={equipamentos.Page!}
              last={totalPages(equipamentos.Total || 0, equipamentos.PageSize!)}
              onChange={updatePage}
            />
            <PageSize size={equipamentos.PageSize!} onChange={updatePageSize} />
          </Box>
        </Box>
      )}
    </PageWrapper>
  );
};

EquipamentosPorEmpresa.displayName = 'EquipamentosPorEmpresa';

export default EquipamentosPorEmpresa;
