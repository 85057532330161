import { Box, Image } from 'grommet';
import React from 'react';

export interface EmptyStateImageProps {}

const EmptyStateImage: React.FC<EmptyStateImageProps> = ({}): JSX.Element => {
  return (
    <Box flex align="center" justify="center" width="100%">
      <Image
        src="/EmptyStateResult.png"
        alt="Sem resultados na busca"
        style={{
          maxWidth: '40%',
          justifySelf: 'center !important',
          marginTop: '2rem',
        }}
      />
    </Box>
  );
};

EmptyStateImage.displayName = 'EmptyStateImage';

export default EmptyStateImage;
