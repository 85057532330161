import { IBasicReturn } from '../../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../../utils/AxiosInstance';

const onChangeEmailSubmit = async (values: IUser): Promise<IBasicReturn<boolean>> => {
  let result: IBasicReturn<boolean> = {
    Success: false,
  };

  await MyAxiosInstance().post<IBasicReturn<boolean>>('Identity/ChangeEmail', values)
    .then((response): IBasicReturn<boolean> => {
      const { data } = response;
      result = data;

      return result;
    })
    .catch((): IBasicReturn<boolean> => {
      result.Success = false;

      return result;
    });

  return result;
};

export default onChangeEmailSubmit;
