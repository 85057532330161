import { Box } from 'grommet';
import { Alert as AlertIcon } from 'grommet-icons';
import React from 'react';

export interface AlertProps {
  text: string;
}

const Alert: React.FC<AlertProps> = ({ text }): JSX.Element => {
  return (
    <Box
      background="status-warning"
      direction="row"
      pad="small"
      gap="medium"
      align="center"
      fill="horizontal"
      margin={{
        bottom: '2rem',
      }}
    >
      <Box><AlertIcon size="medium" color='#000' /></Box>
      <Box>{text}</Box>
    </Box>
  );
};

Alert.displayName = 'Alert';

export default Alert;
