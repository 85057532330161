import { Box, Text } from 'grommet';
import styled from 'styled-components';

export const MediaAboutText = styled(Text)`
  font-size: 12px;
  color: #ADACC1;
  line-height: 1.35;
  margin: 0;
  align-self: start;
  font-weight: 600;

  @media only screen and (max-width: 768px) {
  }
`;

export const MediaNameText = styled(Text)`
  font-size: 20px;
  color: #122136;
  line-height: 1.35;
  margin: 16px 0;

  @media only screen and (max-width: 768px) {
  }
`;

export const MediaCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 396px;
  height: 258px;

  :hover {
    border: 1px #4954E8 solid;
  }

  @media only screen and (max-width: 768px) {
  }
`;