import React from 'react';
import { IProjeto } from '../../../../../Interfaces/IProjeto';
import { Box, Image } from 'grommet';
import ProjetoCard from '../ProjetoCard/ProjetosCard';

export interface ProjetoTabContentProps {
  projetos: IProjeto[];
  refreshData: () => void;
}

const ProjetoTabContent: React.FC<ProjetoTabContentProps> = ({ projetos, refreshData }): JSX.Element => {
  return (projetos.length === 0) ? (
    <Box gap="16px" direction="row" margin={{ top: '2rem' }}>
      <Box flex align="center" justify="center" width="100%">
        <Image
          src="/EmptyStateResult.png"
          alt="Sem resultados na busca"
          style={{
            maxWidth: '40%',
            justifySelf: 'center !important',
            marginTop: '2rem',
          }}
        />
      </Box>
    </Box>
  ) : (
    <Box
      margin={{ top: '2rem' }}
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '8px',
      }}
    >
      {
        projetos.map((item) => (
          <ProjetoCard key={item.Guid} refreshData={refreshData} projeto={item} />
        ))
      }
    </Box>
  );
};

ProjetoTabContent.displayName = 'ProjetoTabContent';

export default ProjetoTabContent;
