import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IProjetoDocumento } from '../../../../Interfaces/IProjetoDocumento';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getDocumentosDoProjeto = async (projetoGuid: string): Promise<IBasicReturn<IProjetoDocumento[]>> => {
  let apiResponse: IBasicReturn<IProjetoDocumento[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IProjetoDocumento[]>>(`Empresa/ProjetoDocumentos/${projetoGuid}`)
    .then((response): IBasicReturn<IProjetoDocumento[]> => {
      const { data } = response;

      apiResponse = data;

      return apiResponse;
    })
    .catch((): IBasicReturn<IProjetoDocumento[]> => {
      apiResponse.Success = false;

      return apiResponse;
    });

  return apiResponse;
};

export default getDocumentosDoProjeto;
