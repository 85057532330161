import { Box, Button, Text } from 'grommet';
import React from 'react';
import { Edit, FormDown, Logout } from 'grommet-icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useAppContext from '../../../Context/useAppContext';
import ProfileCardBox, {
  ArrowIconButton, ProfileCardButtonsBox, ProfileCardRole,
} from './Styles/ProfileCardButtonWithStyles';
import onLogout from '../../../Header/Helpers/onLogout';

export interface ProfileCardProps {}

const ProfileCard: React.FC<ProfileCardProps> = ({}): JSX.Element => {
  const {
    state: { FirstName, LastName, Owner, Admin, Technician },
    dispatch,
  } = useAppContext();

  const returnUserRole = (): string => {
    if (Owner) {
      return 'Proprietário';
    }

    if (Admin) {
      return 'Administrador';
    }

    if (Technician) {
      return 'Técnico';
    }

    return 'Usuário';
  };

  const navigate = useNavigate();

  const logoutUser = async (): Promise<void> => {
    await onLogout()
      .then((): void => {
        dispatch({
          type: 'changeAuth',
          value: false,
        });

        dispatch({
          type: 'changeDrawer',
          value: false,
        });

        dispatch({
          type: 'changeFirstName',
          value: '',
        });

        dispatch({
          type: 'changeLastName',
          value: '',
        });

        dispatch({
          type: 'changeEmail',
          value: '',
        });

        dispatch({
          type: 'changeOwner',
          value: false,
        });

        dispatch({
          type: 'changeValidated',
          value: false,
        });

        navigate('/');
      })
      .catch((): void => {
        toast.error('Erro ao sair do sistema');
        navigate('/');
      });
  };

  // /* Usuário */
  const onManageAccountClick = (): void => {
    navigate('/auth/minhaConta');
  };
  // /* /Usuário */

  return (
    <ProfileCardBox
      direction="column"
      background="#5D30A4"
      round="8px"
      elevation="small"
      width="small"
    >
      <Box flex direction="row" justify="between" align="center">
        <Text>{`${FirstName} ${LastName}`}</Text>

        <ArrowIconButton
          gap="xsmall"
          icon={<FormDown size="14px" color="#FFFFFF" />}
          onClick={() => {}}
        />
      </Box>
      <ProfileCardRole>{returnUserRole()}</ProfileCardRole>
      <ProfileCardButtonsBox
        direction="row"
        align="center"
        justify="between"
        width="100%"
        margin={{
          top: '24px',
        }}
      >
        <Button
          gap="xsmall"
          label="Editar"
          style={{ color: 'white', fontSize: '14px' }}
          icon={<Edit size="14px" color="#FFFFFF" />}
          onClick={onManageAccountClick}
        />
        <Button
          gap="xsmall"
          label="Sair"
          style={{ color: 'white', fontSize: '14px' }}
          icon={<Logout size="14px" color="#FFFFFF" />}
          onClick={logoutUser}
        />
      </ProfileCardButtonsBox>
    </ProfileCardBox>
  );
};

ProfileCard.displayName = 'ProfileCard';

export default ProfileCard;
