import { Dispatch, createContext } from 'react';
import { IUser } from '../../../../Interfaces/IUser';
import { IAppContextReducerAction } from '../../../../Interfaces/IAppContextReducerAction';

interface AppContextType {
  state: IUser;
  dispatch: Dispatch<IAppContextReducerAction>;
}

// @ts-expect-error context
const AppContext = createContext<AppContextType>();

export default AppContext;
