import { BasicReturn, IBasicReturn } from '../../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../../utils/AxiosInstance';

const getEmailValidation = async (): Promise<IBasicReturn> => {
  let basicReturn: IBasicReturn = new BasicReturn(true);

  await MyAxiosInstance().get<IBasicReturn>('Identity/EmailValidate')
    .then((response): IBasicReturn => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = new BasicReturn(false);

      return basicReturn;
    });

  return basicReturn;
};

export default getEmailValidation;
