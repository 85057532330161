import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IUser } from '../../../../../Interfaces/IUser';
import { IDataTableSort } from '../../../../../Interfaces/IDataTableSort';
import UsuariosConvidadosActions from './UsuariosConvidadosActions';

export interface UsuariosConvidadosDataTableProps {
  usuarios: IUser[];
  refreshData: () => void;
}

const UsuariosConvidadosDataTable: React.FC<UsuariosConvidadosDataTableProps> = ({ usuarios, refreshData }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Email',
    direction: 'asc',
  });

  const columns: ColumnConfig<IUser>[] = [
    {
      property: 'FirstName',
      primary: false,
      search: false,
      sortable: true,
      header: 'Nome',
      render: (datum: IUser): React.ReactNode => (
        <Text>{datum.FirstName}</Text>
      ),
    },
    {
      property: 'LastName',
      primary: false,
      search: false,
      sortable: true,
      header: 'Sobrenome',
      render: (datum: IUser): React.ReactNode => (
        <Text>{datum.LastName}</Text>
      ),
    },
    {
      property: 'Email',
      primary: false,
      search: true,
      sortable: true,
      header: 'Email',
      render: (datum: IUser): React.ReactNode => (
        <Text>{datum.Email}</Text>
      ),
    },
    {
      property: 'Technician',
      primary: false,
      search: false,
      sortable: true,
      header: 'Técnico',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Technician) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Admin',
      primary: false,
      search: false,
      sortable: true,
      header: 'Admin?',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Admin) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Active',
      primary: false,
      search: false,
      sortable: true,
      header: 'Ativo?',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Active) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Validated',
      primary: false,
      search: false,
      sortable: true,
      header: 'Validado?',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Validated) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Guid',
      primary: true,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IUser): React.ReactNode => (
        <UsuariosConvidadosActions
          usuario={datum}
          refreshData={refreshData}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={usuarios}
      border={{
        body: 'bottom',
      }}
      sortable={true}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'small',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

UsuariosConvidadosDataTable.displayName = 'UsuariosConvidadosDataTable';

export default UsuariosConvidadosDataTable;
