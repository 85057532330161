import React from 'react';
import { Box } from 'grommet';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import AddVideoForm from '../../../Forms/AddVideo/AddVideoForm';
import adicionarVideoInitialValues from '../../Helpers/adicionarVideoInitialValues';
import adicionarVideoSubmit from '../../Helpers/adicionarVideoSubmit';
import { useNavigate } from 'react-router-dom';

export interface VideoInsertProps {}

const VideoInsert: React.FC<VideoInsertProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const onCancel = (): void => navigate('/owner/manageVideos');
  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Vídeo"
      />
      <Box
        width="large"
      >
        <AddVideoForm
          initialValues={adicionarVideoInitialValues}
          onSubmit={adicionarVideoSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

VideoInsert.displayName = 'VideoInsert';

export default VideoInsert;
