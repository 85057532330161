import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';

i18n
  .use(initReactI18next)
  .use(Backend)
  .init<HttpBackendOptions>({
  initImmediate: false,
  load: 'languageOnly',
  lng: 'pt',
  fallbackLng: 'pt',
  preload: ['en', 'pt'],
  ns: ['translation', 'footer', 'header'],
  // debug: import.meta.env.MODE === 'development',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  backend: {
    loadPath: `${import.meta.env.VITE_LOCAL_URL}/locales/{{lng}}/{{ns}}.json`,
  },
// eslint-disable-next-line @typescript-eslint/no-unused-vars
}, (err, _t) => {
  if (err) return console.error(err);
});

export default i18n;
