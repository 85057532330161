import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const editarPlantaSubmit = async (values: IPlanta): Promise<IBasicReturn> => {
  let basicReturn: IBasicReturn<IPlanta> = { Success: false };

  await MyAxiosInstance().post<IBasicReturn<IPlanta>>('Empresa/PlantaUpdateInfo', values)
    .then((response): IBasicReturn => {
      const { data } = response;

      basicReturn = data;

      return basicReturn;
    })
    .catch((): IBasicReturn => {
      basicReturn = { Success: false };

      return basicReturn;
    });

  return basicReturn;
};

export default editarPlantaSubmit;
