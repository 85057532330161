import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const callAPIError = async (): Promise<IBasicReturn> => {
  let apiResponse: IBasicReturn = {
    Success: false,
  };

  await MyAxiosInstance().get<IBasicReturn>('/Identity/ThrowError')
    .then((response): IBasicReturn => {
      const { data } = response;

      apiResponse = {
        Success: data.Success,
      };

      return apiResponse;
    })
    .catch((): IBasicReturn => {
      apiResponse = {
        Success: false,
      };
      return apiResponse;
    })
  ;

  return apiResponse;
};

export default callAPIError;
