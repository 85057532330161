import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { IUser } from '../../../../../Interfaces/IUser';
import insertUserSubmit from '../../Helpers/insertUserSubmit';
import UsuarioForm from '../../../Forms/Usuario/UsuarioForm';
import insertUsurInitialValues from '../../Helpers/insertUserInitialValues';
import { Box } from 'grommet';

export interface InsertUsuarioProps {}

const InsertUsuario: React.FC<InsertUsuarioProps> = ({ }): JSX.Element => {
  const navigate = useNavigate();
  const onCancel = (): void => navigate('/owner/manageUsers');

  const onSubmit = async (values: IUser): Promise<void> => {
    await insertUserSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Informações do usuário salvas com sucesso');
          navigate('/owner/manageUsers');
        } else {
          toast.error('Erro ao salvar informações do usuário');
        }
      })
      .catch((): void => {
        toast.error('Erro ao salvar informações do usuário');
      });
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Inserir usuário"
      />
      <Box
        width="large"
      >
        <UsuarioForm
          initialValues={insertUsurInitialValues}
          onSubmit={onSubmit}
          onCancel={onCancel}
          editing={false}
        />
      </Box>
    </PageWrapper>
  );
};

InsertUsuario.displayName = 'InsertUsuario';

export default InsertUsuario;
