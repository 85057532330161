import { Form, FormExtendedEvent } from 'grommet';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface FormWrapperProps<T> extends WithTranslation {
  children: React.ReactNode | React.ReactNode[];
  values: T;
  width?: string;
  onChange: ((value: any | T) => void) | undefined;
  onSubmit: (event: FormExtendedEvent<any | T, Element>) => void;
}

const FormWrapper = withTranslation()(
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  <T,>(props: FormWrapperProps<T>): JSX.Element => {
    const {
      children,
      values,
      // width = 'auto',
      onChange,
      onSubmit,
    } = props;

    return (
      <Form
        value={values}
        validate="blur"
        messages={{
          required: 'Campo obrigatório',
          invalid: 'Valor inválido',
        }}
        onChange={onChange}
        onSubmit={onSubmit}
        // size={width}
      >
        {children}
      </Form>
    );
  },
);

FormWrapper.displayName = 'FormWrapper';

export default FormWrapper;
